import { Box, Grid, Stack } from '@mui/material';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const PrivacyPolicyPageRO: React.FC<{}> = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={'Politica de confidențialitate a CLICKnCRUISE Kft'} />
            <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
              <p>
                CLICKnCRUISE Kft (denumit în continuare "Furnizor de servicii" sau "Operator") acordă o atenție
                deosebită în cursul prelucrării datelor, clienții săi fac obiectul dispozițiilor din 2011. évi CXII. (în
                continuare: Info.tv.) și a altor acte legislative aplicabile privind gestionarea datelor, regulamentul
                UE 2016/679 (denumit în continuare: RGPD).
              </p>
              <h3>Numele operatorului de date</h3>
              <p>
                Nume: CLICKnCRUISE Kft
                <br />
                Sediul social: 9141, Ikrény Rét köz 2.
                <br />
                Număr de identificare fiscală: 27547111-1-08
                <br />
                Numărul de înregistrare a societății: 08-09-033950
                <br />
                Număr de înregistrare: U-001970 <br />
                Adresa de e-mail: ahoy@clickncruise.hu
                <br />
              </p>
              <h3>Procesatorul de date </h3>
              <p>
                Amazon Web Services (AWS) <br />
                1200 12th Ave S, Ste 1200, Seattle, WA 98144, Statele Unite ale Americii <br />
                Web: https://aws.amazon.com,
                <br />
                tOS: https://aws.amazon.com/privacy/?nc1=f_pr
                <br />
                Responsabilitatea persoanei împuternicite de către operator: operator de site web, furnizor de servicii
                de găzduire <br />
              </p>
              <h3>Temeiul juridic și definiția prelucrării datelor</h3>
              <p>
                Prelucrarea datelor prin intermediul site-ului web este reglementată de legea Info.tv. 5. (1) lit. a)
                din &sect; (1) al consimțământul este dat de către utilizator pe utilizarea site-ului web și, la
                abonarea la newsletter, prin furnizarea datelor sale personale prin furnizarea voluntară a datelor sale
                cu caracter personal.
              </p>
              <p>
                Site-ul web conține linkuri către alte site-uri web. Aceste site-uri web externe sunt sunt guvernate de
                politica de confidențialitate a site-ului web relevant.
              </p>
              <h3>Domeniul de aplicare al datelor prelucrate, scopul și durata prelucrării</h3>
              <p>
                Durata prelucrării: din momentul în care sunt furnizate datele sau este utilizat serviciul 180 de zile
                calendaristice de la data furnizării datelor sau de la data utilizării serviciului (încheierea
                contractului).
              </p>
              <p>
                Operatorul de date informează Vizitatorii și Utilizatorii site-ului web că site-ul web utilizează
                cookie-uri pentru a asigura o utilizare eficientă a datelor funcționare a site-ului web.
              </p>
              <p>
                Acest site web utilizează aplicația Google Analytics, un serviciu al Google Inc. ("Google") serviciul de
                analiză web. Google Analytics utilizează așa-numitele "cookie-uri", fișiere text care sunt salvate pe
                computerul dvs. pentru a facilita navigarea pe site-ul web vizitat de către Utilizator analiza
                utilizării site-ului web vizitat de către Utilizator.
              </p>
              <p>
                Informațiile generate de modulele cookie despre site-ul web pe care îl utilizați sunt de obicei stocate
                în Serverele Google din SUA. Prin activarea anonimizării IP pe site-ul web, se poate obține Google poate
                utiliza adresa IP a utilizatorului în statele membre ale Uniunii Europene sau ale Spațiului Economic
                European acordul cu Spațiul Economic European.
              </p>
              <p>
                Transmiterea adresei IP complete către un server Google din SUA și scurtarea adresei IP acolo va fi doar
                în cazuri excepționale. În numele operatorului acestui site web, Google va prelucra aceste va utiliza
                aceste informații pentru a evalua utilizarea de către dvs. a site-ului web, și pentru a furniza
                operatorului site-ului web rapoarte cu privire la activitatea site-ului web, și pentru a furniza
                servicii suplimentare legate de utilizarea site-ului web și a internetului.
              </p>
              <p>
                Google Analytics nu asociază adresa IP transmisă de browserul utilizatorului cu adresa de Google cu alte
                date. Stocarea de cookie-uri poate fi activată de către Utilizator prin intermediul setării
                corespunzătoare a browserului său cu toate acestea, vă rugăm să rețineți că, în acest caz, este posibil
                ca această site-ul web să nu fie pe deplin funcțional. De asemenea, puteți împiedica Google să utilizeze
                cookie-urile pentru a accesa funcționalitatea completă a acestui site web să colecteze și să proceseze
                informații despre utilizarea site-ului web de către dvs. prin intermediul cookie-urilor (inclusiv adresa
                IP) prin descărcarea și instalarea add-on-ului pentru browser disponibil la următorul link:
                https://tools.google.com/dlpage/gaoptout?hl=hu
              </p>
              <p>HotJar:</p>
              <p>
                Optimizarea site-ului web se face cu ajutorul modulelor cookie Hotjar, care sunt module cookie de tip
                mouse-over, date despre clicuri și statusuri de formulare. Datele sunt stocate de hotjar.com. Situat la
                colectarea datelor, toate datele din formulare (cu caractere *) sunt mascate, datele reale sunt
                capturate și stocate nu sunt stocate. Politica de confidențialitate a HotJar poate fi găsită aici:
                https://www.hotjar.com/legal/policies/privacy/
              </p>
              <p>
                Prin utilizarea site-ului web, vizitatorii site-ului web autorizează Operatorul de date să utilizeze
                următoarele cookie-uri utilizarea următoarelor module cookie:
              </p>
              <table>
                <tr>
                  <td>
                    <p>Tip</p>
                  </td>
                  <td>
                    <p>Consimțământ</p>
                  </td>
                  <td>
                    <p>Descriere</p>
                  </td>
                  <td>
                    <p>Scop</p>
                  </td>
                  <td>
                    <p>Valabilitate</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>cookie-uri generale ale sistemului</p>
                  </td>
                  <td>
                    <p>nu este necesar</p>
                  </td>
                  <td>
                    <p>cookie de bază responsabil pentru funcționarea aplicației web </p>
                  </td>
                  <td>
                    <p>asigură funcționarea site-ului web</p>
                  </td>
                  <td>
                    <p>până la închiderea browserului</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>cookie-uri generale de sistem</p>
                  </td>
                  <td>
                    <p>necesită</p>
                  </td>
                  <td>
                    <p>
                      necesare pentru a accesa funcționalități suplimentare după ce aplicația web a fost încărcată
                      consimțământul pentru cookie-uri
                    </p>
                  </td>
                  <td>
                    <p>Permite utilizarea cookie-urilor și consimțământul persoanei cu vârsta peste 18 ani</p>
                  </td>
                  <td>
                    <p>365 de zile</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>module cookie pentru funcția de sistem</p>
                  </td>
                  <td>
                    <p>nu este necesar</p>
                  </td>
                  <td>
                    <p>cookie-uri pentru ușurința utilizării și personalizare </p>
                  </td>
                  <td>
                    <p>
                      Acestea permit utilizatorului să acceseze întreaga funcționalitate a sistemului, fără ele multe
                      lucruri nu sunt funcționează
                    </p>
                  </td>
                  <td>
                    <p>până când browserul este închis</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>cookie-uri de urmărire a sistemului</p>
                  </td>
                  <td>
                    <p>necesită</p>
                  </td>
                  <td>
                    <p>necesare pentru măsurare și experiența utilizatorului</p>
                  </td>
                  <td>
                    <p>pentru a produce statistici</p>
                  </td>
                  <td>
                    <p>365 de zile </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>cookie-uri de urmărire </p>
                    <p>(terțe părți)</p>
                  </td>
                  <td>
                    <p>nu sunt necesare</p>
                  </td>
                  <td>
                    <p>
                      pentru identificarea noilor vizitatori și urmărirea web (există integrate și există ceea ce poate
                      fi configurat individual) (Google, Facebook, Twitter, AddThis)
                    </p>
                  </td>
                  <td>
                    <p>țintă precisă, de ex.</p>
                    <p>
                      cookie-ul de urmărire a conversiilor Google Adwords pentru a măsura eficiența anunțurilor Google
                      Ads
                    </p>
                  </td>
                  <td>
                    <p>data exactă de expirare</p>
                    <p>Google Ads: 30 de zile</p>
                  </td>
                </tr>
              </table>
              <h4>Blocați modulele cookie</h4>
              <p>
                Operatorul de date informează vizitatorii și utilizatorii site-ului web că au posibilitatea de a bloca
                cookie-urile cookie-urilor în setările propriilor dispozitive de utilizator. Această setare este de
                obicei setată în setările browserului dispozitivului, în meniul " Confidențialitate și securitate".
              </p>
              <h4>Aplicația Facebook Pixel</h4>
              <p>
                Pe site-ul nostru web folosim Facebook Pixel, un instrument găzduit de Facebook pentru a ajuta
                site-urile web pentru funcționarea economică, optimizarea și analiza site-urilor web. Facebook Pixel
                permite Facebook să poate direcționa vizitatorii site-ului nostru web pentru a afișa reclame Facebook.
              </p>
              <p>
                În consecință, folosim Facebook Pixel pentru a ne asigura că reclamele Facebook pe care le încorporăm să
                apară doar pentru utilizatorii Facebook care s-au arătat interesați de ofertele noastre în ofertele
                noastre. Cu alte cuvinte, cu ajutorul Facebook Pixel, dorim să ne asigurăm că anunțurile de pe Facebook
                se potrivesc cu interesul potențial al utilizatorilor și să nu fie o distragere a atenției. Cu Facebook
                Pixel pentru a analiza performanța anunțurilor noastre de pe Facebook în scopuri statistice și de
                cercetare de piață eficiența anunțurilor dvs. pe Facebook, aflând dacă utilizatorii au dat clic pe
                anunțul de pe Facebook către site-ul nostru web (numiți &quot;conversii&quot; sau &quot;utilizatori în
                acest caz, prelucrarea se bazează pe articolul 6, alineatul 1, teza 1, litera (a) din RGPD se bazează
                pe.
              </p>
              <p>
                Facebook încorporează Facebook Pixel direct atunci când deschideți site-ul nostru web și trimite un
                cookie, adică un un fișier de mici dimensiuni pe dispozitivul dvs. Dacă ulterior vă conectați la
                Facebook sau sunteți conectat la Facebook paginile Facebook, profilul dvs. va arăta vizita dvs. pe
                site-ul nostru va fi înregistrată. Informațiile pe care le obținem despre dvs. sunt anonime pentru noi,
                ceea ce înseamnă că nu primim nicio informație despre profilul dvs. de Facebook pe baza acestor
                informații identitatea utilizatorilor. Cu toate acestea, datele sunt stocate de către Facebook și
                prelucrate de Facebook, astfel încât acestea pot fi legate de profilul utilizatorului. Acest lucru
                înseamnă că datele prelucrate date pot fi utilizate pentru a crea profiluri ale utilizatorilor. Politica
                proprie de prelucrare a datelor de către Facebook de prelucrare a datelor. Astfel, funcționarea Facebook
                Pixel și a reclamelor Facebook în general pentru mai multe informații despre funcționarea Facebook Pixel
                și afișarea anunțurilor Facebook Pixel, vă rugăm să consultați politica de confidențialitate a Facebook
                la adresa la următoarea adresă: https://www.facebook.com/policy.php .
              </p>
              <p>
                Paginile de internet ale furnizorului de servicii pot fi vizitate fără a furniza date. Cu toate acestea,
                există servicii care implică în mod necesar furnizarea de date, după cum se descrie mai jos ce date vor
                fi furnizate în cursul cărui eveniment.
              </p>
              <table>
                <tr>
                  <td>
                    <p>Descrierea activității și scopul prelucrării datelor</p>
                  </td>
                  <td>
                    <p>Temeiul juridic</p>
                  </td>
                  <td>
                    <p>Date prelucrate</p>
                  </td>
                  <td>
                    <p>Durata</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Formular de consimțământ pentru tratament cosmetic</p>
                  </td>
                  <td>
                    <p>consimțământ</p>
                    <p>Articolul 6 alineatul (1) litera (a) din RGPD</p>
                  </td>
                  <td>
                    <p>
                      numele și prenumele persoanei fizice
                      <br />
                      adresa
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>5 ani</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Vizitați site-ul web</p>
                    <p>Scopul este de a asigura funcționarea corectă și de înaltă calitate a site-ului web, </p>
                    <p>să monitorizăm și să îmbunătățim calitatea serviciilor noastre, </p>
                    <p>să identificăm vizitatorii rău intenționați care ne atacă site-ul web,</p>
                    <p>pentru a măsura numărul de vizite, </p>
                    <p>scopuri statistice</p>
                  </td>
                  <td>
                    <p>interesul legitim al companiei noastre;</p>
                    <p>Articolul 6 alineatul (1) litera (f) din RGPD</p>
                  </td>
                  <td>
                    <p>Adresa IP </p>
                    <p>ora vizitei </p>
                    <p>date despre subpaginile vizitate,</p>
                    <p>tipul de sistem de operare și de browser pe care îl utilizați</p>
                  </td>
                  <td>
                    <p>nu este relevant</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Trimiteți formularul
                      <br />
                      contactați clientul
                    </p>
                  </td>
                  <td>
                    <p>consimțământ</p>
                    <p>Articolul 6 alineatul (1) litera (a) din RGPD</p>
                  </td>
                  <td>
                    <p>
                      de exemplu: numele și prenumele persoanei fizice
                      <br />
                      adresa de e-mail
                      <br />
                      Adresa IP
                    </p>
                  </td>
                  <td>
                    <p>180 de zile</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Abonament la newsletter
                      <br />
                      pentru cei care contribuie la serviciu, Compania va crea oferte de marketing personalizate și le
                      va trimite oferte de marketing, îi va contacta în scopuri comerciale și îi va informa cu privire
                      la produsele și produse și servicii comercializate de către Societate.
                    </p>
                  </td>
                  <td>
                    <p>consimțământ</p>
                    <p>Articolul 6 alineatul (1) litera (a) din RGPD</p>
                  </td>
                  <td>
                    <p>
                      nume
                      <br />
                      adresa de e-mail
                      <br />
                    </p>
                  </td>
                  <td>
                    <p>dezabonare de la newsletter</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Comentariu la articol</p>
                  </td>
                  <td>
                    <p>contribuție</p>
                    <p>RGPD Articolul 6, alineatul 1, litera a)</p>
                  </td>
                  <td>
                    <p>
                      nume
                      <br />
                      adresa de e-mail
                      <br />
                      data
                    </p>
                  </td>
                  <td>
                    <p>până la revocare</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Comentariu la profil, cartea de oaspeți</p>
                  </td>
                  <td>
                    <p>contribuție</p>
                    <p>RGPD Articolul 6, alineatul 1, litera a)</p>
                  </td>
                  <td>
                    <p>
                      nume
                      <br />
                      adresa de e-mail
                      <br />
                      Adresa IP
                      <br />
                      data
                    </p>
                  </td>
                  <td>
                    <p>până la revocare</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Serviciul de marketing direct</p>
                    <p>pe baza analizei obiceiurilor dumneavoastră de cumpărare </p>
                    <p>creăm și vă trimitem oferte personalizate, </p>
                    <p>vă contactăm în scopuri de marketing,</p>
                    <p>vă trimitem informații despre produsele și serviciile pe care le vindem.</p>
                  </td>
                  <td>
                    <p>contribuția</p>
                    <p>Articolul 6 alineatul (1) litera (a) din RGPD</p>
                  </td>
                  <td>
                    <p>numele și prenumele dvs,</p>
                    <p>adresa de e-mail, </p>
                    <p>număr de telefon (opțional)</p>
                    <p>alte date opționale, de exemplu, interese, locul de reședință etc</p>
                  </td>
                  <td>
                    <p>serviciul de marketing direct până la dezabonare</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>administrare, reclamații</p>
                    <ul>
                      <li>răspuns la comentarii și reclamații</li>
                      <li></li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>obligație legală</li>
                      <li>Articolul 6 alineatul (1) litera (c) din RGPD</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>nume și prenume</li>
                      <li>adresa de e-mail</li>
                      <li>numărul de telefon</li>
                      <li>adresa poștală</li>
                      <li>alt mesaj personal</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>5 ani</li>
                    </ul>
                  </td>
                </tr>
              </table>
              <p>
                Dacă aveți întrebări cu privire la prelucrarea datelor, ne puteți contacta prin e-mail la adresa
                ahoy@clickncruise.hu sau prin poștă la adresa solicitați informații suplimentare, iar noi vă vom
                răspunde fără întârzieri nejustificate, în termen de maximum 28 de zile la datele de contact pe care
                le-ați furnizat.
              </p>
              <p>
                În aceste cazuri, cele mai necesare date sunt doar în scopul tranzacției online, pentru facturare, sau
                pentru furnizarea serviciului, până în momentul în care este strict necesar să se facă acest lucru
                necesare; inclusiv timpul necesar pentru reclamații și pentru cazurile de garanție și de garanție, și
                anume stocarea pentru a dovedi ulterior termenii contractului. Ulterior, perioada de datelor va fi
                ștearsă la 180 de zile calendaristice după încetarea contractului.
              </p>
              <p>
                Datele cu caracter personal furnizate în legătură cu înregistrarea sau utilizarea serviciilor noastre de
                marketing nu vor fi fi legate între ele, iar noi nu urmărim să ne identificăm vizitatorii ca o chestiune
                de principiu.
              </p>
              <p>
                Ne furnizați în mod voluntar datele dvs. personale atunci când vă înregistrați la noi sau la furnizorul
                de servicii furnizor de servicii și, prin urmare, vă rugăm să aveți grijă atunci când furnizați datele
                dvs. către veridicitatea, corectitudinea și acuratețea informațiilor pe care le furnizați, deoarece
                sunteți responsabil pentru acestea. Incorect, inexact sau incomplet date pot constitui o barieră în
                calea utilizării serviciilor noastre.
              </p>
              <p>
                Dacă furnizați datele cu caracter personal ale unei alte persoane, și nu ale dumneavoastră, vom
                presupune că sunteți dvs aveți autoritatea necesară pentru a face acest lucru.
              </p>
              <p>Vă puteți retrage consimțământul pentru prelucrarea datelor în orice moment, în mod gratuit</p>
              <p>prin anularea înregistrării dumneavoastră,</p>
              <p>retragerea consimțământului pentru prelucrarea datelor sau</p>
              <p>
                la prelucrarea sau utilizarea oricăror date care trebuie completate în timpul înregistrării utilizării
                datelor sau prin retragerea consimțământului sau prin solicitarea blocării acestuia.
              </p>
              <p>
                Din motive tehnice, aveți la dispoziție 24 de ore pentru a vă înregistra retragerea consimțământului cu
                toate acestea, am dori să vă atragem atenția asupra faptului că avem o obligație legală sau interese,
                este posibil să prelucrăm anumite date chiar și după retragerea consimțământului.
              </p>
              <p>
                În cazul în care datele personale sunt înșelătoare sau dacă unul dintre vizitatorii noștri comite o
                infracțiune sau atacă sistemul nostru informatic, vom anula înregistrarea vizitatorului respectiv vom
                șterge imediat datele dumneavoastră sau, dacă este necesar, le vom păstra în răspundere civilă sau în
                cadrul procedurilor penale.
              </p>
              <h3>Alte aspecte legate de prelucrarea datelor</h3>
              <p>
                Putem transfera datele dvs. către persoanele care se ocupă de prelucrarea datelor numai în limitele
                stabilite de lege avem termeni contractuali pentru a ne asigura că aceștia nu pot utiliza datele dvs.
                fără consimțământul dvs utiliza datele dvs. personale în scopuri care nu sunt în conformitate cu
                consimțământul dvs.
              </p>
              <p>Nu transferăm date în străinătate. </p>
              <p>
                Instanțele de judecată, procuratura și alte autorități (de exemplu, poliția, autoritățile fiscale,
                Agenția Națională pentru Protecția Datelor și Autoritatea pentru libertatea de informare) pentru a
                furniza informații, date sau documente ne pot contacta pentru informații sau documente. În aceste
                cazuri, suntem obligați să furnizăm informații dar numai în măsura strict necesară pentru a atinge
                scopul solicitării necesare pentru a îndeplini cererea.
              </p>
              <p>
                Agenții și angajații Furnizorului de servicii implicați în prelucrarea datelor și/sau prelucrarea
                datelor în avans au dreptul, în măsura specificată în prealabil și sub rezerva obligației de
                confidențialitate, să datele cu caracter personal.
              </p>
              <p>
                Vom lua măsuri tehnice și de altă natură adecvate pentru a vă proteja datele personale și pentru a ne
                asigura că securitatea și disponibilitatea datelor dvs. și să le protejăm împotriva accesului
                neautorizat, modificarea, deteriorarea sau divulgarea și de orice alt acces, utilizare sau divulgare
                neautorizată a datelor dumneavoastră cu caracter personal orice utilizare neautorizată.
              </p>
              <h3>Drepturile și căile de atac ale vizitatorilor</h3>
              <p>
                În conformitate cu prevederile legale, aveți următoarele drepturi în legătură cu datele dumneavoastră cu
                caracter personal:
              </p>
              <ul>
                <li>
                  Pentru a obține informații despre prelucrare și o copie a datelor prelucrate (dreptul de acces,
                  articolul 15 din RGPD),
                </li>
                <li>
                  dreptul de a solicita rectificarea datelor inexacte sau completarea datelor incomplete (dreptul
                  dreptul la rectificare, articolul 16 din RGPD),
                </li>
                <li>
                  dreptul de a solicita ștergerea datelor cu caracter personal, în cazul în care există condițiile
                  legale pentru aceasta, și, în cazul transferului de date cu caracter personal către alți operatori,
                  dreptul de a solicita ștergerea datelor informațiile trimise altor persoane vizate în legătură cu
                  cererea de ștergere, în cazul în care legea prevede acest lucru (dreptul la ștergere, articolul 6 din
                  RGPD 17. articolul RGPD),
                </li>
                <li>
                  dreptul de a solicita restricționarea prelucrării (dreptul de restricționare a prelucrării, RGPD 18.
                  articolul RGPD),
                </li>
                <li>
                  dreptul la prelucrarea datelor cu caracter personal într-un format structurat, utilizat în mod
                  obișnuit și care poate fi citit automat dreptul de a obține datele într-un format utilizat în mod
                  obișnuit și inteligibil, precum și dreptul de a transmite aceste date unei alte părți responsabile de
                  prelucrare către operatorul de date (dreptul la portabilitatea datelor, articolul 20 din RGPD),
                </li>
                <li>
                  dreptul de a se opune prelucrării pentru a împiedica prelucrarea în cazul în care prelucrarea se
                  bazează pe consimțământul dumneavoastră sau pe interesele legitime ale cosmeticelor sau ale unei terțe
                  părți acest drept nu se aplică în cazul în care prelucrarea este justificată de motive imperioase,
                  prevalează asupra intereselor dvs. sau în cazul în care datele dvs. fac obiectul unor pretenții legale
                  exercitarea, exercitarea sau apărarea unor pretenții legale (dreptul de opoziție, RGPD 21. articolul
                  21 din RGPD),
                </li>
                <li>
                  dreptul la informații privind aspectele relevante ale responsabilității partajate, care pot include
                  rolurile și responsabilitățile tuturor persoanelor responsabile de prelucrarea datelor cu caracter
                  personal și mecanismele și procedurile de exercitare a drepturilor persoanelor vizate [articolul 28
                  alineatul (2) din RGPD alineatul (2);
                </li>
                <li>
                  dreptul de a retrage consimțământul dat în orice moment pe baza consimțământului prelucrarea pe baza
                  consimțământului. Retragerea nu afectează nicio retragere anterioară, legalitatea prelucrării pe baza
                  consimțământului înainte de retragere (dreptul de retragere, articolul 7 din RGPD), și
                </li>
                <li>
                  Aveți dreptul de a solicita o cale de atac în fața unei instanțe de judecată sau a autorității de
                  supraveghere competente ( https://naih.hu/panaszuegyintezes-rendje.html ) dacă considerați că
                  prelucrarea datelor dvs. încalcă RGPD (articolul 77 din RGPD).
                </li>
              </ul>
              Autoritatea de supraveghere: Autoritatea Națională pentru Protecția Datelor și Libertatea Informației
              <ul>
                <li>Sediul central.</li>
                <li>Adresa poștală: 1530 Budapesta, PO Box 5.</li>
                <li>Telefon: +36 (1) 391-1400</li>
                <li>Fax: +36 (1) 391-1410</li>
                <li>E-mail:ugyfelszolgalat@naih.hu</li>
                <li>Website:https://naih.hu/</li>
              </ul>
              <p>
                La cererea dumneavoastră, vă vom furniza informații cu privire la datele dumneavoastră cu caracter
                personal pe care le prelucrăm sau - prelucrate de noi sau de către persoana împuternicită de noi să le
                prelucreze
              </p>
              <ul>
                <li>despre datele dumneavoastră, </li>
                <li>sursa lor, </li>
                <li>scopurile și temeiul juridic al prelucrării, </li>
                <li>
                  scopul, scopul, scopul, scopul, scopul și durata prelucrării și, în cazul în care acest lucru nu este
                  posibil, criteriile de determinare a acestei durate,
                </li>
                <li>
                  numele și adresele persoanelor care prelucrează datele noastre și activitățile acestora în legătură cu
                  prelucrarea,
                </li>
                <li>
                  circumstanțele încălcărilor de date, efectele acestora și măsurile luate pentru a le preveni și pentru
                  a le soluționa măsurile pe care le-am luat pentru a o preveni; și
                </li>
                <li>
                  în cazul în care datele dumneavoastră cu caracter personal sunt transferate, temeiul juridic și
                  destinatarul transferului.
                </li>
              </ul>
              <p>
                Vă vom furniza informații în termen de 28 de zile de la depunerea cererii. A gratuit, cu excepția
                cazului în care ați solicitat informații în anul în curs pentru același set de date pentru aceeași
                persoană vizată. Vă vom rambursa toate taxele pe care le-ați plătit deja în cazul în care datele au fost
                prelucrate în mod ilegal sau dacă solicitarea de informații a dus la o corecție. A putem refuza să
                furnizăm informații doar în cazurile prevăzute de lege, menționând locul în care informațiile sunt
                cerute de lege, și informându-vă cu privire la posibilitatea de a recurge la o cale de atac judiciară
                sau de a vă adresa Autorității.
              </p>
              <p>
                Vă vom informa pe dumneavoastră și pe orice altă persoană căreia îi dezvăluim datele dumneavoastră cu
                caracter personal cu privire la rectificarea, blocarea, marcarea și ștergerea datelor cu caracter
                personal către care am transferat anterior datele în vederea prelucrării, cu excepția cazului în care
                notificarea neinformarea nu va aduce atingere intereselor dvs. legitime.
              </p>
              <p>
                În cazul în care nu ne conformăm cererii dvs. de rectificare, blocare sau ștergere, cererea în termen de
                28 de zile de la primirea cererii, fie în scris, fie, cu acordul dvs fie prin mijloace electronice,
                motivele refuzului nostru și vă informăm cu privire la căile de atac legale de care dispuneți și la
                dreptul de a vă adresa Autorității pentru o autorității.
              </p>
              <p>
                În cazul în care vă opuneți prelucrării datelor dvs. cu caracter personal, vom înregistra obiecția dvs.
                din momentul în care ne transmiteți cererea dvs în termen de 28 de zile de la data solicitării
                dumneavoastră, vom lua în considerare obiecția dumneavoastră și vă vom informa în scris cu privire la
                decizia noastră vă vom informa în scris. În cazul în care am decis că obiecția dvs. este justificată,
                vom vom înceta prelucrarea, inclusiv orice colectare și transfer ulterior al datelor, și vom bloca
                datele și vă vom notifica obiecția și măsurile luate ca răspuns la aceasta cui am divulgat anterior
                datele cu caracter personal la care se referă obiecția și cine care sunt obligați să ia măsuri pentru a
                pune în aplicare dreptul de opoziție.
              </p>
              <p>
                Vom refuza să ne conformăm cererii dacă putem demonstra că prelucrarea este ilegală sau incompatibilă cu
                scopurile pentru care a fost efectuată motive legitime imperative care prevalează asupra intereselor,
                drepturilor și libertăților dumneavoastră și sau pentru constatarea, exercitarea sau apărarea unor
                pretenții legale sau pentru protecția sau apărarea intereselor dvs. Dacă nu sunteți de acord cu decizia
                noastră sau dacă nu respectăm termenul limită, decizia va fi decizie sau în ultima zi a termenului
                limită, aveți la dispoziție 30 de zile de la data deciziei sau din ultima zi a termenului limită pentru
                a acționa în justiție.
              </p>
              <p>
                Litigiile privind protecția datelor sunt supuse jurisdicției instanțelor care, la alegerea persoanei
                vizate la alegerea dumneavoastră, în fața instanței de la locul de reședință sau de la domiciliul
                persoanei vizate. Un cetățean străin poate, de asemenea, să depună o plângere la autoritatea de
                supraveghere de la locul său de reședință.
              </p>
              <p>
                Înainte de a depune o plângere la o autoritate de supraveghere sau la o instanță, vă rugăm să consultați
                pentru a discuta și a rezolva problema cât mai repede posibil.
              </p>
              <p>
                Furnizorul de servicii este obligat să respecte următoarele legi și recomandări privind prelucrarea
                datelor:
              </p>
              <ul>
                <li>Articolul VI din Legea fundamentală a Ungariei</li>
                <li>
                  directiva Parlamentului European și a Consiliului (UE) privind protecția persoanelor fizice în ceea ce
                  privește prelucrarea datelor cu caracter personal regulamentul (UE) 2016/679 al Parlamentului European
                  și al Consiliului (RGPD)
                </li>
                <li>
                  2011. legea CXII din 2006 privind dreptul la autodeterminare informațională și libertatea de informare
                  (Info tv.)
                </li>
                <li>legea V din 2013 privind Codul civil (Legea privind Codul civil)</li>
                <li>legea CVIII din 2001 - Eker tv privind anumite aspecte ale societății informaționale),</li>
                <li>legea C din 2003 privind comunicațiile electronice - (Ehtv)</li>
                <li>legea CLV din 1997 privind protecția consumatorilor (Fogyv tv.)</li>
                <li>legea CLXV din 2013 privind plângerile și notificările de interes public (Pktv.)</li>
                <li>xLVIII. (Grtv.)</li>
              </ul>
              <h3>Posibilitatea de a modifica declarația de confidențialitate</h3>
              <p>
                Operatorul de date își rezervă dreptul de a modifica această declarație de confidențialitate fără a
                anunța în prealabil utilizatorii să modifice unilateral prezenta notă de confidențialitate. După
                intrarea în vigoare a modificării, utilizatorul poate utiliza serviciul prin utilizarea serviciului,
                utilizatorul acceptă declarația de confidențialitate modificată prin acceptarea sa automată. Site-ul
                publicarea de informații privind gestionarea datelor pe https://clickncruise.hu/privacy-policy pe
                site-ul web.
              </p>
              <p>6. Drepturile dumneavoastră</p>
              <ul>
                <li>
                  De a fi informat cu privire la prelucrare și de a obține o copie a datelor prelucrate (dreptul de
                  acces, articolul 15 din RGPD),
                </li>
                <li>
                  dreptul de a solicita rectificarea datelor inexacte sau completarea datelor incomplete (dreptul de a
                  solicita rectificarea datelor inexacte sau completarea datelor incomplete ( dreptul la rectificare,
                  articolul 16 din RGPD),
                </li>
                <li>
                  dreptul de a solicita ștergerea datelor cu caracter personal, în cazul în care există condițiile
                  legale pentru aceasta, și, în cazul transferului de date cu caracter personal către alți operatori,
                  dreptul de a solicita ștergerea datelor informații trimise altor persoane vizate în legătură cu
                  cererea de ștergere, în cazul în care legea prevede acest lucru (dreptul la ștergere, articolul 6 din
                  RGPD 17. articolul RGPD),
                </li>
                <li>
                  dreptul de a solicita restricționarea prelucrării (dreptul de restricționare a prelucrării, RGPD 18.
                  articolul RGPD),
                </li>
                <li>
                  dreptul la prelucrarea datelor cu caracter personal într-un format structurat, utilizat în mod
                  obișnuit și care poate fi citit automat dreptul de a obține datele într-un format utilizat în mod
                  obișnuit și inteligibil, precum și dreptul de a transmite aceste date unei alte părți responsabile de
                  prelucrare către operatorul de date (dreptul la portabilitatea datelor, articolul 20 din RGPD),
                </li>
                <li>
                  dreptul de a se opune prelucrării pentru a împiedica prelucrarea în cazul în care prelucrarea se
                  bazează pe consimțământul dumneavoastră sau pe interesele legitime ale societății noastre sau ale unei
                  terțe părți acest drept nu se aplică în cazul în care prelucrarea este justificată de motive
                  imperioase care prevalează asupra intereselor dvs. sau atunci când datele dvs. sunt necesare pentru
                  exercitarea unor pretenții legale, exercitarea sau apărarea unor pretenții legale (dreptul la
                  opoziție, articolul 21 din RGPD),
                </li>
                <li>
                  dreptul la informații privind aspectele relevante ale responsabilității partajate, care pot include
                  rolurile și responsabilitățile tuturor persoanelor responsabile de prelucrarea datelor cu caracter
                  personal și mecanismele și procedurile de exercitare a drepturilor persoanelor vizate [articolul 28
                  alineatul (2) din RGPD] alineatul (2);
                </li>
                <li>
                  dreptul de a retrage consimțământul dat în orice moment pe baza consimțământului prelucrarea pe baza
                  consimțământului. Retragerea nu afectează nicio retragere anterioară, legalitatea prelucrării pe baza
                  consimțământului înainte de retragere (dreptul de retragere, articolul 7 din RGPD), și
                </li>
              </ul>
              <p>
                Aveți dreptul de a vă opune prelucrării datelor dvs. cu caracter personal și de a vă exercita drepturile
                în temeiul RGPD către responsabilul nostru cu protecția datelor [articolul 38 alineatul (4) din RGPD]
                sau către responsabilul cu protecția datelor al Comisiei Europene [articolul 38 alineatul (4) din RGPD]
                să depuneți o plângere la autoritatea de supraveghere competentă dacă considerați că prelucrarea încalcă
                RGPD (articolul 77 din RGPD).
              </p>
            </SectionBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
PrivacyPolicyPageRO.displayName = 'PrivacyPolicyPageRO';
