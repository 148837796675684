import {
  CruiseSearchRequest,
  CruiseSearchRequestGuests,
  CruiseSearchRequestSortDirectionEnum,
  CruiseSearchRequestSortEnum
} from '../services/search';
import { BrowserStorage, STORAGE_KEY_FILTER } from './BrowserStorage';

const RESULTS_PER_PAGE = __CONFIG__.search.resultsPerPage;
const FILTER_KEY_MAP = new Map(
  Object.entries({
    cabinCategory: 'c',
    cruiseLength: 'le',
    cruiseLine: 'l',
    guests: 'g',
    portsOfCall: 'd',
    intermediatePortsOfCall: 'i',
    price: 'p',
    seaDays: 'sd',
    ship: 's',
    sustainability: 'su',
    when: 'n',
    where: 'r',
    sort: 'o',
    sortDirection: 'od',
    promo: 'pr',
    routeOptions: 'ro',
    cruiseIds: 'ci'
  })
);

export interface IGuestsFilter {
  adult: number;
  child: number;
}

export class GuestsFilter implements IGuestsFilter {
  adult: number = 2;
  child: number = 0;

  constructor(g?: IGuestsFilter) {
    if (g) {
      this.adult = g.adult;
      this.child = g.child;
    }
  }

  public isEmpty(): boolean {
    return !this.adult && !this.child;
  }

  public getAllAsLabels(t: (v: string) => string): string[] {
    let result: string[] = [];
    if (this.adult > 0) {
      result.push(`${t('search.filters.guest.values.adult.label')}: ${this.adult}`);
    }

    result.push(`${t('search.filters.guest.values.child.label')}: ${this.child}`);

    return result;
  }

  public getAllCount(): number {
    return this.adult + this.child;
  }

  public setValue(name: string, value: number): void {
    switch (name) {
      case 'adult':
        this.adult = value;
        break;
      case 'child':
        this.child = value;
        break;
    }
  }

  toSearchRequest(): { guests: CruiseSearchRequestGuests } {
    return {
      guests: {
        adults: this.adult,
        children: this.child
      }
    };
  }

  toUrlSearchParams() {
    return `${this.adult}|${this.child}`;
  }
}

export class DateRangeFilter {
  from: Date | null = null;
  to: Date | null = null;

  constructor(source?: DateRangeFilter) {
    if (source && source.from && source.to) {
      this.from = new Date(source.from);
      this.to = new Date(source.to);
    }
  }

  isEmpty(): boolean {
    return !this.from || !this.to;
  }

  toSearchRequest(): DateRangeFilter | Object {
    let result: any = {};

    if (this.from || this.to) {
      result.when = {};

      if (this.from) {
        result.when.from = this.from.getTime();
      }
      if (this.to) {
        result.when.to = this.to.getTime();
      }
    }
    return result;
  }

  toUrlSearchParams() {
    let result = '';
    if (!this.from || !this.to) {
      if (this.from) {
        result += `${this.from?.getTime() / 100000}`;
      }
      if (this.to) {
        result += `${this.to?.getTime() / 100000}`;
      }
    } else {
      result += `${this.from?.getTime() / 100000}|${this.to?.getTime() / 100000}`;
    }
    return result;
  }
}

const NAMED_SEARCHES: Record<string, string> = {
  'sail-from-the-uk': 'g=2|0;d=Southamptonˍ(London),ˍUnitedˍKingdom|Doverˍ(London),ˍUnitedˍKingdom',
  'summer-2024': 'g=2|0;n=17145576|17250984',
  'under-500': 'g=2|0;p=0-500',
  'northern-europe': 'g=2|0;r=NorthernˍEurope',
  'xmas-2023': 'g=2|0;n=17028108|17040204',
  'msc-winter-wonders': 'g=2|0;pr=WinterˍWonders',
  'msc-96-hours': 'g=2|0',
  'black-friday': 'g=2|0;pr=BlackˍFriday',
  'black-friday-msc': 'g=2|0;l=MSC;pr=BlackˍFriday',
  'by-car':
    'g=2|0;d=Monfalcone,ˍItaly|Split,ˍCroatia|Triesteˍ(Venice),ˍItaly|Venice,ˍItaly|Zadar,ˍCroatia;le=6-8;ro=roundtrip',
  sustainable: 'g=2|0;su=LNG',
  mediterranean: 'g=2|0;r=Mediterranean',
  caribbean: 'g=2|0;r=Caribbean',
  msc: 'g=2|0;l=MSC',
  'msc-foldkozi': 'g=2|0;r=Mediterranean;l=MSC;le=6-8',
  'msc-dubai': 'g=2|0;r=Dubaiˍ&ˍUAE;l=MSC',
  'adults-only': 'g=2|0;l=VIRGIN',
  'last-minute': 'last-minute'
};

export class CruiseSearchFilters {
  static DEFAULT_SORT: CruiseSearchRequestSortEnum = 'price_per_person';
  static DEFAULT_SORT_DIRECTION: CruiseSearchRequestSortDirectionEnum = 'asc';

  guests: GuestsFilter = new GuestsFilter();
  when: DateRangeFilter = new DateRangeFilter();
  price: Array<string> = [];
  where: Array<string> = [];
  cruiseLine: Array<string> = [];
  ship: Array<string> = [];
  cabinCategory: Array<string> = [];
  portsOfCall: Array<string> = [];
  intermediatePortsOfCall: Array<string> = [];
  routeOptions: Array<string> = [];
  sustainability = [];
  cruiseIds: Array<string> = [];
  promo: Array<string> = [];
  cruiseLength: Array<string> = [];
  seaDays = [];
  limit = RESULTS_PER_PAGE;
  offset = 0;
  sort: CruiseSearchRequestSortEnum = CruiseSearchFilters.DEFAULT_SORT;
  sortDirection: CruiseSearchRequestSortDirectionEnum = CruiseSearchFilters.DEFAULT_SORT_DIRECTION;

  /**
   *
   * @param source cookie value
   */
  constructor(source?: string | null) {
    if (source) {
      if (source === 'last-minute') {
        this.when.from = new Date();
        this.when.to = new Date();
        this.when.to.setMonth(this.when.to.getMonth() + 2);
        this.cruiseLength = ['6-8'];
      } else {
        this.init(NAMED_SEARCHES[source] || source);
      }
    }
  }

  init(source: string): void {
    if (source) {
      let jsonString = source;

      if (source === 'last-minute') {
        this.when.from = new Date();
        this.when.to = new Date();
        this.when.to.setMonth(this.when.to.getMonth() + 2);
        this.cruiseLength = ['6-8'];

        return;
      } else {
        jsonString = NAMED_SEARCHES[source] || source;
      }

      const searchParams = jsonString
        .split(';')
        .filter((e) => e)
        .map((e) => {
          let c = e.split('=');
          return [c[0], c[1]];
        });
      let o: any = {};
      searchParams.forEach((s) => {
        const key = [...FILTER_KEY_MAP.entries()].filter(({ 1: v }) => v === s[0]).map(([k]) => k)[0];
        let value: any = s[1] ? s[1].split('|').map((e) => e.replace(/ˍ/g, ' ')) : null;
        if (value) {
          switch (key) {
            case 'guests':
              value = { adult: parseInt(value[0]) || 2, child: parseInt(value[1]) || 0 };
              break;
            case 'when':
              value = {
                from: value[0] ? new Date(+value[0] * 100000) : null,
                to: value[1] ? new Date(+value[1] * 100000) : null
              };
              break;
            case 'sort':
              value = value[0];
              break;
            case 'sortDirection':
              value = value[0];
              break;
            default:
              break;
          }
          o[key] = value;
        }
      });

      if (o) {
        this.guests = new GuestsFilter(o.guests);
        this.when = new DateRangeFilter(o.when);
        this.price = o.price || [];
        this.where = o.where || [];
        this.cruiseLine = o.cruiseLine || [];
        this.ship = o.ship || [];
        this.cabinCategory = o.cabinCategory || [];
        this.portsOfCall = o.portsOfCall || [];
        this.intermediatePortsOfCall = o.intermediatePortsOfCall || [];
        this.routeOptions = o.routeOptions || [];
        this.sustainability = o.sustainability || [];
        this.cruiseIds = o.cruiseIds || [];
        this.promo = o.promo || [];
        this.cruiseLength = o.cruiseLength || [];
        this.seaDays = o.seaDays || [];
        this.sort = o.sort || CruiseSearchFilters.DEFAULT_SORT;
        this.sortDirection = o.sortDirection || CruiseSearchFilters.DEFAULT_SORT_DIRECTION;
      }
    }
  }

  toUrlSearchParams(): string {
    const excludedAttrs = ['limit', 'offset'];
    return Object.entries(this)
      .filter((e) => !excludedAttrs.includes(e[0]))
      .map(([k, v]) => {
        const key = FILTER_KEY_MAP.get(k);
        if (Array.isArray(v)) {
          if (v.length > 0) {
            return `${key}=${v.map((e) => e.replace(/ /g, 'ˍ')).join('|')}`;
          }
        } else if (typeof v === 'object' && 'toUrlSearchParams' in v) {
          const param = v.toUrlSearchParams();
          if (param) {
            return `${key}=${param}`;
          }
        } else if (k === 'sort' && v === CruiseSearchFilters.DEFAULT_SORT) {
          return null;
        } else if (k === 'sortDirection' && v === CruiseSearchFilters.DEFAULT_SORT_DIRECTION) {
          return null;
        } else {
          return `${key}=${v}`;
        }
        return null;
      })
      .filter((e) => !!e)
      .join(';');
  }

  toSearchRequest(): CruiseSearchRequest {
    let { when, guests, sort, sortDirection, ...result } = this;
    let s = sort ? { sort: sort } : {};
    let sd = sortDirection ? { sortDirection: sortDirection } : {};

    return Object.assign(result, when.toSearchRequest(), guests.toSearchRequest(), s, sd);
  }

  /**
   * @param skipDefaults
   * @returns
   */
  public isEmpty(skipDefaults: boolean = false): boolean {
    return (
      (this.guests.isEmpty() || skipDefaults) &&
      this.when.isEmpty() &&
      this.price.length === 0 &&
      this.where.length === 0 &&
      this.cruiseLine.length === 0 &&
      this.cabinCategory.length === 0 &&
      this.portsOfCall.length === 0 &&
      this.intermediatePortsOfCall.length === 0 &&
      this.routeOptions.length === 0 &&
      this.sustainability.length === 0 &&
      this.cruiseIds.length === 0 &&
      this.promo.length === 0 &&
      this.cruiseLength.length === 0 &&
      this.seaDays.length === 0
    );
  }

  public store(): void {
    //BrowserStorage.getInstance().setItem(STORAGE_KEY_FILTER, JSON.stringify(this));
  }

  public clear(): void {
    this.guests = new GuestsFilter();
    this.when = new DateRangeFilter();
    this.price = [];
    this.where = [];
    this.cruiseLine = [];
    this.ship = [];
    this.cabinCategory = [];
    this.portsOfCall = [];
    this.intermediatePortsOfCall = [];
    this.routeOptions = [];
    this.sustainability = [];
    this.cruiseIds = [];
    this.promo = [];
    this.cruiseLength = [];
    this.seaDays = [];
    this.limit = RESULTS_PER_PAGE;
    this.offset = 0;
    this.sort = CruiseSearchFilters.DEFAULT_SORT;
    this.sortDirection = CruiseSearchFilters.DEFAULT_SORT_DIRECTION;
  }
}
