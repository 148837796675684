import React from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {Trans, useTranslation} from "react-i18next";

import { BookingOfferSummary } from '../../components/booking-offer-summary/booking-offer-summary';
import { BookingState } from '../../models/BookingState';

export const BookingConfirmation: React.FC<{bookingState: BookingState, updateBooking: (b: BookingState) => void}> = (props) => {
  
  // Translation
  const { t } = useTranslation('common');

  // Navigation
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  // States
  const {invoice} = props.bookingState.guestsState;
  const {stripeCustomerId} = props.bookingState.guestsState;

  
  useEffect(() => {
    if (stripeCustomerId) {
      navigate({
        pathname: `/checkout/${stripeCustomerId}`
      });
    }
  }, []);

  return <>
    <Box >
      <Stack textAlign="center">
        <Box margin="0 16px">
          <Typography variant="h2" marginBottom="15px">{t("booking.confirmation.page-title")}</Typography>
          <Box>
          <Trans
              t={t}
              i18nKey={'booking.confirmation.message'}
              values={{ email: invoice.email }}
            ></Trans>
          </Box>
          <Box>
            <Typography variant="h3" textAlign="left" borderBottom='1px solid #8A9EA8' marginTop={3} paddingBottom="8px" style={{fontSize: '16px'}}>{t("booking.confirmation.summary-title")}</Typography>
          </Box>
        </Box>
        <BookingOfferSummary bookingState={props.bookingState} fullWidth={true} readonly={true} updateBooking={props.updateBooking} />
        </Stack>
    </Box>
  </>
}
