import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CruiseSearchResultType } from '../../models/State';
import { CruiseSearchResultListItem } from '../cruise-search-result-list-item/cruise-search-result-list-item';
import { CruiseSearchResultListItemSkeleton } from '../cruise-search-result-list-item/cruise-search-result-list-item-skeleton';
import { PriceTrackerBanner } from '../price-tracker-banner/price-tracker-banner';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';

export const CruiseSearchResultList: React.FC<{
  cruiseSearchState: CruiseSearchResultType;
  isLoading: boolean;
  fetchMoreData: () => void;
  searchFilters: CruiseSearchFilters;
}> = (props) => {
  const priceTrackerBannerIndices = new Set(
    [3, 10, 20, 50].map((i) => Math.min(i, props.cruiseSearchState.totalResultCount) - 1)
  );

  const List = () => {
    if (props.cruiseSearchState.cruises.length > 0) {
      return (
        <>
          <InfiniteScroll
            dataLength={props.cruiseSearchState.totalResultCount} // This field is important to render the next page
            next={props.fetchMoreData}
            hasMore={
              props.cruiseSearchState.cruises.length < props.cruiseSearchState.totalResultCount && !props.isLoading
            }
            loader={<h4>Loading...</h4>}
          >
            {props.cruiseSearchState.cruises.map((cruise, index) => (
              <Box key={index} sx={{ marginBottom: { xs: '10px', md: '25px' } }}>
                <CruiseSearchResultListItem data={cruise} />
                {priceTrackerBannerIndices.has(index) && <PriceTrackerBanner searchFilters={props.searchFilters} cheapestPricePerPerson={props.cruiseSearchState.cruises?.[0].pricePerPerson || 10000} />}
              </Box>
            ))}
          </InfiniteScroll>
        </>
      );
    }
    return props.isLoading ? (
      <></>
    ) : (
      <Box sx={{ marginBottom: { xs: '10px', md: '25px' } }}>
        <PriceTrackerBanner searchFilters={props.searchFilters} cheapestPricePerPerson={10000} />
      </Box>
    );
  };

  const ListSkeleton = () => {
    if (props.isLoading) {
      return (
        <>
          {[...Array(3)].map((i, index) => (
            <Box key={index} sx={{ marginBottom: { xs: '10px', md: '25px' } }}>
              <CruiseSearchResultListItemSkeleton />
            </Box>
          ))}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Stack direction="column" alignItems="center">
        <Box width="100%">
          <List />
          <ListSkeleton />
        </Box>
      </Stack>
    </>
  );
};
