import * as React from 'react';
import './booking-stepper.scss';
import { Step, StepButton, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BookingSteps } from '../../models/BookingState';

import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#05c1b3',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#05c1b3',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#c4c4c4',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#c4c4c4',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    borderRadius: '50%',
	  width: 8,
    height: 8,
	  border: '5px solid #05c1b3',
    backgroundColor: '#fff',
    marginTop: '2px',
    '.StepIcon-circle': {
      width: '0 !important',
      height: '0 !important',
      backgroundColor: '#fff !important',
    },
    '.StepIcon-completedIcon': {
      display: 'none'
    }
  }),
  '& .StepIcon-completedIcon': {
    color: '#05c1b3',
    zIndex: 1,
    fontSize: 18,
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    marginTop: '2px'
  },
  '& .StepIcon-circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function StepIcon(props) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="StepIcon-completedIcon" />
      ) : (
        <div className="StepIcon-circle" />
      )}
    </StepIconRoot>
  );
}

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

export default function BookingStepper(props) {
  const {t} = useTranslation('common');
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const steps = BookingSteps;

  const stepClickEnabled = (index) => () => {
    return index === 0 || props.bookingState.completedSteps.includes(index - 1);
  } 

  const onStepClick = (index) => () => {
    if (stepClickEnabled(index)()) {
      document.getElementById(`step-btn-${index}`).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'});
      props.jumpToStep(index);
    }
  }

  return <>
    <Stepper className='booking-stepper' style={{marginBottom: isLayoutMedium ? '24px' : 0}} activeStep={props.bookingState.activeStep.index} alternativeLabel nonLinear connector={<Connector />}>
      {steps.map((s) => (
        <Step key={s.index} completed={props.bookingState.completedSteps.includes(s.index)}>
          <StepButton id={`step-btn-${s.index}`} variant="text" onClick={onStepClick(s.index)} disabled={!(s.index === 0 || props.bookingState.completedSteps.includes(s.index - 1)) || (props.bookingState.bookingCompleted === true)}>
            <StepLabel StepIconComponent={StepIcon}>{t(s.title)}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  </>
}
