import { Box, Grid, Link, Stack } from '@mui/material';
import { SectionTitle } from '../components/section-title/section-title';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const TermsAndConditionsPageUK: React.FC<{}> = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={'Terms & conditions'} />
            <Box marginTop={3}>
              <b>Barrhead Travel</b>
              <br />
              <Link
                href="https://drive.google.com/file/d/11YcFMIY8Z1RVy-5_bp2m72sJgrZS58p3/view?usp=share_link"
                target="_blank"
              >
                Open PDF
              </Link>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
TermsAndConditionsPageUK.displayName = 'TermsAndConditionsPageUK';