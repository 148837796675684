import { Box, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ItineraryStep } from '../../services/search';
import { ColorButton } from '../color-button/color-button';
import './cruise-itinerary.scss';
import { ReactComponent as ShipIcon } from './images/ship.svg';
import { ReactComponent as MapIcon } from './images/map-point.svg';
import { ReactComponent as AnchorIcon } from './images/anchor.svg';

interface CruiseItineraryScheduleProps {
  itinerary: Array<ItineraryStep>;
}

export const CruiseItinerarySchedule: React.FC<CruiseItineraryScheduleProps> = (props) => {
  // Translation
  const { t } = useTranslation('common');

  const StepIcon = (args: { isCruising: boolean, departureTime?: string, arrivalTime?: string }) => {
    let icon = ShipIcon;
    let viewBox = "0 0 20 20";
    if (!args.isCruising) {
      icon = (args.departureTime && args.arrivalTime) ? AnchorIcon : MapIcon;
      viewBox = "0 0 24 24";
    }

    return <>
      <Grid item xs={12} maxHeight={24}><SvgIcon component={icon} style={{ width: '24px', height: '24px' }} viewBox={viewBox} /></Grid>
      {(args.isCruising || args.departureTime) && <Grid item xs={12} className="arrow-vertical" height={32}></Grid>}
    </>;
  }

  return (
    <>
      <Stack spacing={3}>
        <Grid container columnSpacing={2}>
          <Grid item container xs={2} textAlign="center" spacing={0} alignContent="space-evenly">
            {props.itinerary.map((step: ItineraryStep, index: number) => (
              <StepIcon key={index} isCruising={step.isCruising} departureTime={step.departureTime} arrivalTime={step.arrivalTime} />
            ))}
          </Grid>

          <Grid item xs={10}>
            <Stack spacing={3}>
              {props.itinerary.map((step: ItineraryStep, index: number) => (
                <Stack key={index} spacing={0}>
                  <Typography variant="body1" textAlign="left" fontSize={12} fontWeight={600}>
                    <Trans t={t} i18nKey={'booking.departure.itinerary-schedule.day'} values={{day: step.day}}></Trans>
                  </Typography>
                  <Typography variant="body1" textAlign="left" fontSize={14} fontWeight={600}>
                    {t(step.name)}
                  </Typography>
                  {!step.isCruising && 
                    <Typography variant="body1" textAlign="left" fontSize={14} lineHeight={'14px'}>
                      {index === 0 && step.departureTime && <Trans t={t} i18nKey={'booking.departure.itinerary-schedule.departure-time'} values={{time: step.departureTime}}></Trans>}
                      {index > 0 && index < props.itinerary.length - 1 && step.departureTime && step.arrivalTime &&
                        <Trans t={t} i18nKey={'booking.departure.itinerary-schedule.departure-and-arrival-time'} values={{timeFrom: step.arrivalTime, timeTo: step.departureTime}}></Trans>
                      }
                      {index === props.itinerary.length - 1 && step.arrivalTime && <Trans t={t} i18nKey={'booking.departure.itinerary-schedule.arrival-time'} values={{time: step.arrivalTime}}></Trans>}
                    </Typography>
                  }
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};