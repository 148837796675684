export class Codes {
  static cabinCategoryOrder = new Map(
    Object.entries({
      inside: 1,
      oceanview: 2,
      balcony: 3,
      suite: 4,
      solo_studio: 5
    })
  );

  static currencies = new Map(
    Object.entries({
      HUF: {
        prefix: '',
        postfix: ' Ft'
      },
      EUR: {
        prefix: '€',
        postfix: ''
      },
      GBP: {
        prefix: '£',
        postfix: ''
      }
    })
  );
}
