import React, { useEffect, useState } from 'react';
import '../../styles/booking.scss';
import { BookingDeparturePage } from './step1-departure';
import { BookingCabinTypePage } from './step2-cabin-type';
import { BookingGuestsPage } from './step4-guests';
import { Box, Stack } from '@mui/material';
import { BookingCruiseDetails, BookingGuestState, BookingState, BookingSteps, CheckoutStatus } from '../../models/BookingState';
import { useNavigate, useParams } from 'react-router-dom';
import BookingStepper from '../../components/booking-stepper/booking-stepper';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { BookingServicesPage } from './step3-services';
import { BookingConfirmation } from './step5-booking-confirmation';
import { useSearchApi } from '../../contexts/search-api.context';
import { useCheckoutApi } from '../../contexts/checkout-api.context';


export const BookingForm: React.FC<{
  bookingState: BookingState,
  scrollToTop: () => void,
  cruiseSearchFilters: CruiseSearchFilters,
  updateBooking: (b: BookingState) => void
}> = (props) => {

  // Navigation
  let navigate = useNavigate();
  let { cruiseLine, cruiseGroupId, stepParam, cruiseId } = useParams();

  // States
  const steps = BookingSteps;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  // Api Context
  const { searchApi } = useSearchApi();
  const { checkoutApi } = useCheckoutApi();

  const {bookingState, updateBooking} = props;

  // Methods
  const initBookingEntity = () => {
    
    if (!bookingState.bookingId) {
      checkoutApi.createBooking(
        bookingState.toCreateBookingPayload()
      ).then((response) => {
        bookingState.bookingId = response.data.bookingId;
        updateBooking(bookingState);
      }).catch((error) => {
        
      });
    }
  }

  const loadBookingDetails = () => {
    if (cruiseLine && cruiseGroupId) {
      setIsLoading(true);
      
      const p = searchApi.getCruiseDetails(cruiseGroupId, props.cruiseSearchFilters.toSearchRequest());
      
      p.then((response) => {
        
        if (response.data?.cruiseGroupId) {

          // reset booking to avoid using data from previous attempt from browser storage
          if (bookingState.bookingDetails?.cruiseGroupId !== cruiseGroupId) {
            bookingState.reset();

            // Create empty guest objects for the number of selected in filters
            bookingState.numberOfGuests = props.cruiseSearchFilters.guests;
            const totalGuestCount = bookingState.numberOfGuests.adult + bookingState.numberOfGuests.child;
            
            if (bookingState.guestsState.guestList.length < totalGuestCount) {
              for (let i = 0; i < bookingState.numberOfGuests.adult; i++) {
                bookingState.guestsState.guestList.push(new BookingGuestState({type: 'adult'}));
              }
              for (let i = 0; i < bookingState.numberOfGuests.child; i++) {
                bookingState.guestsState.guestList.push(new BookingGuestState({type:'child'}));
              }
            }
            navigate({pathname: `/book/${cruiseLine}/${cruiseGroupId}/${bookingState.activeStep.path}`}, {replace: true});
          }

          bookingState.bookingDetails = new BookingCruiseDetails(response.data);

          if (cruiseId) {
            const d = bookingState.bookingDetails.departureDates?.find((e) => e.id === cruiseId) || null;
            if (d) {
              props.bookingState.departureDate = d;
              props.bookingState.pricePerPerson = d.pricePerPerson;
              props.bookingState.pricePerPersonLocalCcy = d.pricePerPersonLocalCcy;

              // checkoutApi.updateBooking(
              //   props.bookingState.toUpdateBookingPayload(CheckoutStatus.DATE_SELECTED)
              // );
            }
          }

          updateBooking(bookingState);
          
          initBookingEntity();

          
        } else {
          navigate({
            pathname: '/not-found'
          });
        }
        setIsLoading(false);
      }).catch((error) => {
        navigate({
          pathname: '/not-found'
        });
        setIsLoading(false);
      });
    }
  }

  const onSuccess = () => {
    bookingState.bookingCompleted = true;
    props.bookingState.completedSteps.push(4);
    jumpToStep(bookingState.activeStep.index + 1);
  }

  const nextStep = () => {
    jumpToStep(bookingState.activeStep.index + 1);
  };

  const previousStep = () => {
    jumpToStep(bookingState.activeStep.index - 1);
  };

  const scrollStepper = (index: number) => {
    const e = document.getElementById(`step-btn-${index}`);
    if (e) {
      scrollIntoView(e, { behavior: "smooth", block: "center", inline: "center" });
    }
  }

  const jumpToStep = (index: number, replaceUrl = false) => {
    props.scrollToTop();
    const nextStep = steps[index];
    bookingState.activeStep = nextStep;
    updateBooking(bookingState);
    
    scrollStepper(nextStep.index);
    
    navigate({pathname: `/book/${cruiseLine}/${cruiseGroupId}/${nextStep.path}`}, {replace: replaceUrl});
  };

  useEffect(() => {

    // Load step from URL
    if (stepParam) {
      props.scrollToTop();
      const urlStep = steps.find((e) => e.path === stepParam);
      if (urlStep && urlStep.index !== bookingState.activeStep.index) {
        jumpToStep(urlStep.index, true);
      }
    }
  }, [stepParam]);

  useEffect(() => {
    props.scrollToTop();
    loadBookingDetails();
  }, []);

  // Components

  //<pre>{JSON.stringify(bookingState, null, 2)}</pre>

  return (
    <Stack className='booking-form' spacing={0}>
        <Box className='stepper-container' style={{overflow: 'hidden'}}>
          <BookingStepper bookingState={bookingState} jumpToStep={jumpToStep} />
        </Box>
        {!isLoading && (() => {
          switch (bookingState.activeStep.index) {
            case 0:
              return <BookingDeparturePage {...props} onSubmit={nextStep} />
            case 1:
              return <BookingCabinTypePage {...props} stepBack={previousStep} onSubmit={nextStep} />
            case 2:
              return <BookingServicesPage {...props} stepBack={previousStep} onSubmit={nextStep} />
            case 3:
              return <BookingGuestsPage {...props} stepBack={previousStep} onSubmit={onSuccess} />
            case 4:
              return <BookingConfirmation {...props} />
            default:
              return null
          }
        })()}
    </Stack>
  );
}
