
import { Box, Grid, Stack, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../../components/section-box/section-box';
import { SectionTitle } from '../../components/section-title/section-title';
import { SubscriptionBox } from '../../components/subscription-box/subscription-box';

export const AboutPage: React.FC<{  }> = (props) => {
  const { t, i18n } = useTranslation('common');
  return <>
    <Box>
      <Stack spacing={8} >
        <Box padding={{xs: '0 20px', md: '0 100px'}}>
          <SectionTitle title={t("about.title")} />
          <SectionBox boxParams={{style: {marginTop: '40px'}}}>
            <h3>{t('about.subtitle1')}</h3>
            <p>{t('about.p1')}</p>
            <p>{t('about.p2')}</p>
            <p>{t('about.p3')}</p>

            <h3>{t('about.subtitle2')}</h3>
            <p>{t('about.p4')}</p>
            <p>{t('about.p5')}</p>
            <p>{t('about.p6')}</p>
            <p>
              {t('about.p7')}
              <span>&nbsp;</span>
              <strong>
                <Link href="/search/">{t('about.here')}</Link>
              </strong>
            </p>
            {__CONFIG__.pages.blog.blogEnabled && <p>{t('about.p8')}<strong><Link href={t('about.blog-link')}>{t('about.blog-link')}</Link></strong></p>}
            <p>
              <div>{t('about.p9')}</div>
              <br />
              <div><strong><Link href={`mailto:${__CONFIG__.support.email}`}>{__CONFIG__.support.email}</Link></strong></div>
              {__CONFIG__.support.phone && <div><strong><Link href={`tel:${__CONFIG__.support.phone.replace(/ /g, '')}`}>{__CONFIG__.support.phone}</Link></strong></div>}
            </p>
          </SectionBox>
        </Box>
      </Stack>
    </Box>
  </>
}
