import { Box, Grid, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { ColorButton } from '../../components/color-button/color-button';
import { CruiseSearchResultListItem } from '../../components/cruise-search-result-list-item/cruise-search-result-list-item';
import { SubscriptionBox } from '../../components/subscription-box/subscription-box';
import { CruiseDetails } from '../../services/search';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BookingGuestState, BookingState, CheckoutStatus, IBookingGuestState } from '../../models/BookingState';
import { useCheckoutApi } from '../../contexts/checkout-api.context';

export const CostaToscanaDubaiLanding: React.FC<{}> = (props) => {
  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const { checkoutApi } = useCheckoutApi();

  const [openedOffers, setOpenedOffers] = useState<Array<boolean>>([false, false, false, false, false]);
  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);
  const [offerName, setOfferName] = useState<string>();
  const [offerEmail, setOfferEmail] = useState<string>();
  const [offerPhone, setOfferPhone] = useState<string>();
  const [offerMessage, setOfferMessage] = useState<string>('');

  const offers = [
    {
      title: 'Belső, ablak nélküli kabin €359/fő',
      text: '',
      image: 'costa-inside.webp'
    },
    {
      title: 'Ablakos kabin €499/fő',
      text: '',
      image: 'costa-oceanview.webp'
    },
    {
      title: 'Balkonos kabin €669/fő',
      text: 'Az árak 2 fő/kabin esetén érvényesek. Minden kabin foglalható franciaágyas és 2 db egyszemélyes ággyal is. Egyágyas felárról érdeklődj az ajánlatkérésnél.',
      image: 'costa-balcony.webp'
    },
    {
      title: 'Mit tartalmaz az ár?',
      text: 'Szállást a választott kabinkategóriában. Teljes ellátást a hajón: minden étkezést, a büfében kávét, teát, vizet és gyümölcsleveket automatából, valamint a kikötői illetéket. Edzőterem, jacuzzi- és medencehasználatot a hajó fedélzetén. Szórakoztató műsorok, bulik és színházi előadásokat a hajón.',
      text2:
        'Az ár nem tartalmazza a repülőjegyet (ajánlott utazás: egyéni foglalás alapján), a kötelező borravalót a fedélzeten (11€/éj), a kikötőbe jutás költségét, alkoholos italokat és üdítőket, minibárt, fakultatív kirándulásokat.',
      image: 'costa02.webp'
    },
    {
      title: 'A Costa Toscana,',
      text: 'a Costa legújabb és legmodernebb hajója tágas szabadtéri részein élvezheted a napsütést, a jó időt és a tengert. Nemzetközi művészek előadásai, minden este szórakoztató műsorok, valamint medencék és csúszdák, korszerű spa és edzőterem vár a fedélzeten.',
      image: 'costa03.webp'
    },
    {
      title: 'Sziasztok! Ági vagyok,',
      text: 'és az utazás a legnagyobb szenvedélyem. Mindig is úgy gondoltam, hogy az élményekért érdemes igazán élni, és ennek érdekében mindent meg is teszek: főállású munka mellett már 50 országban jártam az évek során, és rengeteg emléket szereztem a világ minden tájáról. Az utazás és annak megtervezése az, ami még a legszürkébb hétköznapokon is mosolyt csal az arcomra. Részt vettem már földközi-tengeri és karib-tengeri hajóúton is, és elmondhatom, hogy igazán szeretem ezt az utazási formát, hiszen passzol az én pörgős utazási stílusomhoz. Több országot tudsz felfedezni rövidebb idő alatt, emellett pedig isteni finom ételeket kóstolhatsz meg, izgalmas programokon vehetsz részt. Találkozunk a fedélzeten!',
      image: 'costa04.webp'
    },
    {
      title: 'Sziasztok! Mónika vagyok,',
      text: 'jogászként és kutatóként dolgozom, mellette utazom, lehetőségeim szerint minden szabadidőt felhasználva. Az utazás számomra nemcsak a látnivalókról szól, hanem a helyiekről, a kapcsolódásokról is, éppen ezért különösen szeretem az arab országokat, ahová januárban együtt is utazhatunk! A világ elképesztően változatos, tele van el nem mondott történetekkel, rajtunk áll, hogy mennyit ismerünk meg belőle.',
      image: 'costa05.webp'
    }
  ];

  const cruises: Array<CruiseDetails> = [
    {
      imageUrls: [
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/cover-08_EsternoFinale_Rev0_hires(00000)_1.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-deck.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-healthy-food.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-indoor-pool.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-jacuzzi.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-open-deck.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-pool.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-restaurant.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-spa-jacuzzi.jpeg',
        'https://clickncruise.hu/static/images/cruiselines/COSTA/ships/COSTA_TO/ship-pictures/costa-toscana-spa.jpeg'
      ],
      cruiseLineLogoUrl: 'https://clickncruise.hu/static/images/cruiselines/COSTA/logo.png',
      cruiseLine: 'COSTA',
      shipCode: 'COSTA_TO',
      shipName: 'Costa Toscana',
      destinationRegion: 'Dubai & UAE',
      portsOfCall: [
        'Dubai, United Arab Emirates',
        'Dubai, United Arab Emirates',
        'Cruising',
        'Muscat',
        'Muscat',
        'Abu Dhabi',
        'Abu Dhabi',
        'Dubai, United Arab Emirates'
      ],
      pricePerPerson: 299,
      priceLocalCcy: 121650,
      cruiseGroupId: 'costa_dxb_dxb_7_to_4350d',
      departureDates: [
        {
          id: 'COSTA_TO07230120',
          fromDate: new Date('2023-01-20'),
          toDate: new Date('2023-01-27'),
          pricePerPerson: 299,
          pricePerPersonLocalCcy: 121650,
          refreshTime: new Date(),
          availableCount: 1
        }
      ],
      cruiseLength: 7,
      minDepartureDate: new Date('2023-01-20'),
      seaDays: 1,
      itinerary: [
        {
          day: 1,
          name: 'Dubai, United Arab Emirates',
          isCruising: false
        },
        {
          day: 2,
          name: 'Dubai, United Arab Emirates',
          isCruising: false,
          departureTime: '23:59:00'
        },
        {
          day: 3,
          name: 'Cruising',
          isCruising: true
        },
        {
          day: 4,
          name: 'Muscat',
          isCruising: false,
          arrivalTime: '07:00:00'
        },
        {
          day: 5,
          name: 'Muscat',
          isCruising: false,
          departureTime: '17:00:00'
        },
        {
          day: 6,
          name: 'Abu Dhabi',
          isCruising: false,
          arrivalTime: '18:30:00'
        },
        {
          day: 7,
          name: 'Abu Dhabi',
          isCruising: false,
          departureTime: '20:00:00'
        },
        {
          day: 8,
          name: 'Dubai, United Arab Emirates',
          isCruising: false,
          arrivalTime: '05:00:00'
        }
      ]
    }
  ];

  const toggleOffer = (offerIndex: number) => () => {
    let copy = [...openedOffers];
    copy[offerIndex] = !copy[offerIndex];

    setOpenedOffers(copy);
  };

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const handleGuestFieldChange = (event: any) => {
    const fieldId = event.target.id;
    const value = event.target.value;
    switch (fieldId) {
      case 'field-name':
        setOfferName(value);
        break;
      case 'field-email':
        setOfferEmail(value);
        break;
      case 'field-phone':
        setOfferPhone(value);
        break;
      case 'field-message':
        setOfferMessage(value);
        break;
    }
  };

  const handleSubmit = () => {
    const state = new BookingState(null);
    state.bookingId = 'f3d183ad-070d-47d9-8a01-5053b6b36ac0';

    const guest: IBookingGuestState = {
      firstName: offerName || '',
      email: offerEmail || '',
      phone: offerPhone || '',
      type: 'adult'
    };
    state.guestsState.guestList.push(new BookingGuestState(guest));
    state.guestsState.notes = `COSTA TOSCANA DUBAI - ${offerMessage}`;

    checkoutApi.updateBooking(state.toUpdateBookingPayload(CheckoutStatus.GUESTS_PROVIDED));

    handleDialogClose();
  };

  const OfferButton = () => {
    return (
      <>
        <Box textAlign="center" style={{ margin: '8px' }}>
          <ColorButton
            label="Ajánlatot kérek!"
            onClick={handleDialogOpen}
            style={{ width: '300px', textTransform: 'uppercase' }}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Box
        paddingLeft={!isLayoutMedium ? 10 : 0}
        paddingRight={!isLayoutMedium ? 10 : 0}
        marginTop={isLayoutMedium ? -1 : 0}
        style={{ borderTopRightRadius: '35px', borderTopLeftRadius: '35px', overflow: isLayoutMedium ? 'hidden' : '' }}
      >
        <Stack spacing={isLayoutMedium ? 4 : 8}>
          {isLayoutMedium && (
            <img src="/landings/costa01.webp" alt="header" style={{ objectFit: 'cover', maxHeight: '200px' }} />
          )}
          <Box style={{ margin: '0 16px' }}>
            <Typography variant="h2" style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 12 }}>
              Mindig is ki akartad próbálni a hajózást, de nem mertél nekivágni egyedül?
            </Typography>
            <Typography variant="h3" style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 8 }}>
              Szeretnéd bebarangolni Dubaj, Omán és Abu Dhabi városait egyetlen hét alatt, miközben kényelmesen utazol?
            </Typography>
            <Typography variant="h3" style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 4 }}>
              Most két travel bloggerrel, Ágival és Mónikával fedezheted fel a Perzsa-öböl kincseit a vadonatúj Costa
              Toscana fedélzetén!
            </Typography>
            <Typography
              variant="h3"
              style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 12, fontWeight: 'normal' }}
            >
              Dátum: 2023 Január 20 - 27
              <br />
              Útvonal: Dubai - Doha - Muscat - Abu Dhabi - Dubai
            </Typography>
            <OfferButton />
          </Box>
          {offers.map((offer, index) => (
            <Grid
              key={index}
              container
              columnSpacing={isLayoutMedium ? 2 : 7}
              flexDirection={index % 2 !== 0 ? 'row-reverse' : 'row'}
              style={{ width: isLayoutMedium ? 'calc(100% - 16px)' : 'calc(100% - 56px)' }}
            >
              <Grid item xs={12} md={6}>
                <img src={`/landings/${offer.image}`} alt="offer" width={'100%'} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack textAlign="left">
                  <Typography variant="h3" style={{ fontSize: 22, fontWeight: 600, marginTop: 2 }}>
                    {offer.title}
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: 16, fontWeight: 400, marginTop: 2 }}>
                    {offer.text}
                    {offer.text2 && (
                      <>
                        <br />
                        <br />
                        {offer.text2}
                      </>
                    )}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          ))}
          <Typography
            variant="h1"
            style={{ textAlign: 'center', fontSize: 36, fontWeight: 600, marginTop: isLayoutMedium ? '32px' : '64px' }}
          >
            Foglalás
          </Typography>
          <OfferButton />
          <Grid container spacing={4} style={{ width: 'calc(100% - 32px)' }} justifyContent="center">
            {cruises.map((cruise, index) => (
              <Grid key={index} item xs={12} md={4}>
                <CruiseSearchResultListItem
                  data={cruise}
                  forceMediumLayout={true}
                  showButton={false}
                  showPrice={false}
                />
              </Grid>
            ))}
          </Grid>
          <Dialog open={isDialogOpened} onClose={handleDialogClose} fullScreen={isLayoutMedium}>
            <DialogTitle>
              Ajánlatot kérek
              <IconButton
                aria-label="Bezar"
                onClick={handleDialogClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Stack spacing={3} padding={{ xs: 1, md: 8 }}>
                  <TextField
                    id="field-name"
                    label={null}
                    variant="outlined"
                    placeholder="Név"
                    onChange={handleGuestFieldChange}
                  />
                  <TextField
                    id="field-email"
                    label={null}
                    variant="outlined"
                    placeholder="E-mail cím"
                    onChange={handleGuestFieldChange}
                  />
                  <TextField
                    id="field-phone"
                    label={null}
                    variant="outlined"
                    placeholder="Telefonszám"
                    onChange={handleGuestFieldChange}
                  />
                  <TextField
                    id="field-message"
                    label={null}
                    variant="outlined"
                    placeholder="Üzenet"
                    multiline
                    minRows={2}
                    maxRows={4}
                    onChange={handleGuestFieldChange}
                  />
                  <ColorButton label="Elküldöm!" onClick={handleSubmit} />
                </Stack>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Grid item>
            <SubscriptionBox />
          </Grid>
        </Stack>
      </Box>
    </>
  );
};
