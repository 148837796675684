import '../../styles/contact-page.scss';
import { useState } from 'react';
import { Box, Stack, Link, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { SectionBox } from '../../components/section-box/section-box';
import { SectionTitle } from '../../components/section-title/section-title';
import { SubscriptionBox } from '../../components/subscription-box/subscription-box';
import { ColorButton } from '../../components/color-button/color-button';
import { ContactForm } from '../../components/contact-form/contact-form';
import { BookingGuestState, BookingState, IBookingGuestState } from '../../models/BookingState';

export const ContactPage: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');

  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);

  const onFaqClick = () => {
    window.location.href = t('contact.faq-page-link');
  };

  const handleContactFormSubmit = (booking: BookingState, message?: string) => {
    setIsMessageSent(true);
  };

  const SectionHeader = (args: { text: string }) => {
    return (
      <Typography
        variant="h2"
        textAlign={{ xs: 'left', sm: 'left', md: 'center', lg: 'center' }}
        marginTop={'40px'}
        marginBottom={'20px'}
      >
        {args.text}
      </Typography>
    );
  };

  return (
    <>
      {__CONFIG__.pages.contact.pageEnabled && (
        <Box className="contact-page">
          <Stack spacing={8}>
            <Box padding={{ xs: '0 20px', md: '0 100px' }}>
              <SectionTitle title={t('contact.title')} />
              <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
                {__CONFIG__.pages.faq.faqEnabled && (
                  <>
                    <Box maxWidth={'900px'} margin={'auto'}>
                      <Typography variant="body1" textAlign={{ xs: 'left', sm: 'left', md: 'center', lg: 'center' }}>
                        {t('contact.p1')}
                      </Typography>
                    </Box>
                    <Box textAlign={'center'} marginTop={'20px'}>
                      <ColorButton label={t('contact.read-faq-btn-label')} onClick={onFaqClick} />
                    </Box>
                  </>
                )}

                <SectionHeader text={t('contact.chat-section-title')} />
                <Box textAlign={'center'}>
                  <ColorButton label={t('contact.open-chat-btn-label')} href={__CONFIG__.support.messengerLink} />
                </Box>
              </SectionBox>

              <SectionBox>
                <SectionHeader text={t('contact.contact-form-section-title')} />
                <Box maxWidth={'600px'} margin={'auto'} className="contact-form">
                  {!isMessageSent && (
                    <ContactForm afterSubmit={handleContactFormSubmit} showMessageBox={true} source="contact" />
                  )}
                  {isMessageSent && (
                    <Typography variant="body1" textAlign={{ xs: 'left', sm: 'left', md: 'center', lg: 'center' }}>
                      {t('contact.message-sent')}
                    </Typography>
                  )}
                </Box>
              </SectionBox>

              <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
                <SectionHeader text={t('contact.email-section-title')} />
                <Box textAlign={'center'}>
                  <div>
                    <strong>
                      <Link href={`mailto:${__CONFIG__.support.email}`}>{__CONFIG__.support.email}</Link>
                    </strong>
                  </div>
                </Box>
                {__CONFIG__.support.phone && (
                  <>
                    <SectionHeader text={t('contact.phone-section-title')} />
                    <Box textAlign={'center'}>
                      <div>
                        <strong>
                          <Link href={`tel:${__CONFIG__.support.phone.replace(/ /g, '')}`}>
                            {__CONFIG__.support.phone}
                          </Link>
                        </strong>
                      </div>
                    </Box>
                  </>
                )}
                <SectionHeader text={t('contact.quotes.section-title')} />
                <Stack className="quotes" textAlign={'center'} spacing={7}>
                  <blockquote>
                    <Typography variant="body1" textAlign={{ xs: 'left', sm: 'left', md: 'center', lg: 'center' }}>
                      <Trans t={t} i18nKey={'contact.quotes.quote-1'}></Trans>
                    </Typography>
                    <span>{t('contact.quotes.quote-1-author')}</span>
                  </blockquote>
                  <blockquote>
                    <Typography variant="body1" textAlign={{ xs: 'left', sm: 'left', md: 'center', lg: 'center' }}>
                      <Trans t={t} i18nKey={'contact.quotes.quote-2'}></Trans>
                    </Typography>
                    <span>{t('contact.quotes.quote-2-author')}</span>
                  </blockquote>
                  <blockquote>
                    <Typography variant="body1" textAlign={{ xs: 'left', sm: 'left', md: 'center', lg: 'center' }}>
                      <Trans t={t} i18nKey={'contact.quotes.quote-3'}></Trans>
                    </Typography>
                    <span>{t('contact.quotes.quote-3-author')}</span>
                  </blockquote>
                </Stack>
              </SectionBox>

              <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
                <SectionHeader text={t('contact.footer-section.title')} />
                <Typography variant="body1" textAlign={{ xs: 'left', sm: 'left', md: 'center', lg: 'center' }}>
                  <Trans t={t} i18nKey={'contact.footer-section.content'}></Trans>
                </Typography>
              </SectionBox>
            </Box>

            <Box>
              <SubscriptionBox />
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
};
