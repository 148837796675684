import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../hooks/useTracking';
import { CruiseSearchFilters, GuestsFilter } from '../../models/CruiseSearchFilters';
import { FilterOptions, GuestFilterOptions } from '../../models/FilterOptions';
import { ColorButton } from '../color-button/color-button';
import { DatePickerInputRange } from '../input-datepicker/input-datepicker';
import { GuestsSelect } from '../input-guests-select/input-guests-select';
import { MultiSelect, SelectOption } from '../input-multiselect/input-multiselect';
import './filter-panel.scss';

export const FilterPanel: React.FC<{
  searchFilters: CruiseSearchFilters;
  updateSearchFilters: (f: CruiseSearchFilters) => void;
  onSearch: () => void;
  searchOnSelect: boolean;
}> = (props) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation('common');

  FilterOptions.guests.adult.count = props.searchFilters.guests.adult;
  FilterOptions.guests.child.count = props.searchFilters.guests.child;

  const isEnabledCruiseline = (option:SelectOption) => {
    return true;
  } 

  const getShipOptionsByCruiseLine = (cruiseLines: any): SelectOption[] => {
    let ships = FilterOptions.ships.filter(isEnabledCruiseline);
    if (cruiseLines.length > 0) {
      const c = cruiseLines.map((line: any) => (line.value || line).toLowerCase().slice(0, 5)).join('|');
      ships = ships.filter((s) => {
        return s.label.toLowerCase().match(`${c}`) || (c.includes('norw') && s.label.startsWith('Pride ')) || (c.includes('royal') && s.label.toLowerCase().includes('of the seas')) || (c.includes('pando') && s.label.toLowerCase().includes('p&o'));
      });
    }

    return ships;
  };

  // States
  const [shipOptions, setShipOptions] = useState<SelectOption[]>(
    getShipOptionsByCruiseLine(props.searchFilters.cruiseLine)
  );

  // Methods
  const searchButtonClick = () => {
    trackEvent('search', { filters: props.searchFilters.toUrlSearchParams() });
    props.onSearch();
    props.updateSearchFilters(props.searchFilters);
  };

  const searchOnSelect = () => {
    if (props.searchOnSelect === true) {
      searchButtonClick();
    }
  };

  const guestFilterChanged = (g: GuestFilterOptions) => {
    let gn: GuestsFilter = props.searchFilters.guests;
    gn.adult = g.adult.count || 0;
    gn.child = g.child.count || 0;

    searchOnSelect();
  };

  const dateFilterChanged = (from: Date | null, to: Date | null) => {
    props.searchFilters.when.from = from;
    props.searchFilters.when.to = to;
  };

  const multiSelectChanged = (name: string, values: SelectOption[]) => {
    const optionName = name.replace('filter-', '');
    (props.searchFilters as any)[optionName] = values.map((e) => e.value);

    searchOnSelect();
  };

  const cruiseLineChanged = (name: string, values: SelectOption[]) => {
    multiSelectChanged(name, values);

    setShipOptions(getShipOptionsByCruiseLine(values));
  };

  return (
    <>
      <Stack className="filter-panel" spacing={0}>
        <Grid container columnSpacing={{ xs: 0, sm: 3, md: 4 }} rowSpacing={1.5}>
          <Grid item xs={12} sm={6} md={12}>
            <GuestsSelect
              id="guests"
              label={t('search.filters.guest.label')}
              options={FilterOptions.guests}
              onChanged={guestFilterChanged}
              values={props.searchFilters.guests.getAllAsLabels(t)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="where"
              label={t('search.filters.where.label')}
              placeHolder={t('search.filters.where.placeholder')}
              options={FilterOptions.where}
              onChanged={multiSelectChanged}
              values={props.searchFilters.where}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <DatePickerInputRange
              id="when"
              label={t('search.filters.when.label')}
              onChanged={dateFilterChanged}
              values={props.searchFilters.when}
              onClose={searchOnSelect}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="cruiseLine"
              label={t('search.filters.cruiseLine.label')}
              placeHolder={t('search.filters.cruiseLine.placeholder')}
              options={FilterOptions.cruiseLine.filter(isEnabledCruiseline)}
              onChanged={cruiseLineChanged}
              values={props.searchFilters.cruiseLine}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="ship"
              label={t('search.filters.ship.label')}
              placeHolder={t('search.filters.ship.placeholder')}
              options={shipOptions}
              onChanged={multiSelectChanged}
              values={props.searchFilters.ship}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="cabinCategory"
              label={t('search.filters.cabinCategory.label')}
              placeHolder={t('search.filters.cabinCategory.placeholder')}
              options={FilterOptions.cabinCategory}
              onChanged={multiSelectChanged}
              values={props.searchFilters.cabinCategory}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="price"
              label={t('search.filters.price.label')}
              placeHolder={t('search.filters.price.placeholder')}
              options={FilterOptions.priceRange}
              onChanged={multiSelectChanged}
              values={props.searchFilters.price}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="cruiseLength"
              label={t('search.filters.cruiseLength.label')}
              placeHolder={t('search.filters.cruiseLength.placeholder')}
              options={FilterOptions.cruiseLength}
              onChanged={multiSelectChanged}
              values={props.searchFilters.cruiseLength}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="portsOfCall"
              label={t('search.filters.portsOfCall.label')}
              placeHolder={t('search.filters.portsOfCall.placeholder')}
              options={FilterOptions.portsOfCall}
              onChanged={multiSelectChanged}
              values={props.searchFilters.portsOfCall}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="intermediatePortsOfCall"
              label={t('search.filters.intermediatePortsOfCall.label')}
              placeHolder={t('search.filters.intermediatePortsOfCall.placeholder')}
              options={FilterOptions.intermediatePortsOfCall}
              onChanged={multiSelectChanged}
              values={props.searchFilters.intermediatePortsOfCall}
              translationKeyPrefix='search.filters.portsOfCall.options'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="routeOptions"
              label={t('search.filters.routeOptions.label')}
              placeHolder={t('search.filters.routeOptions.placeholder')}
              options={FilterOptions.routeOptions}
              onChanged={multiSelectChanged}
              values={props.searchFilters.routeOptions}
              translationKeyPrefix='search.filters.routeOptions.options'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="seaDays"
              label={t('search.filters.seaDays.label')}
              placeHolder={t('search.filters.seaDays.placeholder')}
              options={FilterOptions.seaDays}
              onChanged={multiSelectChanged}
              values={props.searchFilters.seaDays}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="sustainability"
              label={t('search.filters.sustainability.label')}
              placeHolder={t('search.filters.sustainability.placeholder')}
              options={FilterOptions.sustainability}
              onChanged={multiSelectChanged}
              values={props.searchFilters.sustainability}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <MultiSelect
              id="promo"
              label={t('search.filters.promo.label')}
              placeHolder={t('search.filters.promo.placeholder')}
              options={FilterOptions.promo}
              onChanged={multiSelectChanged}
              values={props.searchFilters.promo}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box textAlign="center" marginBottom={2}>
              <ColorButton label={t('search.search-button')} onClick={searchButtonClick} sx={{ minWidth: '70%' }} />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
