
import { Box, Grid, Stack } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { ColorButton } from '../components/color-button/color-button';
import { useNavigate } from 'react-router-dom';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const Page404: React.FC<{ message?: string, showButton?: boolean }> = (props) => {

  const {t} = useTranslation('common');
  const navigate = useNavigate();
  
  const handleButtonClick = () => {
    navigate({
      pathname: `/search`
    });
  }

  return <>
    <Stack textAlign="center">
      <Box marginBottom={8}>
      <img src="/site/404-ships.webp" alt="abta" height="" style={{width: '100%', maxWidth: '600px'}} />
      {props.message &&
        <h2 style={{margin: '40px auto'}}>
          <Trans t={t} i18nKey={props.message}></Trans>
        </h2>
      }
      {props.showButton && 
        <ColorButton
          label={t('page-404.look-around-btn-label')}
          onClick={(e) => {
            handleButtonClick();
          }}
        />
      }
      </Box>
      <SubscriptionBox />
    </Stack>
  </>
}

Page404.defaultProps = {
  message: 'page-404.cruise-not-found',
  showButton: true
};
