import React, { useState, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styled from '@mui/material/styles/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth0 } from '@auth0/auth0-react';
import { ColorButton } from '../color-button/color-button';
import { useTranslation } from "react-i18next";
import { useTracking } from '../../hooks/useTracking';

import { ProfileButton, ProfileImage, AuthMenuItemContainer } from './auth-menu-item.styles';

const AuthMenuItem: React.FC<{}> = (props) => {
  
  const { trackEvent, login: trackingLogin, logout: trackingLogout } = useTracking();  

  const { isLoading: isAuthLoading, user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const {t} = useTranslation('common');
  
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const [menuAnchor, setManueAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: isAuthenticated ? '#00BA88' : '#ED2E7E',
      color: isAuthenticated ? '#00BA88' : '#ED2E7E',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 5s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '20%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
      '0%': {
        opacity: 0,
      },
      '10%': {
        opacity: isAuthenticated ? 0 : 1,
      }
    },
  }));

  const handleLogout = () => {
    trackEvent('logout-button-clicked');
    trackingLogout();
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const handleLogin = () => {
    trackEvent('login-button-clicked');
    loginWithRedirect();
  }

  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setManueAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setManueAnchor(null);
  };

  const openUserDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDrawerOpen(!isDrawerOpen);
  };

  // Elements
  const LoginButton = () => {
    return <ColorButton className='header-button' label={t('account.login')} onClick={handleLogin} />;
  };

  const LoginButtonSmall = () => {
    return <>
      <IconButton onClick={handleLogin} aria-label={t('account.login')} title={t('account.login')}>
        <UserAvatar />
      </IconButton>
    </>;
  };

  const UserAvatar = () => {
    return <>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          {user && <ProfileImage
            src={user.picture}
            alt={user.name}
            sx={{ width: 28, height: 28 }}
          />}
          {!user && <PersonIcon />}
        </StyledBadge>
      </>;
  };

  useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      trackingLogin(user.sub);
    }
  });

  return (
    <AuthMenuItemContainer>
      {/* Loader */}
      {isAuthLoading && <Skeleton animation="wave" variant="rectangular" height='28px' width={isLayoutSmall ? '28px' : '138px'} sx={{borderRadius: '4px'}} />}

      {/* User menu button for desktop view */}
      {!isAuthLoading && isAuthenticated && user && !isLayoutSmall && 
        <div>
          <ProfileButton onClick={openUserMenu}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Box>
                <UserAvatar />
              </Box>
              <Box>
                <Typography variant='body1' color={'#fff'}>{user.name}</Typography>
              </Box>
            </Stack>
          </ProfileButton>
          <Menu
            id="user-menu"
            anchorEl={menuAnchor}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>{t('account.logout')}</MenuItem>
          </Menu>
        </div>
      }

      {/* User menu button for mobile view */}
      {!isAuthLoading && isAuthenticated && user && isLayoutSmall &&
        <div>
          <IconButton onClick={openUserDrawer}>
            <UserAvatar />
          </IconButton>
          <Drawer className="navigation-drawer" open={isDrawerOpen} onClose={() => setDrawerOpen(false)} anchor="right">
            <List>
              <ListItem onClick={() => setDrawerOpen(false)}>
                <ListItemText>
                  <Link className={'link'} onClick={handleLogout}>
                    {t('account.logout')}
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Drawer>
        </div>
      }

      {/* Login button for desktop view */}
      {!isAuthLoading && (!isAuthenticated || !user) && !isLayoutSmall && <LoginButton />}

      {/* Login button for mobile view */}
      {!isAuthLoading && (!isAuthenticated || !user) && isLayoutSmall && <LoginButtonSmall />}
    </AuthMenuItemContainer>
  );
};

export default AuthMenuItem;