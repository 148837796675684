//import { EncryptStorage } from 'encrypt-storage';

export const STORAGE_KEY_VERSION = 'cnc-v';
export const STORAGE_KEY_FILTER = 'cnc-f';
export const STORAGE_KEY_BOOKING = 'cnc-b';

export class BrowserStorage {

  private static instance: BrowserStorage;

  private constructor() {
    const prevVersion = this.getStorage().getItem(STORAGE_KEY_VERSION);
    if (!prevVersion || prevVersion !== __APP_VERSION__) {

      // Clean up prev version storage
      this.getStorage().removeItem(this.getVersionedKey(STORAGE_KEY_FILTER, prevVersion));
      this.getStorage().removeItem(this.getVersionedKey(STORAGE_KEY_BOOKING, prevVersion));

      // Store version
      this.getStorage().setItem(STORAGE_KEY_VERSION, __APP_VERSION__);
    }
  }
  
  public static getInstance(): BrowserStorage {
    if (!BrowserStorage.instance) {
        BrowserStorage.instance = new BrowserStorage();
    }

    return BrowserStorage.instance;
  }

  private getStorage = (): Storage => {
    //encryptStorage = new EncryptStorage('cnc');
    return localStorage;
  }

  private getVersionedKey = (key: string, version?: string|null): string => {
    
    return `${key}-${version||__APP_VERSION__}`;
  }

  public getItem = (key: string): any => {
    return this.getStorage().getItem(this.getVersionedKey(key));
  }
  
  public setItem = (key: string, item: any): any => {
    this.getStorage().setItem(this.getVersionedKey(key), item);
  }

}