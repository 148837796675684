import { Box, Stack, useMediaQuery, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorButton } from '../color-button/color-button';
import './contact-form.scss';
import { BookingCruiseCabin, BookingGuestState, BookingState, CheckoutStatus } from '../../models/BookingState';
import { useCheckoutApi } from '../../contexts/checkout-api.context';

type ContactField = 'email'|'phone'|'message';

export const ContactForm: React.FC<{
  bookingState?: BookingState,
  afterSubmit: (booking: BookingState) => void;
  source: string;
  showMessageBox?: boolean;
  requiredFields?: Array<ContactField>;
  cabinId?: string;
}> = (props) => {

  // Translation
  const { t } = useTranslation('common');

  const { checkoutApi } = useCheckoutApi();

  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const [guestEmail, setGeustEmail] = useState<string>('');
  const [guestPhone, setGuestPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false);

  const handleGuestFieldChange = (event: any) => {
    const fieldId = event.target.id;
    const value = event.target.value;
    switch (fieldId) {
      case 'field-email':
        setGeustEmail(value);
        break;
      case 'field-phone':
        setGuestPhone(value);
        break;
      case 'field-message':
        setMessage(value);
        break;
    }
  };

  const handleSubmit = (event: any) => {
    setIsSubmitPending(true);
    const bookingState = new BookingState(JSON.stringify(props.bookingState));
    
    // Fill selected cabin info
    if (bookingState.bookingDetails && props.cabinId) {
      const c = bookingState.bookingDetails.cabins?.find((e) => e.id === props.cabinId)||null;
      if (c) {
        const destinationRegion = bookingState.bookingDetails?.destinationRegion;
        const firstPortOfCall = bookingState.bookingDetails.portsOfCall[0];

        bookingState.cabinType = new BookingCruiseCabin(c, destinationRegion, firstPortOfCall);
        bookingState.pricePerPerson = c.pricePerPerson;
        bookingState.pricePerPersonLocalCcy = c.pricePerPersonLocalCcy;
      }
    }

    bookingState.source = props.source;
    bookingState.guestsState.guestList[0] = new BookingGuestState();
    bookingState.guestsState.guestList[0].email = guestEmail;
    bookingState.guestsState.guestList[0].phone = guestPhone;
    bookingState.guestsState.guestList[0].type = 'adult';
    bookingState.guestsState.notes = props.source + (message ? `: ${message}` : '');

    if (!props.bookingState) {
      bookingState.bookingId = 'f3d183ad-070d-47d9-8a01-5053b6b36ac0';
    }

    // Save booking
    checkoutApi.updateBooking(
      bookingState.toUpdateBookingPayload(CheckoutStatus.GUESTS_PROVIDED)
    ).then((response) => {
      props.afterSubmit(bookingState);
    }).finally(() => {
      setIsSubmitPending(false);
    });
  };

  return (
    <Stack className='contact-form' spacing={3} padding={{ xs: 1, md: 5 }}>
      <Box>
        <Typography variant="body1" textAlign="left">
          {t('booking.departure.callback-dialog.phone-label')}
        </Typography>
        <TextField
          id="field-phone"
          label={null}
          variant="outlined"
          type='tel'
          placeholder={t('booking.departure.callback-dialog.phone-placeholder')}
          onChange={handleGuestFieldChange}
          style={{ width: '100%', minWidth: isLayoutMedium ? '' : '250px' }}
          required={props.requiredFields?.includes('phone')}
          inputProps={{autoComplete: "tel"}}
        />
      </Box>
      <Box>
        <Typography variant="body1" textAlign="left">
          {t('booking.departure.callback-dialog.email-label')}
        </Typography>
        <TextField
          id="field-email"
          label={null}
          variant="outlined"
          type='email'
          placeholder={t('booking.departure.callback-dialog.email-placeholder')}
          onChange={handleGuestFieldChange}
          style={{ width: '100%', minWidth: isLayoutMedium ? '' : '250px' }}
          required={props.requiredFields?.includes('email')}
          inputProps={{autoComplete: "email"}}
        />
      </Box>
      {props.showMessageBox === true && 
        <Box>
          <Typography variant="body1" textAlign="left">
            {t('booking.departure.callback-dialog.message-label')}
          </Typography>
          <TextField
            id="field-message"
            label={null}
            variant="outlined"
            placeholder={t('booking.departure.callback-dialog.message-placeholder')}
            onChange={handleGuestFieldChange}
            minRows={4}
            maxRows={6}
            multiline
            style={{ width: '100%', minWidth: isLayoutMedium ? '' : '250px' }}
            required={props.requiredFields?.includes('message')}
          />
        </Box>
      }
      <ColorButton label={t('booking.departure.callback-dialog.submit')} onClick={handleSubmit} disabled={isSubmitPending}/>
    </Stack>
  );
};

ContactForm.defaultProps = {
  showMessageBox: false,
  requiredFields: []
}
