
import { Box, Grid, Stack } from '@mui/material';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const TermsAndConditionsPageHU: React.FC<{  }> = (props) => {
  
  return <>
    <Box>
      <Stack spacing={8} >
        <Box padding={{xs: '0 20px', md: '0 100px'}}>
          <SectionTitle title={"Utazás Megrendelésre Szóló Szerződés általános szerződési feltételei"} />
          <SectionBox boxParams={{style: {marginTop: '40px'}}}>
          
          <h3>Preambulum</h3>
          <p>A jelen Általános Szerződési Feltételek (továbbiakban: ÁSZF) azokat a rendelkezéseket tartalmazzák, amelyeket CLICKnCRUISE Korlátolt Felelősségű Társaság (székhelye: 9141, Ikrény, Rét köz 2. B. ép; cégjegyzékszáma: 08-09-033950; adószáma: 27547111-1-08; Magyar Kereskedelmi Engedélyezési Hivatal nyilvántartási száma: U-001970; telefonszáma: 06203705292; továbbiakban CLICKnCRUISE), mint utazásközvetítő az utazásközvetítői tevékenységének igénybevétele során alkalmazni kell.</p>
          <p>Az ÁSZF az Utazásközvetítő által üzemeltetett www.clickncruise.hu internetes weboldalon (a továbbiakban: Weboldal) a CLICKnCRUISE által végzett utazásközvetítői tevékenység és az ahhoz kapcsolódó, a Weboldalon keresztül elérhető bármely további szolgáltatás igénybevételének szabályait tartalmazza.</p>
          <p>A jelen szolgáltatás használata a jelen ÁSZF elfogadásának minősül. Az utasok személyes adatainak kezeléséről az Adatvédelmi Tájékoztató rendelkezik. </p>
          
          <h3>1. Az utazásközvetítő</h3>
          <p>A CLICKnCRUISE díj ellenében vállalja, hogy az Utas részére más Utazásszervező által szervezett utazáshoz kapcsolódóan utazásközvetítői tevékenységet végez. A CLICKnCRUISE rendelkezik minden olyan engedéllyel, amelyek szükségesek a tevékenységének végzéséhez.</p>
          
          <h3>2. Az Utazásközvetítő tevékenysége, általános szabályok</h3>
          <p>2.1. A CLICKnCRUISE az utazásszervező- és közvetítő tevékenységről szóló 213/1996. (XII. 23.) kormányrendelet szerinti utazásközvetítői tevékenységet végez, tevékenysége utazásszervezésre nem terjed ki. nyújtja. A CLICKnCRUISE tevékenysége kiterjedhet azonban a Weboldalon elérhető, az utazásközvetítéshez kapcsolódó egyéb szolgáltatások nyújtására is.</p>
          <p>2.2 A jelen ÁSZF nem része az utazási szerződésnek, kizárólag a CLICKnCRUISE és a Weboldal használói, látogatói (bárki, aki a Weboldalt meglátogatja, vagy annak tartalmát, tartalmának bármely részletét megismeri, vagy a Weboldal által elérhető szolgáltatásokat, vagy azok bármelyikét igénybe veszi, a továbbiakban együtt: Megrendelő/Utas) közötti szabályokat rögzíti.</p>
          <p>2.3. A CLICKnCRUISE és az Utas közötti szerződés nyelve a magyar. </p>
          <p>2.4. A CLICKnCRUISE a tevékenységét kizárólag online, az internet közcélú hálózatán keresztül végzi, ennek megfelelően működésére az információs társadalmi szolgáltatásokra irányadó szabályokat is alkalmazni kell.</p>
          <p>2.5. A Weboldal tartalma nyilvános, bárki számára elérhető. </p>
          <p>2.6. Utazási szerződés megkötésére ajánlatot (foglalás), vagy bármely más, kötelezettségvállalással járó nyilatkozatot tenni kizárólag 18. életévüket betöltött, teljes cselekvőképességgel rendelkező személyek jogosultak (akár saját nevükben, akár gazdasági társaság, vagy bármely más személy, szervezet képviseletében). Nem tartozik felelősséggel a CLICKnCRUISE az olyan kárért, vagy bármely más hátrányért, ami azért ér bárkit, mert az Utas életkorát, cselekvőképességét illetően a Weboldalon valótlanul nyilatkozik, vagy a CLICKnCRUISE-t e tekintetben egyéb módon megtéveszti.</p>
          <p>2.7. A CLICKnCRUISE rögzíti az Utas által a Weboldalon tett nyilatkozatokat, megadott adatokat. Amennyiben kétség merül fel ezen adatok, illetve nyilatkozatok tartalmát illetően, abban az esetben a CLICKnCRUISE rendszerében rögzített tartalmat kell valónak elfogadni. </p>
          <p>2.8. Amennyiben az Utas a foglalás során több utas részére rendeli meg az érintett utazási terméket, abban az esetben az Utast a CLICKnCRUISE a többi utas jogszerűen eljáró képviselőjének tekinti. Kizárólag az Utas felelős az olyan kárért, vagy bármely más hátrányért/joghátrányért, ami azért következik be, az Utas a többi utas vonatkozásában nem rendelkezett képviseleti jogosultsággal, vagy adatot nem megfelelően ad meg.</p>
          <p>2.9. Az Utas által a Weboldalon megadott adatok, illetve általuk tett nyilatkozatok tartalmát a CLICKnCRUISE nem vizsgálja, nem ellenőrzi. Kizárólag az Utas tartozik felelősséggel a CLICKnCRUISE, vagy harmadik személyt ért bármely kárért, vagy egyéb hátrányért/jogkövetkezményért, ami abból ered, hogy az Utas a valóságnak nem megfelelő adatot közöl, vagy egyébként valótlan tartalmú nyilatkozatot tesz.</p>
          <p>2.10. A Weboldal használata során a CLICKnCRUISE az Utas hozzájárulását kérheti, és az Utas a releváns jogszabályok által előírt formában és tartalommal hozzájárulást adhat a CLICKnCRUISE részére, hogy az Utazásközvetítő a részére reklámot közöljön.</p>
          <p>2.11. A látogató/Utas tudomásul veszi, hogy nem hivatkozhat arra, hogy a honlapon fellelhető utazást köteles biztosítani számára az utazási iroda, főleg akkor nem, ha időközben az utazásszervező levette az utat az oldalról.Az esetleges elírásokért az utazási iroda nem vállal felelősséget.A honlap meglátogatása a látogató vagy más harmadik fél és az utazási iroda között jogokat és kötelezettségeket magában foglaló jogviszonyt nem keletkeztet. Az utazási iroda közvetített utazásokkal kapcsolatos kötelezettségeket kizárólag az ezekkel kapcsolatos szerződések (pl. Utazási Szerződés) határozzák meg.</p>
          
          <h3>3. Az utazási szerződésekre vonatkozó általános tájékoztatás</h3>
          <p>3.1. Az utazásközvetítő az utazási termékeket az utazásszervezők és az Utasok között közvetíti. Az utazási szerződés minden esetben az Utazásszervező és az azt megrendelő személy, azaz Utas között jön létre, ebből adódóan az utazásközvetítő nem felelős az utazási szerződés bármely szerződő fél általi hibás teljesítéséből fakadó következményekért.</p>
          <p>3.2. Az utazási szerződésre vonatkozó szabályokat az utazásszervezők nyomtatványai, általános szerződési feltételei, egyéb, szerződéses normát tartalmazó dokumentumai, valamint az utazási szerződésre vonatkozó jogszabályok (így különösen az utazási szolgáltatásokra vonatkozó szerződésekről, különösen az utazási csomagra és az utazási szolgáltatásegyüttesekre vonatkozó szerződésekről szóló 472/2017. (XII. 28.) Korm. rendelet, valamint a Polgári Törvénykönyvről szóló 2013. évi V. törvény) határozzák meg.</p>
          <p>3.3. Az utazásközvetítő az utazásszervezők által rendelkezésére bocsátott tájékoztatókat és egyéb dokumentumokat változtatás nélkül teszi elérhetővé a weboldalon az Utasok számára. </p>
          <p>Ebből következően az utazásközvetítő nem vállal felelősséget azért, ha e dokumentumok bármelyikének tartalma részben, vagy egészben nem felel meg a valóságnak, vagy bármely jogszabállyal ellentétes tartalmú. Az Utas tudomásul veszi, hogy ha emiatt kár, vagy bármilyen egyéb hátrány éri, abban az esetben annak következményeit az utazásszervezővel szemben közvetlenül érvényesítheti.</p>
          <p>3.4. Az Utas tudomásul veszi azt is, hogy a CLICKnCRUISE a Weboldalon az utazásszervezők által a rendelkezésére bocsátott adatokat, képeket, videókat stb. jeleníti meg – különös tekintettel a kabinadatokra -, ennek megfelelően nem vállal felelősséget azért, ha ezen adatok bármelyike (így különösen, de nem kizárólagosan az utazás díja, vagy bármely egyéb költségei, vagy az utazás leírása) egészben, vagy részben nem felel meg a valóságnak.</p>
          <p>3.5. Az utas tudomásul veszi, hogy a Weblapon megjelenő adatok (így különösen, de nem kizárólagosan az utazás díja, vagy egyéb költségei, vagy akár az érintett utazás megrendelhetősége) tájékoztató jellegűek, és ennek megfelelően változhatnak. A végleges adatokat az utazásszervező a foglalás visszaigazolásában küldi meg az utas részére.</p>
          
          <h3>4. A foglalás menete</h3>
          <p>4.1. A Szolgáltatásra történő regisztráció és a Szolgáltatás igénybevételének feltétele a Szabályzat ismerete és elfogadása. A Szolgáltatás használata a jelen Szabályzat elfogadásának minősül.</p>
          <p><span>A Weboldalon történt foglalással minden Utas kijelenti, hogy elfogadja jelen Szabályzatot, tisztában van a foglalás menetével. Az utazásszervező általános szerződési feltételei elérhetőek az Utas számára megküldött visszaigazolásban, valamint a </span>
            <span> <a href="https://www.clickncruise.hu">www.clickncruise.hu</a></span><span>&nbsp;oldalon megtalálható linkre kattintással. </span>
          </p>
          <p>4.3. A foglalási űrlap kitöltésével és elküldésével nem jön létre utazási szerződés. </p>
          
          <p>4.4. Az utazási szerződés az utas és az utazásszervező között szerződés az utazásszervező foglalásra küldött visszaigazolása alapján, ezen visszaigazolásban írásban rögzített feltételek szerint az utazási díj előlegének megfizetése után jön létre. </p>
          <p>
            <ul>
              <li>Az Utas az utazásközvetítő weboldalán elérhető “Hajóút Kereső” használatával az utazási ajánlatok között kereshet (ennek során különböző szűrési feltételeket állíthat be), majd kiválaszt egy ajánlatot, ezt követően választhat a különböző indulási időpontok közül.</li>
              <li>A következő lépés a “Kabintípus” (belső, ablakos, balkonos, lakosztály, solo stúdió) kiválasztása - a kabin elhelyezkedéséért a hajón belül nem vállalunk felelősséget, a szabad helyek függvényében kerül kiosztásra -.</li>
              <li>Ezt követi az “Utasadatok” megadása: vezetéknév, keresztnév, állampolgárság, nem, születési dátum, telefonszám, email cím, mely adatok továbbításra kerülnek a hajótársaság felé a foglalás véglegesítése és az utazási szerződés létrejötte érdekében. Az utazáson gyermek részvételére lehetőség van, ez esetben a szülő email címe és telefonszáma megadása is kötelező.</li>
              <li>Ezt követi a “Számlázási adatok” megadása: vezetéknév, keresztnév, ország, város, cím, irányítószám.</li>
              <li>Ezt követi a “jogi nyilatkozatok elfogadása” (azaz a CLICKnCRUISE ÁSZF, a hajótársaság saját ÁSZF-je, az Adatkezelési tájékoztató elfogadása), mely kötelező a továbblépéshez.</li>
              <li>Az utolsó lépés a fizetés a “Stripe integráción” keresztül, melynek során az Utas fizetési módot választhat (bankkártya, Google Pay). A feltüntetett forintosított árak tájékoztató jellegűek, az árfolyam az MNB API-ból származik, a fizetés Euróban történik az Utas bankkártyájáról az Utas bankjának eladási árfolyamán. Foglaláskor az előleg vagy a foglalás időpontjától függően a teljes összege kerül levonásra. Felhívjuk az Utas figyelmét, hogy a foglalás során az „ajánlat összegzése” ablakban található fizetési ütemezést – mely utazásszervezőnként eltérhet – tartsa szem előtt és foglaláskor a megfelelő összeg álljon rendelkezésre a számlán. Amennyiben valamely összeg a CLICKnCRUISE-on kívül eső hibából a megadott határidőig nem vonható le, úgy egy alkalommal a CLICKnCRUISE email értesítést küld az Utasnak, majd a második sikertelen levonás esetén a foglalást törli az adott utazásszervező Általános Szerződési Feltételei szerinti jogkövetkezménnyel. </li>
              <li>A fizetési mód választásával az Utas rögzítette a foglalási igényét.</li>
              <li>Az Utas automatikus email választ kap a megadott emailcímén a foglalási igény elküldéséről. </li>
              <li>A CLICKnCRUISE ezt követően megkezdi az Utas igényének a feldolgozását (munkaidőben): ennek során ellenőrzi a rendelkezésre álló kapacitást, foglaltsági állapotot, részvételi díjat, egyéb feltételeket.</li>
              <li>Amennyiben a foglalási igény valamilyen okból kifolyólag mégsem teljesíthető (egyidejű foglalás miatt nem állnak rendelkezésre szabad helyek, technikai hiba, jelentős árváltozás, stb.), úgy erről a CLICKnCRUISE emailben értesíti az Utast, és lehetőségei szerint alternatív ajánlatot küld. </li>
              <li>Amennyiben az előleg (vagy teljes összeg fizetése esetén a teljes összeg) jóváírásra kerül az Utazásközvetítő számláján (a foglalási folyamat során megadott kártyát terhelve), akkor (és csak akkor) a CLICKnCRUISE véglegesíti a foglalási igényt az adott utazásszervezőnél és elküldi az előlegről kiállított számviteli bizonylatot az Utas részére. </li>
              <li>A foglalás igény véglegesítéséről a CLICKnCRUISE megküldi az utazásszervező által kiállított visszaigazolást, valamint emailben megküldi az elektronikusan már elfogadott és előzetesen megismert dokumentumokat, és az Utas által kinyomtatandó és aláírva visszaküldendő utazási szerződést, valamint az Utas információt kap arról, hogy az esetleges fizetési hátraléka mikor kerül levonásra a megadott bankkártyáról. </li>
            </ul>
          </p>

          <p>4.5. Amennyiben az Utas a foglalási igényét módosítani szeretné, akkor emailen kapcsolatba léphet az utazásközvetítővel, a foglalás módosítására az adott utazásszervező szabályai szerint van lehetőség!</p>
          <p>4.6. Az indulás előtt a szükséges utazási dokumentumokat a CLICKnCRUSIE emailben küldi meg az Utas számára a megadott emailcímre.</p>
          <p>4.7. Az Utas „Az Ajánlat összegzése” ablakban ellenőrizheti utazásának részleteit, a részvételi díj részleteit ill. végösszegét. Amennyiben bármilyen hibát vagy eltérést tapasztal, kapcsolatba tud lépni a CLICKnCRUSE-vel email útján vagy „chat” alkalmazásával.</p>
          <p>4.8. Felhívjuk a figyelmet, hogy a foglalás  során az Utas által feltüntetett adatok valódiságáért és helyességéért az Utas felel, a hibásan megadott adatok miatt bekövetkező esetleges joghátrányokért – így különösen a változtatás miatt felmerülő többletköltségért – a CLICKnCRUISE nem vállal felelősséget.</p>
          <p>4.9. Megfelelő gondosság és szakszerű ügyintézés esetén is előfordulhat, hogy a foglalás elküldése és a visszaigazolás, vagy a visszaigazolás és a fizetés közti időszak alatt a megrendelt utazásra elfogynak a szabad helyek. </p>
          <p>4.10. Amennyiben a foglalás elküldése és a visszaigazolás között elfogynak a helyek, úgy az utazásszervező/CLICKnCRUISE új ajánlatot ajánl az Utasnak, melyet külön el kell fogadnia és csak ezt követően jöhet létre az így módosított feltételek szerinti utazási szerződés. </p>
          <p>Amennyiben a visszaigazolás és a fizetés között elfogynak a helyek, ez esetben a CLICKnCRUISE haladéktalanul értesíti az utast a helyek beteltéről, és a fizetéssel létrejött szerződés megszűnik. A szerződés ilyen módon történő megszűnésből adódó károkért a CLICKnCRUISE kizárja a felelősségét. A szerződésnek a visszaigazolás és fizetés közti időszakban történő megszűnése esetén a CLICKnCRUISE segít újabb utat találni, vagy kezelési költség felszámolása nélkül visszafizeti a befizetett összeget. Az internetes foglalási lap alkalmas az utazási díj minden elemének tényleges számításba vételére, azonban lehetnek eltérések a foglalási lapon megkapott és a visszaigazoláson szereplő ár/összeg között, mindkét irányban. Amennyiben ez az eset fordul elő mindig a visszaigazoláson szereplő összeg az érvényes.</p>

          <h3>5. Panaszkezelés</h3>
          <p>5.1. Az Utas jogosult az esetlegesen felmerülő problémák (nem teljesülő, vagy módosított utazási feltételek) bekövetkeztekor, azaz bármely a reklamációs panaszigény felmerülése eseténközvetlenül a CLICKnCRUISE segítségét kérni, az elhárítandó akadályok, körülmények tekintetében.</p>
          
          <h3>6. Szerzői, szomszédos és egyéb jogok a jelen Weboldalhoz kapcsolódóan</h3>
          <p>6.1. Az Utas tudomásul veszi, hogy a Weboldalt az utazásközvetítő szerzői joga védi. A Weboldal teljes tartalma az utazásközvetítő, vagy a részére egyes tartalmi elemek használatára jogot adó utazásszervezők, vagy más jogalanyok szellemi tulajdonát képezi.</p>

          <h3>7. Adatvédelmi rendelkezések</h3>
          <p>Az utazásközvetítő adatkezelési tevékenységéről szóló ismertetőt az Utazásközvetítő „Adatvédelmi nyilatkozat” tartalmazza, mely a www.clickncruise.hu/adatvedelmi-nyilatkozat oldalon érhető el. A Weboldal használatával a utas elismeri, hogy az adatvédelmi tájékoztatást megértette, az abban foglaltakat elfogadta. </p>

          <h3>8. Záró rendelkezések</h3>
          <p>8.1. A fogyasztóvédelemről szóló 1997. évi CLV. törvény (a továbbiakban: Fgytv.) rendelkezései szerint az utas (fogyasztó) a lakóhelye szerint illetékes Kereskedelmi és Iparkamara mellett szervezett békéltető testülethez fordulhat, ha az utazásközvetítői szerződéssel kapcsolatos panaszügyének rendezését a CLICKnCRUISE-nál igazolhatóan megkísérelte. Amennyiben a felek közötti vitát peren kívüli úton nem sikerült rendezni, a fogyasztóvédelmi szabályok megsértése esetén a fogyasztók 2017. január 1-től panaszaikkal elsősorban a területileg illetékes járási hivatalokhoz fordulhatnak.</p>
          <p>8.2. A CLICKnCRUISE fenntartja magának a jogot, hogy jelen Általános Szerződési Feltételeket bármikor egyoldalúan, előzetes értesítés nélkül megváltoztassa.</p>
          <p>8.3. Amennyiben jelen Általános Szerződési Feltételek egy vagy több kikötése jogszabályváltozás következtében teljesen vagy részben megvalósíthatatlanná vagy érvénytelenné válik, vagy törvényi rendelkezést sért, ez a jelen Általános Szerződési Feltételek többi részét nem érinti.</p>

          </SectionBox>
        </Box>
      </Stack>
    </Box>
  </>
}
TermsAndConditionsPageHU.displayName = 'TermsAndConditionsPageHU';