import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

import { CruiseDetailsPanel } from '../../components/cruise-details-panel/cruise-details-panel';
import { BookingFormProps } from './BookingFormProps';

import { useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BookingDepartureDateItem } from '../../components/booking-departure-date-item/booking-departure-date-item';
import { CruiseItinerary } from '../../components/cruise-itinerary/cruise-itinerary';
import { CruiseItinerarySchedule } from '../../components/cruise-itinerary/cruise-itinerary-schedule';
import { CruiseDepartureDate } from '../../services/search';

import { useTracking } from '../../hooks/useTracking';
import { ReactComponent as ArrowRightIcon } from './images/arrow-right-icon.svg';
import { useCheckoutApi } from '../../contexts/checkout-api.context';
import { CheckoutStatus } from '../../models/BookingState';

export const BookingDeparturePage: React.FC<BookingFormProps> = (props: BookingFormProps) => {
  // Theme
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  // Translation
  const { t } = useTranslation('common');

  const { trackEvent } = useTracking();
  const { checkoutApi } = useCheckoutApi();

  function isDateInFilterRange(departureDate: CruiseDepartureDate) {
    const f = props.cruiseSearchFilters?.when;
    let result = true;

    if (f) {
      const departureFrom = new Date(departureDate.fromDate);
      departureFrom.setUTCHours(0, 0, 0, 0);

      if (f.from) {
        const filterDate = new Date(f.from);
        filterDate.setUTCHours(0, 0, 0, 0);

        result = result && departureFrom.getTime() >= filterDate.getTime();
      }
      if (f.to) {
        const filterDate = new Date(f.to);
        filterDate.setUTCHours(0, 0, 0, 0);

        result = result && departureFrom.getTime() <= filterDate.getTime();
      }
    }
    return result;
  }

  const shipInfoUrl = props.bookingState.bookingDetails?.shipCode
    ? __CONFIG__.shipInfo[props.bookingState.bookingDetails?.shipCode]
    : null;

  // States
  const [isItineraryDetailsOpen, setItineraryDetailsOpen] = useState(false);
  useEffect(() => {
    setItineraryDetailsOpen(!isLayoutSmall);
  }, [isLayoutSmall]);

  const handleItineraryDetailsToggle = () => {
    trackEvent(isItineraryDetailsOpen ? 'itinerary_details_close' : 'itinerary_details_open');
    setItineraryDetailsOpen(!isItineraryDetailsOpen);
  };

  const { bookingDetails } = props.bookingState,
    departureDatesInRange = props.bookingState.bookingDetails?.departureDates?.filter((d) => isDateInFilterRange(d)),
    remainingDepartureDates = props.bookingState.bookingDetails?.departureDates?.filter((d) => !isDateInFilterRange(d));

  // Methods
  const handleChange = (event: any) => {
    if (bookingDetails) {
      const d = bookingDetails.departureDates?.find((e) => e.id === event.target.dataset.cruiseCode) || null;
      if (d) {
        props.bookingState.departureDate = d;
        props.bookingState.pricePerPerson = d.pricePerPerson;
        props.bookingState.pricePerPersonLocalCcy = d.pricePerPersonLocalCcy;

        checkoutApi.updateBooking(props.bookingState.toUpdateBookingPayload(CheckoutStatus.DATE_SELECTED));
      }
      props.bookingState.completedSteps.push(0);
      props.updateBooking(props.bookingState);

      props.onSubmit();
    }
  };

  // Components
  const Title = () => {
    if (!isLayoutMedium) {
      return (
        <>
          <Typography variant="h2" textAlign="left" margin="24px 0 16px 0">
            {t('booking.departure.sub-title')}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="h3" textAlign="left" margin="24px 0 16px 0">
            {t('booking.departure.sub-title')}
          </Typography>
        </>
      );
    }
  };

  const preventDefault = useCallback((e) => e.preventDefault(), []);

  const ThumbnailImageContainer = () => {
    if (bookingDetails) {
      return (
        <>
          <Box
            className="thumbnail-image-container"
            minWidth="100%"
            marginLeft={isLayoutSmall ? '-20px' : ''}
            width={isLayoutSmall ? '100vw' : ''}
          >
            {bookingDetails.imageUrls && (
              <Carousel
                infiniteLoop
                showIndicators={true}
                showArrows={isLayoutMedium}
                transitionTime={400}
                showThumbs={!isLayoutSmall && bookingDetails.imageUrls.length > 1}
                showStatus={false}
                renderThumbs={(children) => {
                  return children;
                }}
                onSwipeStart={(e) => e.target.addEventListener('touchmove', preventDefault)}
                onSwipeEnd={(e) => e.target.removeEventListener('touchmove', preventDefault)}
              >
                {bookingDetails.imageUrls.map((i, index) => (
                  <div key={index}>
                    <div
                      className="thumbnail-image"
                      key={index}
                      style={{ backgroundImage: `url("${i}&w=960")`, paddingTop: '52%' }}
                    ></div>
                    <img src={`${i}&w=960`} alt="" style={{ display: 'none' }} width={120} />
                  </div>
                ))}
              </Carousel>
            )}
            <Box className="ship" p={1}>
              <img src={bookingDetails.cruiseLineLogoUrl} alt={bookingDetails.cruiseLine} />
              {bookingDetails.shipName}
            </Box>
          </Box>
        </>
      );
    }
    return <></>;
  };

  const ItineraryPanel = () => {
    if (bookingDetails) {
      return (
        <>
          <CruiseItinerary
            portsOfCall={bookingDetails.portsOfCall}
            cruiseLength={bookingDetails.cruiseLength}
            seaDays={bookingDetails.seaDays}
            mode="icon"
            sx={!isLayoutMedium ? { margin: '20px 0' } : {}}
          />
          {bookingDetails.itinerary && bookingDetails.itinerary.length > 0 && (
            <>
              <Link onClick={handleItineraryDetailsToggle}>
                <Grid container columnSpacing={1}>
                  <Grid item style={{ color: '#3E3E3E', fontWeight: 700, fontSize: 14 }}>
                    {t('booking.departure.itinerary-schedule-link')}
                  </Grid>
                  <Grid item>
                    <SvgIcon
                      component={ArrowRightIcon}
                      style={{
                        width: '20px',
                        height: '20px',
                        transform: isItineraryDetailsOpen ? 'rotate(90deg)' : ''
                      }}
                      viewBox="0 0 24 24"
                    />
                  </Grid>
                </Grid>
              </Link>

              <Collapse in={isItineraryDetailsOpen} unmountOnExit timeout={10000}>
                <Stack className="itinerary-schedule-panel" spacing={3}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h3" textAlign="left">
                        {t('booking.departure.itinerary-schedule-title')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <CruiseItinerarySchedule itinerary={bookingDetails.itinerary} />
                </Stack>
              </Collapse>
            </>
          )}
          {!(bookingDetails.itinerary && bookingDetails.itinerary.length > 0) && (
            <CruiseItinerary
              portsOfCall={bookingDetails.portsOfCall}
              cruiseLength={bookingDetails.cruiseLength}
              seaDays={bookingDetails.seaDays}
              mode="inline"
            />
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Box>
        <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={3} alignItems="stretch">
          {bookingDetails && (
            <>
              <Stack spacing={3} width={!isLayoutMedium ? '100%' : '38%'}>
                <ThumbnailImageContainer />
                {shipInfoUrl && (
                  <Link
                    href={shipInfoUrl}
                    target="_blank"
                    onClick={() => trackEvent('ship_info_open')}
                    style={{
                      margin: 0
                    }}
                  >
                    <Grid
                      container
                      style={{
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px',
                        borderTop: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        padding: '6px 0 0 8px',
                        margin: 0
                      }}
                    >
                      <Grid item>
                        <Typography style={{ color: '#3E3E3E', fontSize: 14 }}>
                          {t('booking.departure.ship-info.title')}
                        </Typography>
                      </Grid>
                      <Grid item ml={1}>
                        <SvgIcon
                          component={ArrowRightIcon}
                          style={{
                            width: '20px',
                            height: '20px'
                          }}
                          viewBox="0 0 24 24"
                        />
                      </Grid>
                    </Grid>
                  </Link>
                )}
                {bookingDetails && isLayoutMedium && (
                  <>
                    <ItineraryPanel />
                  </>
                )}
              </Stack>

              <Box width={!isLayoutMedium ? '100%' : '62%'}>
                <Stack spacing={0}>
                  {bookingDetails && (
                    <>
                      <CruiseDetailsPanel
                        data={bookingDetails}
                        guestsNumber={props.bookingState.numberOfGuests.adult}
                        view="detail"
                      />
                      <Alert severity="info" variant="filled">
                        <AlertTitle>
                          <Typography variant="h3" textAlign="left" color="#fff">
                            {t('booking.departure.whats-included-in-price.title')}
                          </Typography>
                        </AlertTitle>
                        <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
                          {t(
                            `booking.departure.whats-included-in-price.description.${props.bookingState.bookingDetails?.cruiseLine}`
                          )}
                        </Typography>
                      </Alert>
                    </>
                  )}

                  {bookingDetails && !isLayoutMedium && (
                    <Stack spacing={2}>
                      <ItineraryPanel />
                    </Stack>
                  )}
                  {departureDatesInRange && departureDatesInRange.length > 0 && <Title />}
                  <Stack spacing={2}>
                    {departureDatesInRange?.map((d, index) => (
                      <div key={index}>
                        <BookingDepartureDateItem
                          bookingState={props.bookingState}
                          data={d}
                          handleChange={handleChange}
                          selected={
                            props.bookingState.departureDate && props.bookingState.departureDate.id === d.id
                              ? true
                              : false
                          }
                        />
                      </div>
                    ))}
                    {remainingDepartureDates && remainingDepartureDates.length > 0 && (
                      <Box>
                        <Typography variant="h3" textAlign="left" margin="48px 0 16px 0">
                          {t('booking.departure.further-offers-title')}
                        </Typography>
                        {remainingDepartureDates?.map((d, index) => (
                          <div key={index}>
                            <BookingDepartureDateItem
                              bookingState={props.bookingState}
                              data={d}
                              handleChange={handleChange}
                              selected={
                                props.bookingState.departureDate && props.bookingState.departureDate.id === d.id
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        ))}
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </>
          )}
          {!bookingDetails && (
            <>
              <Stack spacing={1} width={!isLayoutMedium ? '100%' : '38%'}>
                <Skeleton
                  className="thumbnail-image-container"
                  sx={{
                    borderRadius: '5px',
                    minWidth: '100%',
                    marginLeft: isLayoutSmall ? '-20px' : '',
                    width: isLayoutSmall ? '100vw' : ''
                  }}
                  animation="wave"
                  variant="rectangular"
                  height="230px"
                />
                <Skeleton animation="wave" variant="rectangular" height="60px" sx={{ borderRadius: '5px' }} />
              </Stack>

              <Box width={!isLayoutMedium ? '100%' : '62%'}>
                <Stack spacing={3}>
                  <Skeleton animation="wave" variant="rectangular" height="216px" sx={{ borderRadius: '5px' }} />
                  <Stack>
                    <Skeleton animation="wave" variant="rectangular" height="40px" sx={{ borderRadius: '5px' }} />
                  </Stack>
                  {!isLayoutMedium && (
                    <Skeleton animation="wave" variant="rectangular" height="140px" sx={{ borderRadius: '5px' }} />
                  )}
                  <Skeleton animation="wave" variant="rectangular" height="40px" sx={{ borderRadius: '5px' }} />
                  <Stack spacing={2}>
                    <Stack direction="column" spacing={1}>
                      <Skeleton animation="wave" variant="rectangular" height="140px" sx={{ borderRadius: '5px' }} />
                      <Skeleton animation="wave" variant="rectangular" height="140px" sx={{ borderRadius: '5px' }} />
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};
