import { Box, FormControlLabel, Popover, Radio, Stack, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-medium-image-zoom/dist/styles.css';
import { useTracking } from '../../hooks/useTracking';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { CruiseSearchRequestSortDirectionEnum, CruiseSearchRequestSortEnum } from '../../services/search';
import { ReactComponent as SortIcon } from './sort-icon.svg';
import './sort-panel.scss';

export const SortPanel: React.FC<{
  onSort: (sort: CruiseSearchRequestSortEnum, direction: CruiseSearchRequestSortDirectionEnum) => void;
  searchFilters: CruiseSearchFilters;
}> = (props) => {
  const { trackEvent } = useTracking();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedValue, setSelectedValue] = useState(
    `${props.searchFilters.sort}-${props.searchFilters.sortDirection}`
  );

  // Translation
  const { t } = useTranslation('common');

  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setSelectedValue(value);
    trackEvent('sort', { value });

    const v = value.split('-');
    props.onSort(v[0] as CruiseSearchRequestSortEnum, v[1] as CruiseSearchRequestSortDirectionEnum);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'sort-popup' : undefined;

  return (
    <>
      <Box>
        <Button
          onClick={handleClick}
          sx={{ padding: '10px 0', minWidth: isLayoutMedium ? '42px' : '64px', fontSize: '20px !important' }}
          color="primary"
          variant="contained"
        >
          <SortIcon />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{ className: 'sort-paper', style: { width: isLayoutMedium ? '100%' : 'auto' } }}
        >
          <Stack className="sort-panel" spacing={2}>
            <Typography variant="h2" style={{ fontSize: 18, textAlign: 'left' }}>
              {t('search.sort.title')}
            </Typography>
            <Typography variant="h3" style={{ fontSize: 16 }}>
              {t('search.sort.departure-date.label')}
            </Typography>
            <Box>
              <FormControlLabel
                value="departure_date-asc"
                label={t('search.sort.departure-date.asc-label') + ''}
                control={
                  <Radio
                    aria-labelledby="label-departure_date-asc"
                    checked={selectedValue === 'departure_date-asc'}
                    onChange={handleChange}
                    value="departure_date-asc"
                    name="sort-radio"
                  />
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                value="departure_date-desc"
                label={t('search.sort.departure-date.desc-label') + ''}
                control={
                  <Radio
                    aria-labelledby="label-departure_date-desc"
                    checked={selectedValue === 'departure_date-desc'}
                    onChange={handleChange}
                    value="departure_date-desc"
                    name="sort-radio"
                  />
                }
              />
            </Box>

            <Typography variant="h3" style={{ fontSize: 16 }}>
              {t('search.sort.price.label')}
            </Typography>
            <Box>
              <FormControlLabel
                value="price_per_person-asc"
                label={t('search.sort.price.asc-label') + ''}
                control={
                  <Radio
                    aria-labelledby="label-price_per_person-asc"
                    checked={selectedValue === 'price_per_person-asc'}
                    onChange={handleChange}
                    value="price_per_person-asc"
                    name="sort-radio"
                  />
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                value="price_per_person-desc"
                label={t('search.sort.price.desc-label') + ''}
                control={
                  <Radio
                    aria-labelledby="label-price_per_person-desc"
                    checked={selectedValue === 'price_per_person-desc'}
                    onChange={handleChange}
                    value="price_per_person-desc"
                    name="sort-radio"
                  />
                }
              />
            </Box>
          </Stack>
        </Popover>
      </Box>
    </>
  );
};
