import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorButton } from '../../components/color-button/color-button';
import { CruiseItinerary } from '../../components/cruise-itinerary/cruise-itinerary';
import { CruiseSearchResultListItem } from '../../components/cruise-search-result-list-item/cruise-search-result-list-item';
import bannerImage from '../../components/header/images/main-header-background.webp';
import { Price } from '../../components/price/price';
import { useCheckoutApi } from '../../contexts/checkout-api.context';
import { useTracking } from '../../hooks/useTracking';
import { BookingState } from '../../models/BookingState';

// no live prices and availability
// no blog links
// full price shown first but then per person
// images are not different
// no discount
// no option created, no price guarantee
// no email template
interface Offer {
  message?: string;
  state: BookingState;
}

const Banner = (props: { name: string; message?: string }) => {
  return (
    <Box p={0} style={{ borderRadius: '20px', backgroundImage: `url(${bannerImage})` }}>
      <Typography variant="h1" color="#fff" p={1.5}>
        {props.name}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          textAlign: 'left !important',
          backgroundColor: '#ffffff88',
          backdropFilter: 'blur(4px)',
          borderRadius: '0 0 20px 20px'
        }}
        color="#111"
        p={1.5}
      >
        {props.message || ''}
      </Typography>
    </Box>
  );
};

const OfferDetails = (props: { offer: Offer; onOpen: () => void }) => {
  const { t, i18n } = useTranslation('common');

  if (!props.offer.state.bookingDetails) {
    return <></>;
  }

  return (
    <Box className="cruise-details-panel">
      <Stack className="company-info">
        <Box className="cruise-line-name">
          <Typography variant="h3">
            {new Date(props.offer.state.departureDate!.fromDate).toLocaleDateString(i18n.language, {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}{' '}
            {t(`search.filters.where.options.${props.offer.state.bookingDetails.destinationRegion}`)} (
            <Trans
              t={t}
              i18nKey="labels.cruise-length"
              values={{
                count: props.offer.state.bookingDetails.cruiseLength
              }}
            />
            )
          </Typography>
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Box>
          <CruiseItinerary
            portsOfCall={props.offer.state.bookingDetails.portsOfCall}
            cruiseLength={props.offer.state.bookingDetails.cruiseLength}
            seaDays={props.offer.state.bookingDetails.seaDays}
            mode="long"
          />
        </Box>
        <Box>{props.offer.message}</Box>
      </Stack>

      <Grid container justifyContent="end" alignItems="flex-end" flexWrap="nowrap" mt={1}>
        <Grid item>
          <Stack spacing={1}>
            <Box>
              <Price mainPrice={props.offer.state.getTotalPrice(true)} localPrice={props.offer.state.getTotalPriceLocalCcy(true)}/>
            </Box>
            <Box textAlign="right">
              <ColorButton label={t('search.result-list.cruise.book')} onClick={props.onOpen} />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export const OfferPage: React.FC<{ updateBooking: (b: BookingState) => void }> = (props) => {
  const { offerSlug } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { t } = useTranslation('common');
  const { checkoutApi } = useCheckoutApi();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [offerListName, setOfferListName] = useState<string>('');
  const [offerListMessage, setOfferListMessage] = useState<string | undefined>(undefined);
  const [offerSearch, setOfferSearch] = useState<string | undefined>(undefined);
  const [offersList, setOffersList] = useState<Offer[]>([]);

  const searchSimilar = () => {
    navigate({ pathname: `/search/${offerSearch}` });
  };

  const openBooking = (state: BookingState) => {
    if (!state.bookingDetails) {
      return;
    }

    trackEvent('offer_opened', { offer_slug: offerSlug });
    state.bookingId = '';
    props.updateBooking(state);
    navigate({
      pathname: `/book/${state.bookingDetails.cruiseLine}/${state.bookingDetails.cruiseGroupId}/${state.activeStep.path}`
    });
  };

  useEffect(() => {
    checkoutApi
      .getOffers(offerSlug!)
      .then((response) => {
        const { name, message, search, offers } = response.data;
        setOfferListName(name);
        setOfferListMessage(message);
        setOfferSearch(search || '');
        setOffersList(offers.map((offer) => ({ message: offer.message, state: new BookingState(offer.state) })));
        setIsLoading(false);
        trackEvent('offer_list_opened', { offer_slug: offerSlug });
      })
      .catch((err) => {
        trackEvent('offer_list_open_failed', { offer_slug: offerSlug });
        navigate({ pathname: '/not-found' });
      });
  }, []);

  return (
    <Box pl={2} pr={2} pb={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Stack spacing={2} width={'100%'} maxWidth={'1024px'}>
        {!isLoading && (
          <>
            <Banner name={offerListName} message={offerListMessage} />
            {offersList.map(
              (offer, i) =>
                offer.state.bookingDetails && (
                  <CruiseSearchResultListItem
                    key={i}
                    data={offer.state.bookingDetails}
                    showButton={false}
                    detailPanel={
                      <OfferDetails
                        offer={offer}
                        onOpen={() => {
                          openBooking(offer.state);
                        }}
                      />
                    }
                  />
                )
            )}
            <Box textAlign="center">
              <ColorButton label={t('search.result-list.search-similar')} onClick={searchSimilar} variant="outlined" />
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};
