import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { NotificationsActive } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../components/header/images/main-header-background.webp';
import { useSearchApi } from '../../contexts/search-api.context';
import { useTracking } from '../../hooks/useTracking';
import { ColorButton } from '../color-button/color-button';
import './price-tracker-banner.scss';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';

export const PriceTrackerBanner: React.FC<{ searchFilters: CruiseSearchFilters, cheapestPricePerPerson: number }> = (props) => {
  const { t } = useTranslation('common');
  const { trackEvent } = useTracking();
  const { searchApi } = useSearchApi();
  const isPriceTrackerBannerEnabled = useFeatureIsOn('price-tracker-banner');

  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);
  const [isSnackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [isSaveSuccesful, setSaveSuccesful] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const handleEmailFieldChange = (event: any) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleSubmit = useCallback(() => {
    if (email) {
      trackEvent('price_tracking_enabled', { filters: props.searchFilters.toUrlSearchParams() });
      searchApi
        .createTracker({
          email,
          searchRequest: props.searchFilters.toSearchRequest(),
          threshold: props.cheapestPricePerPerson,
          url: props.searchFilters.toUrlSearchParams()
        })
        .then((response) => {
          setSaveSuccesful(true);
          setSnackbarOpen(true);
          handleDialogClose();
        });
    }
  }, [email, props.cheapestPricePerPerson, props.searchFilters, searchApi, trackEvent]);

  if (
    !isPriceTrackerBannerEnabled ||
    props.searchFilters.sort !== 'price_per_person' ||
    props.searchFilters.sortDirection !== 'asc'
  ) {
    return <></>;
  }

  if (isSaveSuccesful) {
    return (
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: isLayoutSmall ? 'bottom' : 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" variant="filled">
          <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
            {t('search.price-tracker.success-message')}
          </Typography>
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Box
      p={2}
      sx={{ borderRadius: '20px', backgroundImage: `url(${bannerImage})`, marginTop: { xs: '10px', md: '25px' } }}
    >
      <Stack direction={isLayoutSmall ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
        <Stack direction="column">
          <Typography
            variant="h1"
            sx={{ textAlign: isLayoutSmall ? 'center !important' : 'left !important' }}
            color="#fff"
          >
            {t('search.banner.priceTracker.title')}
          </Typography>
          <Typography
            variant="h4"
            sx={{ textAlign: isLayoutSmall ? 'center !important' : 'left !important' }}
            color="#fff"
          >
            {t('search.banner.priceTracker.description')}
          </Typography>
        </Stack>
        <ColorButton
          label={t('search.banner.priceTracker.button')}
          onClick={(e) => {
            trackEvent('price_tracker_dialog_opened');
            handleDialogOpen();
          }}
          startIcon={<NotificationsActive />}
          color="info"
          sx={{ maxHeight: '60px', minWidth: '250px' }}
        />
      </Stack>

      <Dialog
        className="price-tracker-dialog"
        open={isDialogOpened}
        onClose={handleDialogClose}
        fullScreen={isLayoutMedium}
      >
        <DialogTitle>
          {t('search.price-tracker.dialog.title')}
          <IconButton
            aria-label="Bezar"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ minWidth: isLayoutMedium ? '' : '480px' }}>
          <Stack gap={4}>
            <Typography variant="body1" textAlign="left" marginTop="8px">
              {t('search.price-tracker.dialog.content')}
            </Typography>

            <Stack gap={2} alignItems={'center'}>
              <TextField
                id="field-email"
                label={null}
                type="email"
                variant="outlined"
                placeholder={t('search.price-tracker.dialog.email-placeholder')}
                onChange={handleEmailFieldChange}
                style={{ width: '100%', minWidth: isLayoutMedium ? '' : '250px' }}
                required={true}
                inputProps={{ autoComplete: 'email' }}
              />
              <ColorButton label={t('search.price-tracker.dialog.submit-button-label')} onClick={handleSubmit} />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
