// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/main-header-background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header{background:#F2F2F2;z-index:999;border-radius:0px 0px 35px 35px;top:0}.header-container{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:top;background-size:cover}.header-container h1{text-align:center;font-weight:700}.header-container.rounded{box-shadow:0px 0px 8px #AEAEAE;border-radius:0px 0px 35px 35px;height:50px}.header-container.rounded .MuiToolbar-root{min-height:unset}.header-container .header-button{font-size:14px;padding-right:8px;padding-left:8px;height:30px;border-radius:5px}\n", "",{"version":3,"sources":["webpack://./src/components/header/header.scss"],"names":[],"mappings":"AAAA,QACE,kBAAmB,CACnB,WAAY,CACZ,+BAAgC,CAChC,KAAM,CACN,kBACE,wDAA2D,CAC3D,2BAA4B,CAC5B,uBAAwB,CACxB,qBAAsB,CAJvB,qBAOG,iBAAkB,CAClB,eAAgB,CARnB,0BAYG,8BAA+B,CAC/B,+BAAgC,CAChC,WAAY,CAdf,2CAgBK,gBAAiB,CAhBtB,iCAqBG,cAAe,CACf,iBAAkB,CAClB,gBAAiB,CACjB,WAAY,CACZ,iBAAkB","sourcesContent":[".header {\n  background: #F2F2F2;\n  z-index: 999;\n  border-radius: 0px 0px 35px 35px;\n  top: 0;\n  &-container {\n    background-image: url(./images/main-header-background.webp);\n    background-repeat: no-repeat;\n    background-position: top;\n    background-size: cover;\n\n    h1 {\n      text-align: center;\n      font-weight: 700;\n    }\n    \n    &.rounded {\n      box-shadow: 0px 0px 8px #AEAEAE;\n      border-radius: 0px 0px 35px 35px;\n      height: 50px;\n      .MuiToolbar-root {\n        min-height: unset;\n      }\n    }\n\n    .header-button {\n      font-size: 14px;\n      padding-right: 8px;\n      padding-left: 8px;\n      height: 30px;\n      border-radius: 5px;\n    }\n  }\n\n  \n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
