import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../hooks/useTracking';
import { CruiseDepartureDate } from '../../services/search';
import { ColorButton } from '../color-button/color-button';
import { Price, PriceType } from '../price/price';
import './booking-departure-date-item.scss';
import { FavoriteMailDialog } from '../favorite-mail-dialog/favorite-mail-dialog';
import { BookingState } from '../../models/BookingState';

export const BookingDepartureDateItem: React.FC<{
  data: CruiseDepartureDate;
  handleChange: (e: any) => void;
  selected?: boolean;
  bookingState: BookingState
}> = (props) => {
  const { trackEvent } = useTracking();

  // Translation
  const { t, i18n } = useTranslation('common');

  // Theme
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  // Components
  const DateBox = (args: { date: Date }) => {
    return (
      <>
        <Stack className="date-box">
          <strong>
            {args.date.toLocaleDateString(i18n.language, { year: 'numeric', month: 'short', day: 'numeric' })}
          </strong>
          <Box>{args.date.toLocaleDateString(i18n.language, { weekday: 'long' })}</Box>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Grid container className={`booking-departure-date-item ${props.selected ? 'selected' : ''}`}>
        <Grid item xs={12} sm={6} order={{ xs: 3, sm: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" marginTop={{ xs: '15px', sm: '0' }}>
            <DateBox date={props.data.fromDate} />
            <Box className="date-fwd-icon">
              <ArrowForwardIcon />
            </Box>
            <DateBox date={props.data.toDate} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }} style={{ textAlign: 'right' }}>
          <Price
            mainPrice={new PriceType(props.data.pricePerPerson)}
            localPrice={
              new PriceType(props.data.pricePerPersonLocalCcy, __CONFIG__.currency.localCurrency, null, 'estimate')
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 3, sm: 3 }} marginTop={{ xs: '15px', sm: '20px' }} alignItems={'flex-end'} display={'flex'}>
          {!isLayoutSmall && (<FavoriteMailDialog data={props.bookingState} date={props.data} />)}
        </Grid>
        <Grid item xs={12} sm={6} order={4} marginTop={{ xs: '15px', sm: '20px' }} style={{ textAlign: 'right' }}>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
          <ColorButton
            label={t('booking.departure.choose-button-label')}
            data-cruise-code={props.data.id}
            onClick={(e) => {
              trackEvent('departure_select', { departure_date: moment(props.data.fromDate).format('YYYY-MM-DD') });
              props.handleChange(e);
            }}
            style={{ width: isLayoutSmall ? '100%' : '' }}
          />
          {isLayoutSmall && (<FavoriteMailDialog data={props.bookingState} date={props.data} iconOnly={true} />)}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

BookingDepartureDateItem.defaultProps = {
  selected: false
};
