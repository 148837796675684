import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: '#05C1B3',
      contrastText: '#fff'
    },
    secondary: {
      main: 'rgba(2, 12, 37, 0.946187)',
      contrastText: '#fff'
    },
    error: {
      main: '#ED2E7E'
    },
    success: {
      main: '#00BA88'
    },
    info: {
      main: '#020C25',
      light: '#020C25',
      dark: '#020C25',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          paddingLeft: '40px',
          paddingRight: '40px',
          margin: '2px'
        },
        outlined: {
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        },
        outlinedSecondary: {
          borderColor: '#c9c9c9'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: '48px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#05C1B325'
        },
        outlined: {
          backgroundColor: '#FFFFFF',
          borderColor: '#05C1B350'
        },
        clickable: {
          '&:hover, &:focus, &:active': {
            backgroundColor: '#05C1B325 !important'
          }
        }
      }
    }
  }
});

theme.typography.h1 = {
  fontSize: '36px',
  fontWeight: 600,
  [theme.breakpoints.down('xs')]: {
    fontSize: '18px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px'
  }
};

theme.typography.h2 = {
  color: '#333',
  fontSize: '1.1rem',
  textAlign: 'center',
  fontWeight: 600,
  '@media (min-width:600px)': {
    fontSize: '1.5rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem'
  }
};

theme.typography.h3 = {
  color: '#333',
  fontSize: '1rem',
  fontWeight: 600,
  '@media (min-width:600px)': {
    fontSize: '1.2rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem'
  }
};

theme.typography.h4 = {
  color: '#333',
  fontSize: '0.9rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '1rem',
    textAlign: 'center'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem'
  }
};

theme.typography.h6 = {
  fontSize: '14px',
  fontWeight: 600
};

theme.typography.body1 = {
  color: '#3E3E3E'
};

theme.typography.body2 = {
  fontSize: '10px',
  fontWeight: 400,
  color: '#757575'
};

theme.typography.button = {
  fontWeight: 600,
  lineHeight: 2,
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '18px'
  }
};

export default theme;
