import { Box, Grid, Link, Stack } from '@mui/material';
import { SectionTitle } from '../components/section-title/section-title';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const PrivacyPolicyPageUK: React.FC<{}> = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={'Privacy Policy'} />
            <Box marginTop={3}>
              <b>CLICKnCRUISE Ltd.</b>
              <br />
              <Link
                href="https://drive.google.com/file/d/10dhAaxZlKZlCU5z9A9cjTQEhfWRDHI5v/view?usp=share_link"
                target="_blank"
              >
                Open PDF
              </Link>
            </Box>
            <Box marginTop={3}>
              <b>Barrhead Travel</b>
              <br />
              <Link
                href="https://drive.google.com/file/d/1KKb1IOW1lAaq_si96k4u0u8MAETuocuW/view?usp=sharing"
                target="_blank"
              >
                Open PDF
              </Link>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
PrivacyPolicyPageUK.displayName = 'PrivacyPolicyPageUK';