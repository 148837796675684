import './input-text.scss';
import FormControl from '@mui/material/FormControl';
import { Box, FormHelperText, MenuItem, Select, TextField, TextFieldProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, RefCallBack, useFormContext } from 'react-hook-form';

export type ValidationRulesType = {
  required?: string,
  pattern?: {
    value: RegExp,
    message: string
  }
}

export type InputTextProps = TextFieldProps & {
  errors?: FieldErrors;
  inputRef?: RefCallBack;
  validationRules?: ValidationRulesType;
  onInputBlur?: (e: any) => void;
}

export const InputText: React.FC<InputTextProps> = (props) => {

  const {t} = useTranslation('common');
  const isRequired = props.required || (props.validationRules && props.validationRules.required);
  const {defaultValue, validationRules, placeholder, onInputBlur, ...inputProps} = props;

  // Form
  const { control, formState: { errors } } = useFormContext();

  const getErrors = () => {
    let e = '';
    if (errors && props.id && errors[props.id]) {
      e = errors[props.id].message;
    }
    return e;
  }

  return <>
    <FormControl className='input-text' sx={{ m: 1}}>
      {props.label &&
        <Typography variant="h6" style={{marginBottom: '4px'}}>{t(props.label+'') + (isRequired ? '*' : '') }</Typography>
      }
      <Controller
        name={props.id||''}
        rules={validationRules}
        control={control}
        defaultValue={defaultValue}
        render={({ field: {ref, onBlur, ...rest} }) => {
          const onTextInputBlur = (event: any) => {
            onBlur();
            if (onInputBlur) {
              onInputBlur(event);
            }
          };

          return (
            <TextField
              {...inputProps}  
              {...rest}
              label={null}
              variant="outlined"
              placeholder={t(placeholder+'')}
              error={Boolean(getErrors())}
              helperText={getErrors()}
              inputProps={{ref: ref}}
              onBlur={onTextInputBlur}
            />
          );
        }}
      />
    </FormControl>
  </>
}


export type InputSelectProps = InputTextProps & {
  options: Array<{label: string, value: string}>;
}


export const InputSelect: React.FC<InputSelectProps> = (props) => {

  const {t} = useTranslation('common');
  const isRequired = props.required || (props.validationRules && props.validationRules.required);
  const {defaultValue, validationRules, placeholder, onInputBlur, options} = props;

  // Form
  const { control, formState: { errors } } = useFormContext();

  const getErrors = () => {
    let e = '';
    if (errors && props.id && errors[props.id]) {
      e = errors[props.id].message;
    }
    return e;
  }

  return <>
    <FormControl className='input-text' sx={{ m: 1}}>
      <Typography variant="h6" style={{marginBottom: '4px'}}>{t(props.label+'') + (isRequired ? '*' : '') }</Typography>
      <Controller
        name={props.id||''}
        rules={validationRules}
        control={control}
        defaultValue={defaultValue||''}
        render={({ field: {ref, onBlur, ...rest} }) => {
          const onTextInputBlur = (event: any) => {
            onBlur();
            if (onInputBlur) {
              onInputBlur(event);
            }
          };

          return (
            <Box>
              <Select
                {...rest}
                id={props.id}
                label={null}
                displayEmpty
                variant="outlined"
                error={Boolean(getErrors())}
                inputProps={{ref: ref}}
                onBlur={onTextInputBlur}
                style={{width: '100%'}}
              >
                {placeholder &&
                  <MenuItem disabled value="">
                    {t(placeholder+'')}
                  </MenuItem>
                }
                {options.map((o, i) => 
                  <MenuItem key={i} value={o.value}>{t(o.label)}</MenuItem>
                )}
              </Select>
              <FormHelperText>{getErrors()}</FormHelperText>
            </Box>
          );
        }}
      />
    </FormControl>
  </>
}

InputText.defaultProps = {
  helperText: 'form.required-helper-text'
}

