import { Button, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';

interface PredefinedSearchButtonProps {
  searchFilters: CruiseSearchFilters;
  updateSearchFilters: (f: CruiseSearchFilters) => void;
  label: string;
  image: string;
  onButtonClick?: () => void;
  name: string;
  showLabel?: boolean;
  aspectRatio?: string;
  hoverAnimationEnabled?: boolean;
}

const ImageButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15
    },
    '& .MuiImageMarked-root': {
      opacity: 0
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor'
    }
  }
}));

ImageButton.defaultProps = {
  variant: 'contained'
};

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
  borderRadius: '10px'
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
  borderRadius: '10px'
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity')
}));

export const PredefinedSearchButton: React.FC<PredefinedSearchButtonProps> = (props) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  const onButtonClick = () => {
    trackEvent('predefined_search', { label: props.name });

    if (props.onButtonClick) {
      return props.onButtonClick();
    }

    props.updateSearchFilters(props.searchFilters);
    navigate({
      pathname: `/search/${props.name}`
    });
  };

  return (
    <>
      <Box className="predefined-search-button">
        <ImageButton
          aria-label={props.label}
          focusRipple
          key={props.label}
          style={{
            width: '100%',
            height: props.aspectRatio === 'auto' ? '200px' : 'auto',
            aspectRatio: props.aspectRatio
          }}
          onClick={onButtonClick}
        >
          <ImageSrc style={{ backgroundImage: `url(${props.image})` }}/>
          {props.hoverAnimationEnabled && <ImageBackdrop className="MuiImageBackdrop-root"/>}
          {props.showLabel === true && 
            <Image>
              <Typography
                component="span"
                variant="h2"
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`
                }}
              >
                {props.label}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          }
        </ImageButton>
      </Box>
    </>
  );
};

PredefinedSearchButton.defaultProps = {
  aspectRatio: 'auto',
  hoverAnimationEnabled: true
};
