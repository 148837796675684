import { Stack, Typography } from '@mui/material';
import './section-title.scss';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const SectionTitle: React.FC<{ title: string, subTitle?: string, textAlign?: 'left'|'center', maxWidth?: number }> = (props) => {
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const getSubTitle = () => {
    if (props.subTitle) {
      return (
        <Typography variant="h4"
          className="section-title-subtitle"
          sx={{ marginTop: isLayoutSmall ? '5px !important' : '14px !important'}}
          style={{maxWidth: props.maxWidth ? `${props.maxWidth}px` : '', textAlign: props.textAlign ? props.textAlign : (isLayoutSmall ? 'left' : 'center')}}
        >
            {props.subTitle}
        </Typography>
      );
    }
  }

  return <>
    <div className='section-title'>
      <Stack spacing={5} alignItems={props.textAlign ? (props.textAlign === 'left' ? 'flex-start' : 'center') : {xs: 'flex-start', sm: 'center'}}>
          <Typography variant="h2" textAlign={props.textAlign ? props.textAlign : {xs: 'left', sm: 'center'}} style={{maxWidth: props.maxWidth ? `${props.maxWidth}px` : ''}}>{props.title}</Typography>
          {getSubTitle()}
      </Stack>
    </div>
  </>
}
