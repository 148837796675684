import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckoutApi } from '../../contexts/checkout-api.context';
import { useTracking } from '../../hooks/useTracking';

/**
 * Handles opening favorite links sent out in emails
 * Redirects to the booking page with the correct cruise line and cruise group id
 *
 * @param props
 * @returns
 */
export const FavoritePage: React.FC<{}> = (props) => {
  let { favoriteId } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { checkoutApi } = useCheckoutApi();

  const navigateToNotFound = () => {
    trackEvent('favorite_access_failed', { favorite_id: favoriteId });
    navigate({ pathname: '/not-found' });
  };

  useEffect(() => {
    if (!favoriteId) {
      navigateToNotFound();
      return;
    }

    checkoutApi
      .getFavorite(favoriteId)
      .then((response) => {
        const { cruiseGroupId, cruiseId } = response.data;
        if (cruiseGroupId) {
          const cruiseLine = cruiseGroupId.split('_')[0].toUpperCase();
          let bookingPagePath = `/book/${cruiseLine}/${cruiseGroupId}/departure/`;
          if (cruiseId) {
            bookingPagePath = `/book/${cruiseLine}/${cruiseGroupId}/departure/${cruiseId}`;
          }
          trackEvent('favorite_access_ok', { favorite_id: favoriteId });
          navigate({ pathname: bookingPagePath });
        } else {
          navigateToNotFound();
        }
      })
      .catch((err) => {
        navigateToNotFound();
      });
  });

  return <></>;
};
