import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertTitle,
  Box,
  Grid,
  Link,
  Skeleton,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import { BookingCabinTypeItem } from '../../components/booking-cabin-type-item/booking-cabin-type-item';
import { BookingOfferSummary } from '../../components/booking-offer-summary/booking-offer-summary';
import { useTracking } from '../../hooks/useTracking';
import { BookingCruiseCabin, BookingGuestState, CheckoutStatus } from '../../models/BookingState';
import { Codes } from '../../models/Codes';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';

import { BookingFormProps } from './BookingFormProps';
import { ReactComponent as ArrowRightIcon } from './images/arrow-right-icon.svg';
import { useSearchApi } from '../../contexts/search-api.context';
import { useCheckoutApi } from '../../contexts/checkout-api.context';

type CabinStepProps = BookingFormProps & {
  cruiseSearchFilters: CruiseSearchFilters;
  scrollToTop: () => void;
};

export const BookingCabinTypePage: React.FC<CabinStepProps> = (props: CabinStepProps) => {
  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  // Translation
  const { t } = useTranslation('common');

  // Api Context
  const { searchApi } = useSearchApi();
  const { checkoutApi } = useCheckoutApi();

  const { trackEvent } = useTracking();

  const isMSC = props.bookingState.bookingDetails?.cruiseLine === 'MSC';
  const showAllCabins = isMSC || props.bookingState.bookingDetails?.cruiseLine === 'CELESTYAL';

  // States
  const [cabinsGrouped, setCabinsGrouped] = useState<{ [name: string]: BookingCruiseCabin[] }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [callbackSuccessful, setCallbackSuccessful] = useState(false);

  // Methods
  const createCabinGroups = () => {
    if (props.bookingState.bookingDetails) {
      if (props.bookingState.bookingDetails.cabins) {
        const c = props.bookingState.bookingDetails.cabins;

        c.sort((a, b) => {
          const orderA = Codes.cabinCategoryOrder.get(a.categoryGroup)?.toString() || '';
          const orderB = Codes.cabinCategoryOrder.get(b.categoryGroup)?.toString() || '';
          return orderA.localeCompare(orderB);
        });

        let cg = c.reduce((groups: { [name: string]: BookingCruiseCabin[] }, item: BookingCruiseCabin) => {
          const group = groups[item.categoryGroup] || [];

          const isSuite = item.categoryGroup === 'suite';
          const doNotMergeCabin = showAllCabins || isSuite;
          const cheapestPriceForCategoryGroup = Math.min(
            ...group.filter((c) => c.categoryGroup === item.categoryGroup).map((c) => c.pricePerPerson)
          );

          if (group.length === 0 || doNotMergeCabin) {
            groups[item.categoryGroup] = [...group, item];
          } else if (item.pricePerPerson < cheapestPriceForCategoryGroup) {
            groups[item.categoryGroup] = [item];
          }

          return groups;
        }, {});
        if (cg) {
          setCabinsGrouped(cg);
        }
      }
    }
  };

  const loadCabinsDetails = () => {
    if (props.bookingState.departureDate && props.bookingState.bookingDetails?.shipCode) {
      setIsLoading(true);

      const f = props.cruiseSearchFilters;
      props.bookingState.numberOfGuests.adult = f.guests.adult;
      props.bookingState.numberOfGuests.child = f.guests.child;

      const p = searchApi.getCruiseCabins(
        props.bookingState.departureDate.id,
        props.bookingState.bookingDetails.shipCode,
        f.toSearchRequest()
      );

      p.then((response) => {
        if (props.bookingState.bookingDetails) {
          response.data.sort((a, b) => (a.pricePerPerson <= b.pricePerPerson ? -1 : 1));

          const destinationRegion = props.bookingState.bookingDetails?.destinationRegion;
          const firstPortOfCall = props.bookingState.bookingDetails.portsOfCall[0];

          props.bookingState.bookingDetails.cabins = response.data.map(
            (e) => new BookingCruiseCabin(e, destinationRegion, firstPortOfCall)
          );

          const alreadySelectedCabin = response.data.filter((c) => c.id === props.bookingState.cabinType?.id);

          if (alreadySelectedCabin.length > 0) {
            props.bookingState.pricePerPerson = alreadySelectedCabin[0].pricePerPerson;
            props.bookingState.pricePerPersonLocalCcy = alreadySelectedCabin[0].pricePerPersonLocalCcy;
          } else {
            props.bookingState.pricePerPerson = response.data[0].pricePerPerson;
            props.bookingState.pricePerPersonLocalCcy = response.data[0].pricePerPersonLocalCcy;
          }

          props.updateBooking(props.bookingState);

          createCabinGroups();
        }
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    }
  };

  const handleChange = (cabinId: string) => {
    if (props.bookingState.bookingDetails) {
      const c = props.bookingState.bookingDetails.cabins?.find((e) => e.id === cabinId) || null;
      if (c) {
        const destinationRegion = props.bookingState.bookingDetails?.destinationRegion;
        const firstPortOfCall = props.bookingState.bookingDetails.portsOfCall[0];

        props.bookingState.cabinType = new BookingCruiseCabin(c, destinationRegion, firstPortOfCall);
        props.bookingState.pricePerPerson = c.pricePerPerson;
        props.bookingState.pricePerPersonLocalCcy = c.pricePerPersonLocalCcy;
      }

      props.bookingState.guestsState.guestList = [];
      const totalGuestCount = props.bookingState.numberOfGuests.adult + props.bookingState.numberOfGuests.child;
      if (props.bookingState.guestsState.guestList.length < totalGuestCount) {
        for (let i = 0; i < props.bookingState.numberOfGuests.adult; i++) {
          props.bookingState.guestsState.guestList.push(new BookingGuestState({ type: 'adult' }));
        }
        for (let i = 0; i < props.bookingState.numberOfGuests.child; i++) {
          props.bookingState.guestsState.guestList.push(new BookingGuestState({ type: 'child' }));
        }
      }

      checkoutApi.updateBooking(props.bookingState.toUpdateBookingPayload(CheckoutStatus.CABIN_SELECTED));

      props.bookingState.completedSteps.push(1);
      props.updateBooking(props.bookingState);
      props.onSubmit();
    }
  };

  const handleAfterCallbackSubmit = () => {
    setCallbackSuccessful(true);
    props.scrollToTop();
  };

  const guestCountChanged = () => {
    loadCabinsDetails();
  };

  useEffect(() => {
    loadCabinsDetails();
  }, [props.bookingState.departureDate]);

  // Components
  const Title = () => {
    if (!isLayoutMedium) {
      return (
        <>
          <Typography variant="h2" textAlign="center" marginBottom="8px">
            {t('booking.cabintype.sub-title')}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="h3" textAlign="left" marginBottom="8px">
            {t('booking.cabintype.sub-title')}
          </Typography>
        </>
      );
    }
  };

  const MSCBlogPost = () => {
    return (
      <Box mb={2}>
        <Alert severity="info" variant="outlined">
          <AlertTitle>
            <Typography variant="h3" textAlign="left">
              {t('booking.cabintype.msc-cabin-type.title')}
            </Typography>
          </AlertTitle>
          <Link
            href={t('booking.cabintype.msc-cabin-type.url')}
            target="_blank"
            onClick={() => trackEvent('msc_cabin_info_open')}
            fontSize={14}
          >
            <Grid container columnSpacing={1}>
              <Grid item>{t('booking.cabintype.msc-cabin-type.description')}</Grid>
              <Grid item>
                <SvgIcon
                  component={ArrowRightIcon}
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                  viewBox="0 0 24 24"
                />
              </Grid>
            </Grid>
          </Link>
        </Alert>
      </Box>
    );
  };

  return (
    <>
      <Stack direction={!isLayoutMedium ? 'column' : 'row'} spacing={3}>
        {isLayoutMedium && (
          <Box width="30%">
            <BookingOfferSummary
              bookingState={props.bookingState}
              updateBooking={props.updateBooking}
              onGuestCountChanged={guestCountChanged}
              readonly={true}
            />
          </Box>
        )}
        <Box width={!isLayoutMedium ? '100%' : '70%'}>
          <Stack>
            <Title />
            {isMSC && __CONFIG__.pages.blog.blogEnabled && <MSCBlogPost />}
            {!isLoading && (
              <Box className="accordion-container">
                {callbackSuccessful && (
                  <Alert severity="success" variant="filled" style={{ marginBottom: 15 }}>
                    <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
                      {t('booking.departure.callback-dialog.success-text')}
                    </Typography>
                  </Alert>
                )}
                {props.bookingState.bookingDetails &&
                  cabinsGrouped &&
                  Object.keys(cabinsGrouped).map((cabinCategory: any, i: number) => (
                    <Accordion key={i} defaultExpanded={true}>
                      <AccordionSummary
                        color="secondary"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${cabinCategory}-content`}
                        id={`panel-${cabinCategory}-header`}
                      >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          {t(`search.filters.cabinCategory.options.${cabinCategory}`)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {(cabinsGrouped[cabinCategory] as Array<BookingCruiseCabin>).map((c, i) => (
                          <BookingCabinTypeItem
                            key={i}
                            data={c}
                            bookingState={props.bookingState}
                            handleChange={handleChange}
                            afterCallbackSubmit={handleAfterCallbackSubmit}
                            selected={
                              props.bookingState.cabinType && props.bookingState.cabinType.id === c.id ? true : false
                            }
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Box>
            )}
            {isLoading && (
              <Stack direction="column" spacing={1}>
                <Skeleton animation="wave" variant="rectangular" height="140px" sx={{ borderRadius: '5px' }} />
                <Skeleton animation="wave" variant="rectangular" height="140px" sx={{ borderRadius: '5px' }} />
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
