import './input-guests-select-item.scss';
import Box from '@mui/material/Box';
import { Button, Grid, Stack, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GuestFilterOption } from '../../models/FilterOptions';

type GuestsSelectItemProps = {
  option: GuestFilterOption,
  minusClick: (e: any) => void,
  plusClick: (e: any) => void,
  readonly?: boolean
}

export const GuestsSelectItem: React.FC<GuestsSelectItemProps> = (props) => {
  const {t} = useTranslation('common');

  const minusButtonClick = (event: any) => {
    props.minusClick(event);
  }

  const plusButtonClick = (event: any) => {
    props.plusClick(event);
  }

  return <>
      <Grid container justifyContent="flex-start" alignItems="center" className='input-guests-select-item' width="100%">
        <Grid item xs={7.5}>
          <Stack>
            <Box fontWeight="700">
              {t(props.option.label)}
            </Box>
            <Box className='input-guests-hint'>
              {t(props.option.hint)}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={4.5} textAlign="right">
          <Stack direction="row" alignItems="center" justifyContent={props.readonly ? "flex-end" : "space-between"}>
          {(!props.readonly &&
            <Button className='input-guests-count-button' variant="outlined" disabled={props.option.count < 1} data-option-value={props.option.value} onClick={minusButtonClick}>-</Button>
          )}
          <span className='input-guest-count' style={{fontSize: props.readonly ? '20px' : ''}}>
            {(props.readonly ? t('offer-summary.number-of-guests', {count: props.option.count}) : props.option.count)}
          </span>
          {(!props.readonly &&
            <Button className='input-guests-count-button' variant="outlined" disabled={props.option.count >= props.option.maxCount} data-option-value={props.option.value} onClick={plusButtonClick}>+</Button>
          )}
          </Stack>
        </Grid>
      </Grid>

  </>
}

GuestsSelectItem.defaultProps = {
  readonly: false
}