import './quick-search-box.scss';
import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColorButton } from '../color-button/color-button';
import { CruiseSearchFilters, GuestsFilter } from '../../models/CruiseSearchFilters';
import { MultiSelect, SelectOption } from '../input-multiselect/input-multiselect';
import { FilterOptions, GuestFilterOptions } from '../../models/FilterOptions';
import { DatePickerInputRange } from '../input-datepicker/input-datepicker';
import { GuestsSelect } from '../input-guests-select/input-guests-select';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';
import { SectionTitle } from '../section-title/section-title';

export const QuickSearchBox: React.FC<{
  searchFilters: CruiseSearchFilters;
  updateSearchFilters: (f: CruiseSearchFilters) => void;
}> = (props) => {
  // Theme
  const theme = useTheme();
  const isLayoutLarge = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const { trackEvent } = useTracking();
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  FilterOptions.guests.adult.count = props.searchFilters.guests.adult;
  FilterOptions.guests.child.count = props.searchFilters.guests.child;

  const searchButtonClick = () => {
    trackEvent('search', { filters: props.searchFilters.toUrlSearchParams() });
    props.updateSearchFilters(props.searchFilters);
    navigate({
      pathname: `/search/${props.searchFilters.toUrlSearchParams()}`
    });
  };

  const multiSelectChanged = (name: string, values: SelectOption[]) => {
    const optionName = name.replace('filter-', '');
    (props.searchFilters as any)[optionName] = values.map((e) => e.value);
  };

  const guestFilterChanged = (g: GuestFilterOptions) => {
    let gn: GuestsFilter = props.searchFilters.guests;
    gn.adult = g.adult.count || 0;
    gn.child = g.child.count || 0;

    props.updateSearchFilters(props.searchFilters);
  };

  const dateFilterChanged = (from: Date | null, to: Date | null) => {
    props.searchFilters.when.from = from;
    props.searchFilters.when.to = to;
  };

  return (
    <>
      <Stack className="quick-search-box" spacing={2} padding={isLayoutLarge ? 2 : 0}>
        {isLayoutLarge && <Typography variant="h1">{t('home.header-title')}</Typography>}
        {!isLayoutLarge && <SectionTitle title={t('home.header-title')} />}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="space-between"
          spacing={isLayoutLarge ? 0 : 2}
          mt={isLayoutLarge ? undefined : '0 !important'}
          ml={isLayoutLarge ? undefined : '-16px !important'}
        >
          <Grid item xs={12} md={3}>
            <MultiSelect
              id="where"
              label={t('home.quick-search.filters.where.label')}
              placeHolder={t('home.quick-search.filters.where.placeholder')}
              options={FilterOptions.where}
              onChanged={multiSelectChanged}
              values={props.searchFilters.where}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePickerInputRange
              id="when"
              label={t('home.quick-search.filters.when.label')}
              onChanged={dateFilterChanged}
              values={props.searchFilters.when}
              showClearDates={false}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <GuestsSelect
              id="guests"
              label={t('home.quick-search.filters.guest.label')}
              options={FilterOptions.guests}
              onChanged={guestFilterChanged}
              values={props.searchFilters.guests.getAllAsLabels(t)}
            />
          </Grid>
          <Grid item xs={12} md={2} textAlign="center">
            <ColorButton
              label={t('search.search-button')}
              onClick={searchButtonClick}
              sx={{ width: isLayoutLarge ? '100%' : '70%', paddingLeft: '0', paddingRight: '0' }}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
