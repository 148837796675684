import { Alert, LinearProgress, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ColorButton } from '../../components/color-button/color-button';
import { useCheckoutApi } from '../../contexts/checkout-api.context';

export const CheckoutPage: React.FC<{}> = (props) => {
  // Translation
  const { t } = useTranslation('common');

  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  // Navigation
  const [searchParams] = useSearchParams();
  let { transactionId } = useParams();

  // States
  const [paymentResult, setPaymentResult] = useState<string | null>(searchParams.get('payment-result'));
  const [bookingNumber, setBookingNumber] = useState<string | null>(searchParams.get('booking-number'));
  const [isLoading, setIsLoading] = useState<boolean>();

  // Api context
  const { checkoutApi } = useCheckoutApi();

  // Methods
  const createCheckout = () => {
    setIsLoading(true);
    setPaymentResult(null);
    setBookingNumber(null);

    if (transactionId) {
      checkoutApi.createCheckout(transactionId, {})
        .then((response) => {
          const redirectUrl = response.data.redirectUrl;

          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    }
  };

  useEffect(() => {
    // Redirect to stripe checkout page
    if (!paymentResult) {
      createCheckout();
    } else {
      switch (paymentResult) {
        case 'success':
          break;
        case 'failed':
          break;
      }
    }
  }, []);

  return (
    <>
      <Box>
        {/* Waiting for redirect */}
        {!paymentResult && isLoading && (
          <Stack alignItems="center" justifyContent="center" height="20vh">
            <Typography variant="h2" marginBottom="15px">
              {t('booking.payment.redirecting')}
            </Typography>
            <Box style={{ maxWidth: '600px', width: '100%' }}>
              <LinearProgress />
            </Box>
          </Stack>
        )}

        {/* Payment successful */}
        {paymentResult === 'success' && (
          <Stack alignItems="center" justifyContent="center" textAlign="center" spacing={5} marginBottom={5}>
            <Box>
              <Alert severity="success" variant="filled">
                <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
                  {t('booking.payment.successful.message')}
                </Typography>
              </Alert>
            </Box>
            <Box>
              <Typography variant="h1">{t('booking.payment.successful.title')}</Typography>
              <Typography variant="body1">
                {t('booking.payment.successful.content')}
                {bookingNumber && (
                  <Trans t={t} i18nKey={'booking.payment.successful.booking-number'} values={{ bookingNumber }}></Trans>
                )}
              </Typography>
            </Box>
          </Stack>
        )}

        {/* Payment failed */}
        {paymentResult === 'failed' && (
          <Stack alignItems="center" justifyContent="center" textAlign="center" spacing={5} marginBottom={5}>
            <Box>
              <Alert severity="error" variant="filled">
                <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
                  {t('booking.payment.unsuccessful.message')}
                </Typography>
              </Alert>
            </Box>
            <Box>
              <Typography variant="h1">{t('booking.payment.unsuccessful.title')}</Typography>
              <Typography variant="body1">{t('booking.payment.unsuccessful.content')}</Typography>
            </Box>
            <ColorButton
              label={t('booking.payment.try-again-button-label')}
              onClick={createCheckout}
              disabled={isLoading}
              style={{ marginTop: '30px', width: !isLayoutMedium ? '80%' : '' }}
            />
          </Stack>
        )}
      </Box>
    </>
  );
};
