import './input-guests-select.scss';
import { SyntheticEvent, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GuestsSelectItem } from '../input-guests-select-item/input-guests-select-item';
import { FilterOptions, GuestFilterOptions } from '../../models/FilterOptions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      fontSize: '16px'
    },
  },
};

export const GuestsSelect: React.FC<{ id: string, label: string, options: GuestFilterOptions, onChanged: (g: GuestFilterOptions) => void, values: string[] }> = (props) => {
  const {t} = useTranslation('common');
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(props.values);

  const getStyles = (name: string, optionName: readonly string[], theme: Theme) => {
    return {
      fontWeight:
        optionName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    let values: string[] = [];
    props.options.getAll().forEach((o) => {
      
        values.push(t(o.label) + ': ' + o.count)
      
    });
    setSelectedOptions(values);
  };

  const handleClose = (event: SyntheticEvent<Element, Event>) => {
    props.onChanged(props.options);
  }

  const minusButtonClick = (event: any) => {
    let minValue = event.target.dataset.optionValue === 'adult' ? 1 : 0;
    let o = FilterOptions.guests.get(event.target.dataset.optionValue);
    if (o && o.count > minValue) {
      o.count--;
    }
  };

  const plusButtonClick = (event: any) => {
    let o = FilterOptions.guests.get(event.target.dataset.optionValue);
    if (o) {
      o.count++;

      if (o.value === 'adult') {
        if (o.count >= 4) {
          FilterOptions.guests.child.count = 0;
        } else if (o.count >= 3 && FilterOptions.guests.child.count >= 2) {
          FilterOptions.guests.child.count = 1;
        } else if (o.count >= 2 && FilterOptions.guests.child.count >= 3) {
          FilterOptions.guests.child.count = 2;
        }
      } else if (o.value === 'child') {
        if (o.count >= 3 && FilterOptions.guests.adult.count >= 2) {
          FilterOptions.guests.adult.count = 1;
        } else if (o.count >= 2 && FilterOptions.guests.adult.count >= 3) {
          FilterOptions.guests.adult.count = 2;
        } else if (o.count >= 1 && FilterOptions.guests.adult.count >= 4) {
          FilterOptions.guests.adult.count = 3;
        }
      }
    }
  };

  return <>
    <FormControl className='input-guests-select' sx={{ m: 1}}>
      <Typography variant="h6" style={{marginBottom: '4px'}}>{props.label}</Typography>
      <Select
        id={`filter-${props.id}`}
        multiple
        displayEmpty
        value={selectedOptions}
        onChange={handleChange}
        onClose={handleClose}
        input={<OutlinedInput id={`filter-${props.id}-chip`} />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span>{t('search.filters.guest.placeholder')}</span>;
          }
          return <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} disabled={value.split(' ')[1] === '0'} />
              ))}
            </Box>
          </>
        }}
        MenuProps={MenuProps}
      >
        {props.options.getAll().map((option, i) => (
          <MenuItem
            key={i}
            value={option.label}
            style={getStyles(option.value, selectedOptions, theme)}
          >
            <GuestsSelectItem option={option} minusClick={minusButtonClick} plusClick={plusButtonClick} />
          </MenuItem>
        ))}
        <Box style={{padding: '5px', paddingLeft: '15px'}}><Typography variant="body2">{t('search.filters.guest.hint')}</Typography></Box>
      </Select>
    </FormControl>
  </>
}
