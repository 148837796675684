import { Box, SvgIcon, Card, CardContent, Grid, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';
import { CruiseDepartureDate, CruiseDetails } from '../../services/search';
import { CruiseItinerary } from '../cruise-itinerary/cruise-itinerary';
import { Price, PriceType } from '../price/price';
import './cruise-details-panel-v2.scss';
import TodayIcon from '@mui/icons-material/Today';
import { ReactComponent as ArrowRightIcon } from './images/arrow-right-icon.svg';

export const CruiseDetailsPanelV2Horizontal: React.FC<{
  data: CruiseDetails;
  view: 'normal' | 'detail';
  guestsNumber?: number;
  showPrice?: boolean;
  showButton?: boolean;
}> = (props) => {
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');

  const firstDepartureDate = props.data.minDepartureDate ? new Date(props.data.minDepartureDate) : null;
  const departureDates = (props.data.departureDates || []).sort((a, b) => {
    if (a.fromDate === props.data.minDepartureDate) {
      return -1;
    } else if (b.fromDate === props.data.minDepartureDate) {
      return 1;
    } else {
      return 0;
    }
  });

  const [isItineraryOpen, setIsItineraryOpen] = useState<boolean>(false);

  const onDateScroll = (target: HTMLDivElement) => {
    if (target.scrollLeft > 0) {
      if (!target.className.includes('maskLeft')) {
        target.className += ' maskLeft';
      }
    } else if (target.className.includes('maskLeft')) {
      target.className = target.className.replace(' maskLeft', '');
    }

    if (target.scrollLeft >= target.scrollWidth - target.clientWidth) {
      if (!target.className.includes('noMaskRight')) {
        target.className += ' noMaskRight';
      }
    } else if (target.className.includes('noMaskRight')) {
      target.className = target.className.replace(' noMaskRight', '');
    }
  };

  const onDateClick = (dep: CruiseDepartureDate) => {
    trackEvent('cruise_date_click', { cruise_group_id: props.data.cruiseGroupId });
    trackCruiseSelectEvent();
    navigate(`/book/${props.data.cruiseLine}/${props.data.cruiseGroupId}/departure/${dep.id}`);
  };

  const handleItineraryDetailsToggle = () => {
    trackEvent(isItineraryOpen ? 'itinerary_details_close' : 'itinerary_details_open');
    setIsItineraryOpen(!isItineraryOpen);
  };

  const trackCruiseSelectEvent = (source?: string) => {
    trackEvent('cruise_select', { cruise_group_id: props.data.cruiseGroupId, source });
  };

  const DepartureDateCardContent = (args: { departure: CruiseDepartureDate }) => {
    const dep = args.departure;
    return (
      <>
        <CardContent style={{ padding: 0 }}>
          <Stack>
            <Stack gap={2} style={{ padding: 8 }}>
              <Stack className="price-main" style={{ alignItems: 'start' }}>
                <strong style={{ color: '#020C25' }}>
                  {t('booking.departure.first-date-option', {
                    date: new Date(dep.fromDate).toLocaleDateString(i18n.language, {
                      month: 'short',
                      day: 'numeric'
                    })
                  })}
                </strong>
                <span>
                  <TodayIcon style={{ fontSize: '0.8rem', marginRight: '4px' }} />
                  {t('booking.departure.first-date-option', {
                    date: new Date(dep.fromDate).toLocaleDateString(i18n.language, {
                      year: 'numeric'
                    })
                  })}
                </span>
              </Stack>

              <Box>
                <Price mainPrice={new PriceType(dep.pricePerPerson)} />
              </Box>
            </Stack>

            <Box style={{ backgroundColor: '#e1ebed', fontSize: '8px', padding: '2px' }}>
              <Price
                localPrice={
                  new PriceType(dep.pricePerPersonLocalCcy, __CONFIG__.currency.localCurrency, null, 'estimate')
                }
              />
            </Box>
          </Stack>
        </CardContent>
      </>
    );
  };

  const DepartureDateSelector = (args: { departure: CruiseDepartureDate }) => {
    const dep = args.departure;
    return (
      <>
        <Card className="departure-date-card" variant={'outlined'} onClick={() => onDateClick(dep)}>
          <DepartureDateCardContent departure={dep} />
        </Card>
      </>
    );
  };

  return (
    <>
      <Box className="cruise-details-panel-v2">
        <Box className="company-info">
          <Typography variant="h3">
            <Trans
              t={t}
              i18nKey="labels.cruise-length"
              values={{
                count: props.data.cruiseLength
              }}
            />{' '}
            {t(`search.filters.where.options.${props.data.destinationRegion}`)}
          </Typography>
        </Box>

        {props.view === 'normal' && (
          <Stack mb={2} rowGap={1}>
            <CruiseItinerary
              portsOfCall={props.data.portsOfCall}
              cruiseLength={props.data.cruiseLength}
              seaDays={props.data.seaDays}
              mode="icon"
            />
            {props.data.portsOfCall.length > 2 && (
              <>
                <Link onClick={handleItineraryDetailsToggle}>
                  <Grid container columnSpacing={1} justifyContent={'center'}>
                    <Grid item style={{ color: '#3E3E3E', fontWeight: 700, fontSize: 14 }}>
                      {t('booking.departure.itinerary-schedule-link')}
                    </Grid>
                    <Grid item>
                      <SvgIcon
                        component={ArrowRightIcon}
                        style={{
                          width: '20px',
                          height: '20px',
                          transform: isItineraryOpen ? 'rotate(90deg)' : ''
                        }}
                        viewBox="0 0 24 24"
                      />
                    </Grid>
                  </Grid>
                </Link>
                {isItineraryOpen && (
                  <Box>
                    <CruiseItinerary
                      portsOfCall={props.data.portsOfCall}
                      cruiseLength={props.data.cruiseLength}
                      seaDays={props.data.seaDays}
                      mode="long"
                    />
                  </Box>
                )}
              </>
            )}
          </Stack>
        )}

        {props.view === 'normal' && firstDepartureDate && (
          <Grid
            container
            mb={2}
            className="scroll-horizontally"
            onScroll={(e) => onDateScroll(e.target as HTMLDivElement)}
            spacing={2}
          >
            {departureDates.map((dep) => (
              <Grid item key={dep.id} xs={4} minWidth={'120px'}>
                <DepartureDateSelector departure={dep} />
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container justifyContent="end" alignItems="flex-end" flexWrap="nowrap" mt={1}>
          <Grid item>
            <Stack spacing={1}>{props.view === 'normal' && props.showButton && <Box textAlign="right"></Box>}</Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

CruiseDetailsPanelV2Horizontal.defaultProps = {
  view: 'normal',
  showPrice: true,
  showButton: true
};
