import { createContext, useState, useEffect, useContext } from "react";
import { CheckoutApi } from "../services/checkout";

import { Configuration } from '../services/checkout/configuration';
import { useAuth0 } from "@auth0/auth0-react";

const CheckoutApiContext = createContext<{checkoutApi: CheckoutApi}>({
  checkoutApi: {} as CheckoutApi
});

export const useCheckoutApi = () => useContext(CheckoutApiContext);

const DEFAULT_CONFIG = __CONFIG__.api.checkout;

export const CheckoutApiProvider = ({ children }: any) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [ checkoutApi, setCheckoutApi ] = useState<CheckoutApi>(new CheckoutApi(new Configuration(DEFAULT_CONFIG)));

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        if (token) {
          setCheckoutApi(new CheckoutApi(
            new Configuration({ ...DEFAULT_CONFIG, accessToken: token})
          ));
        }
      });
    }
  }, [isAuthenticated]);

  const value = { checkoutApi };

  return (
    <CheckoutApiContext.Provider value={value}>
      {children}
    </CheckoutApiContext.Provider>
  );
};