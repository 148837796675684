import { Box, IconButton } from '@mui/material';
import { SvgIcon } from '@mui/material';
import { ReactComponent as FlagHu } from './images/hu.svg';
import { ReactComponent as FlagGb } from './images/gb.svg';
import { ReactComponent as FlagRo } from './images/ro.svg';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Only supporting swtiching between 2 languages for now
const LANGUAGES = __CONFIG__.i18n.languages.slice(0, 2);
const FLAGS: Record<string, any> = {
  hu: FlagHu,
  ro: FlagRo,
  en: FlagGb
};

export const LanguageSwitcher: React.FC<{}> = (props) => {
  const { t, i18n } = useTranslation();

  const switchLanguage = () => {
    const l = LANGUAGES[(LANGUAGES.indexOf(i18n.language) + 1) % LANGUAGES.length];
    i18n.changeLanguage(l);
    moment.locale(l);
  };

  const FlagIcon = () => {
    return (
      <>
        <SvgIcon
          component={FLAGS[LANGUAGES.filter((l) => l !== i18n.language)[0]]}
          style={{ cursor: 'pointer', width: '25px', height: '20px' }}
          viewBox="0 0 640 480"
        />
      </>
    );
  };

  const buttonTitle = () => {
    return t(`language.switch-to.${i18n.language}`);
  };

  return (
    <>
      <Box title={buttonTitle()}>
        <IconButton onClick={switchLanguage}>
          <FlagIcon />
        </IconButton>
      </Box>
    </>
  );
};
