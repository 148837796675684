import { Box, Link, Stack, SvgIcon, SxProps } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './cruise-itinerary.scss';
import { ReactComponent as ShipIcon } from './images/ship.svg';

const MAX_PORTS_OF_CALL = 18;
const MORE_PORTS_OF_CALL_ELLIPSIS = '…';

export type CruiseItineraryModeType = 'long' | 'inline' | 'icon';

interface CruiseItineraryProps {
  portsOfCall: Array<string>;
  cruiseLength: number;
  seaDays: number;
  mode?: CruiseItineraryModeType;
  sx?: SxProps;
}

export const CruiseItinerary: React.FC<CruiseItineraryProps> = (props) => {
  // Translation
  const { t } = useTranslation('common');

  const [displayAllPorts, setDisplayAllPorts] = useState(false);
  const displayedPortsOfCall =
    props.portsOfCall.length > MAX_PORTS_OF_CALL && !displayAllPorts
      ? [
          ...props.portsOfCall.slice(0, MAX_PORTS_OF_CALL / 2),
          MORE_PORTS_OF_CALL_ELLIPSIS,
          ...props.portsOfCall.slice((-1 * MAX_PORTS_OF_CALL) / 2)
        ]
      : props.portsOfCall;

  const PortOfCall = (args: { port: string; index: number }) => {
    const portsLength = displayedPortsOfCall.length - 1;
    const p = args.port;
    const i = args.index;

    // First item
    if (i === 0) {
      return (
        <Box display="inline-block">
          <span className="bold">{p}</span>
          <span className="ports-separator">&gt;</span>
        </Box>
      );
    }

    // Last item
    if (i === portsLength) {
      return (
        <Box display="inline-block">
          <span className="bold">{p}</span>
          {props.seaDays > 0 && (
            <>
              {' ('}
              <Trans
                t={t}
                i18nKey="labels.days-at-sea-length"
                values={{
                  count: props.seaDays
                }}
              />
              {')'}
            </>
          )}
        </Box>
      );
    }

    // Ellipsis item
    if (p === MORE_PORTS_OF_CALL_ELLIPSIS) {
      return (
        <Box display="inline-block">
          <Link component="button" onClick={() => setDisplayAllPorts(true)} sx={{ fontSize: 'inherit' }}>
            {p}
            <Trans
              t={t}
              i18nKey="labels.more-ports-of-call"
              values={{
                count: props.portsOfCall.length - displayedPortsOfCall.length + 1
              }}
            />
            {p}
          </Link>
          <span className="ports-separator">&gt;</span>
        </Box>
      );
    }

    // Middle items
    // TODO: move translation to ports.
    return (
      <span style={{ display: 'inline-block' }}>
        {t(`${p}`)}
        <span className="ports-separator">&gt;</span>
      </span>
    );
  };

  const PortsOfCallLong = () => {
    return (
      <Box className="cruise-itinerary" sx={props.sx}>
        {displayedPortsOfCall.map((port: string, index: number) => (
          <PortOfCall key={index} port={port} index={index} />
        ))}
      </Box>
    );
  };

  const PortsOfCallIcon = () => {
    const departure = props.portsOfCall[0];
    const arrival = props.portsOfCall[props.portsOfCall.length - 1];

    return (
      <Stack
        className="cruise-itinerary"
        sx={props.sx}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack style={{width: '34%'}}>
          <Box className="uppercase">{t('labels.departure')}</Box>
          <Box className="bold">{departure}</Box>
        </Stack>

        <Box className="arrow dotted"></Box>

        <Stack alignItems="center" style={{ width: '16%' }}>
          <SvgIcon component={ShipIcon} style={{ width: '20px', height: '20px' }} viewBox="0 0 20 20" />
          <Box textAlign="center">
            {t('labels.number-of-ports-of-call', { count: props.cruiseLength - props.seaDays - 1 })}
          </Box>
        </Stack>

        <Box className="arrow dotted"></Box>

        <Stack style={{width: '34%', textAlign: 'right'}}>
          <Box className="uppercase">{t('labels.arrival')}</Box>
          <Box className="bold">{arrival}</Box>
        </Stack>
      </Stack>
    );
  };

  const PortsOfCall = () => {
    switch (props.mode) {
      case 'inline':
      case 'long':
        return <PortsOfCallLong />;
      case 'icon':
        return <PortsOfCallIcon />;
    }
    return <></>;
  };

  return (
    <>
      <PortsOfCall />
    </>
  );
};

CruiseItinerary.defaultProps = {
  mode: 'long'
};
