import styled from '@mui/material/styles/styled';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

export const ProfileImage = styled(Avatar)(({ theme }) => ({
  border: '2px solid white'
}));

export const ProfileButton = styled(Button)(({ theme }) => ({
  paddingLeft: '8px',
  paddingRight: '8px',
  fontSize: '14px !important'
}));

export const AuthMenuItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',  
}));

