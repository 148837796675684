import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorButton } from '../../components/color-button/color-button';
import { CruiseSearchResultList } from '../../components/cruise-search-result-list/cruise-search-result-list';
import { FilterPanel } from '../../components/filter-panel/filter-panel';
import depositBannerImage from '../../components/header/images/main-header-background.webp';
import { SearchResultHeader } from '../../components/search-result-header/search-result-header';
import { SectionBox } from '../../components/section-box/section-box';
import { useSearchApi } from '../../contexts/search-api.context';
import { useTracking } from '../../hooks/useTracking';
import { BookingState } from '../../models/BookingState';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { CruiseSearchResultType } from '../../models/State';
import {
  CruiseDetails,
  CruiseSearchRequestSortDirectionEnum,
  CruiseSearchRequestSortEnum
} from '../../services/search';
import '../../styles/search-page.scss';

export type SearchPageProps = {
  searchFilters: CruiseSearchFilters;
  updateSearchFilters: (f: CruiseSearchFilters) => void;
  updateBooking: (b: BookingState) => void;
  scrollToTop: () => void;
};

var currentPage: number = 0;

const defaultSearchState: CruiseSearchResultType = {
  cruises: [],
  totalResultCount: 0
};

const BannerOnTop: React.FC<{ searchFilters: CruiseSearchFilters; noResults: boolean }> = (props) => {
  const { trackEvent } = useTracking();
  const isLoginEnabled = useFeatureIsOn('login');
  const isLoginDiscountBannerEnabled = useFeatureIsOn('login-discount-banner');
  const isLoginNominalDiscountBannerEnabled = useFeatureIsOn('login-nominal-discount-banner');
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { t } = useTranslation('common');

  const loginFromBanner = useCallback(() => {
    trackEvent('discount-login-clicked');
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      }
    });
  }, [loginWithRedirect, trackEvent]);

  if (isLoginEnabled && isLoginDiscountBannerEnabled) {
    return (
      <Box p={2} style={{ borderRadius: '20px', backgroundImage: `url(${depositBannerImage})` }}>
        <Typography variant="h1" color="#fff">
          {t(`search.banner.login${isLoginNominalDiscountBannerEnabled ? 'Nominal' : ''}Discount.title`)}
        </Typography>
        <Typography variant="h4" sx={{ textAlign: 'left !important' }} color="#fff">
          {t(`search.banner.login${isLoginNominalDiscountBannerEnabled ? 'Nominal' : ''}Discount.description`)}
          <span
            className="inline-discount-code"
            style={
              isAuthenticated
                ? {}
                : {
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    filter: 'blur(4px)',
                    cursor: 'pointer'
                  }
            }
            onClick={isAuthenticated ? undefined : loginFromBanner}
          >
            {isAuthenticated
              ? t(`search.banner.login${isLoginNominalDiscountBannerEnabled ? 'Nominal' : ''}Discount.discountCode`)
              : '0000'}
          </span>
        </Typography>
      </Box>
    );
  }

  const { promo, when } = { ...props.searchFilters };

  if (promo && promo.length && promo.includes('Winter Wonders')) {
    return (
      <div className="promo-banner">
        <img src="/site/msc_winter_wonders_banner.webp" alt="winter wonders" height="" />
      </div>
    );
  }

  const lastDayForDeposit = new Date();
  lastDayForDeposit.setDate(lastDayForDeposit.getDate() + __CONFIG__.booking.deposit.shouldPayDepositLimitDays);
  const notLastMinuteSearch = when.to === null || when.to > lastDayForDeposit;

  if (notLastMinuteSearch && __CONFIG__.search.depositBannerEnabled && !props.noResults) {
    return (
      <Box p={2} style={{ borderRadius: '20px', backgroundImage: `url(${depositBannerImage})` }}>
        <Typography variant="h1" color="#fff">
          {t('search.banner.deposit.title')}
        </Typography>
        <Typography variant="h4" sx={{ textAlign: 'left !important' }} color="#fff">
          {t('search.banner.deposit.description', { days: __CONFIG__.booking.deposit.shouldPayDepositLimitDays })}
        </Typography>
      </Box>
    );
  }

  return <></>;
};

export const SearchPage: React.FC<SearchPageProps> = ({ searchFilters, updateSearchFilters, scrollToTop }) => {
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { filters: urlFilters } = useParams();
  const { searchApi } = useSearchApi();

  const [searchResultState, setSearchResultState] = useState<CruiseSearchResultType>(defaultSearchState);
  const [isFiltersVisible, setFiltersVisible] = useState<boolean>(searchFilters.isEmpty());
  const [isResultsVisible, setResultsVisible] = useState<boolean>(searchResultState.cruises.length > 0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mobileHeaderScrollPosition, setMobileHeaderScrollPosition] = useState(0);

  const updateSearchResults = useCallback((cruises: CruiseDetails[] | null, totalCount: number) => {
    if (!cruises) {
      setSearchResultState(defaultSearchState);
    } else {
      setSearchResultState((currentSearcResultState) => ({
        cruises: currentSearcResultState.cruises.concat(cruises),
        totalResultCount: totalCount
      }));
    }
  }, []);

  const resetSearchResults = useCallback(() => {
    currentPage = 0;

    searchFilters.offset = 0;
    updateSearchFilters(searchFilters);

    updateSearchResults(null, 0);
  }, []);

  const fetchMoreData = useCallback((filters?: string) => {
    setIsLoading(true);
    setResultsVisible(true);

    if (filters) {
      searchFilters.init(filters);
    }
    searchFilters.offset = currentPage * searchFilters.limit;
    updateSearchFilters(searchFilters);

    const searchRequest = searchFilters.toSearchRequest();

    const searchPromise = searchApi.postCruiseSearch(searchRequest);
    const filtersAsSearchParam = searchFilters.toUrlSearchParams();

    searchPromise
      .then((response) => {
        updateSearchResults(response.data.items, response.data.totalCount);
        setIsLoading(false);
        if (response.data.totalCount === 0) {
          trackEvent('search_no_result', { filters: filtersAsSearchParam });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        trackEvent('search_error', { filters: filtersAsSearchParam });
        Sentry.captureException(error);
      });

    currentPage = currentPage + 1;
  }, []);

  const resetFilters = useCallback(() => {
    searchFilters.clear();
    updateSearchFilters(searchFilters);
    setFiltersVisible(true);
    fetchMoreData();
  }, []);

  const searchClicked = useCallback(() => {
    trackEvent('search', { filters: searchFilters.toUrlSearchParams() });
    resetSearchResults();
    fetchMoreData();
    setFiltersVisible(false);

    scrollToTop();
    navigate({
      pathname: `/search/${searchFilters.toUrlSearchParams()}`
    });
  }, []);

  const onSort = useCallback((sort: CruiseSearchRequestSortEnum, direction: CruiseSearchRequestSortDirectionEnum) => {
    searchFilters.sort = sort;
    searchFilters.sortDirection = direction;
    updateSearchFilters(searchFilters);

    resetSearchResults();
    fetchMoreData();
    setFiltersVisible(false);

    scrollToTop();
  }, []);

  const navigateToFilters = useCallback(() => {
    scrollToTop();
    navigate({ pathname: '/filter' });
  }, []);

  useEffect(() => {
    resetSearchResults();
    fetchMoreData(urlFilters);
    setFiltersVisible(false);
  }, []);

  const noResults = isResultsVisible && !isLoading && searchResultState.totalResultCount === 0;

  const tiles = __CONFIG__.quickSearch.home.filter((tile) => urlFilters === tile.key);
  const metaTitle = tiles.length
    ? t(`home.quick-search-button.${tiles[0].key}`) + t('meta.quicksearch-title-postfix', '')
    : undefined;
  const metaDescription = tiles.length ? t('meta.quicksearch-description', '') : undefined;

  // need to re-create on every-render until stuck staate is fixed. E.g. filters coming from url
  const FilterPanelForcedNew = () => (
    <FilterPanel
      searchFilters={searchFilters}
      updateSearchFilters={updateSearchFilters}
      onSearch={searchClicked}
      searchOnSelect={!isLayoutSmall}
    />
  );

  return (
    <>
      <Helmet>
        {metaTitle && <title>{metaTitle}</title>}
        {metaTitle && <meta property="og:title" content={metaTitle} />}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaDescription && <meta property="og:description" content={metaDescription} />}
      </Helmet>
      <Stack className="search-page" spacing={8}>
        <Box sx={{ flexGrow: 1, paddingTop: '20px' }} padding={{ xs: '0 8px', md: '0 100px' }}>
          <Grid
            container
            width="100%"
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            spacing={{ xs: 2, sm: 2, md: 6 }}
          >
            {(isFiltersVisible || !isLayoutSmall) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={searchFilters.isEmpty() && searchResultState.cruises.length === 0 ? 12 : 3.5}
              >
                <FilterPanelForcedNew />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={8.5}>
              <Stack direction="column" spacing={3}>
                <SearchResultHeader
                  searchFilters={searchFilters}
                  updateSearchFilters={updateSearchFilters}
                  searchClicked={searchClicked}
                  navigateToFilters={navigateToFilters}
                  onSort={onSort}
                  mobileHeaderScrollPosition={mobileHeaderScrollPosition}
                  setMobileHeaderScrollPosition={setMobileHeaderScrollPosition}
                />

                <BannerOnTop searchFilters={searchFilters} noResults={noResults} />

                {noResults && (
                  <SectionBox>
                    <Stack spacing={1} justifyContent="space-between" marginBottom="32px">
                      <Typography variant={isLayoutSmall ? 'h2' : 'h3'} textAlign="left">
                        {t('search.no-result.title')}
                      </Typography>
                      <Typography variant="body1">{t('search.no-result.description')}</Typography>
                      <Box textAlign="center">
                        <ColorButton
                          label={t('search.no-result.new-search-button-label')}
                          onClick={resetFilters}
                          variant="outlined"
                          style={{ width: '300px', marginTop: 2 }}
                        />
                      </Box>
                    </Stack>
                  </SectionBox>
                )}

                <Box>
                  {isResultsVisible && (
                    <CruiseSearchResultList
                      cruiseSearchState={searchResultState}
                      fetchMoreData={fetchMoreData}
                      isLoading={isLoading}
                      searchFilters={searchFilters}
                    />
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
};
