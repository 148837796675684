import './header.scss';
import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { NavigationBar } from '../navigation-bar/navigation-bar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { useLocation } from 'react-router-dom';
import { QuickSearchBox } from '../quick-search-box/quick-search-box';

export const Header: React.FC<{
  searchFilters: CruiseSearchFilters;
  updateSearchFilters: (f: CruiseSearchFilters) => void;
  scrollToTop: () => void;
}> = (props) => {
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const route = useLocation();
  const desktopHomeHeader = !isLayoutSmall && route.pathname === '/';

  return (
    <>
      <Box className="header" sx={{ paddingBottom: { xs: '10px', sm: '10px', md: '0' } }}>
        <Stack
          className={`header-container ${isLayoutSmall ? 'rounded' : ''}`}
          style={{ background: isLayoutSmall ? '#fff' : route.pathname !== '/' ? '#2f323d' : '' }}
          spacing={isLayoutSmall ? 2 : 0}
        >
          <Grid item>
            <NavigationBar scrollToTop={props.scrollToTop} desktopHomeHeader={desktopHomeHeader} />
          </Grid>
          {desktopHomeHeader && (
            <Grid item style={{ padding: '190px 0 0 0', height: '450px', boxShadow: '0px 4px 4px #AEAEAE' }}>
              <Stack alignItems="center" spacing={2}>
                <Box style={{ maxWidth: '1040px', width: '90%' }}>
                  <QuickSearchBox searchFilters={props.searchFilters} updateSearchFilters={props.updateSearchFilters} />
                </Box>
              </Stack>
            </Grid>
          )}
        </Stack>
      </Box>
    </>
  );
};
