import { Box, Grid, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const CompanyDetailsPageUK: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Box>
        <Stack spacing={8}>
          <SectionBox boxParams={{ style: { padding: '0 100px' } }}>
            <SectionTitle title={t('company-details.title')} />
            <Box marginTop={3}>
              <b>CLICKnCRUISE Ltd.</b>
              <br />
              Address: <br />
              Lytchett House 13 Freeland Park
              <br />
              Wareham Road <br />
              Poole <br />
              BH16 6FA
              <br />
              <br />
              Email: ahoy@clickncruise.co.uk
              <br />
            </Box>
            <Box marginTop={3}>
              <b>ATOL Information:</b>
              <br />
              Accredited Body Member (CLICKnCRUISE) trading division as notified to the CAA is an Accredited Body member
              of Barrhead Travel Service Ltd and the Accredited Body's ATOL number 5346.
            </Box>
            <Box marginTop={3}>
              <b>ATOL Protection:</b>
              <br />
              All the flights and flight-inclusive holidays on this website are financially protected by the ATOL
              scheme. When you pay you will be supplied with an ATOL Certificate. Please ask for it and check to ensure
              that everything you booked (flights, hotels and other services) is listed on it. Please see our booking
              conditions for further information or for more information about financial protection and the ATOL
              Certificate go to:
              <Link href="http://www.caa.co.uk/" target="_blank" rel="noreferrer">
                www.caa.co.uk
              </Link>
            </Box>
            <Box marginTop={3}>
              <b>ABTA:</b>
              <br />
              Book with Confidence. We are a Member of{' '}
              <Link href="https://www.abta.com/" target="_blank" rel="noreferrer">
                ABTA
              </Link>{' '}
              which means you have the benefit of ABTA's assistance and Code of Conduct. All the package and Flight-Plus
              holidays we sell are covered by a scheme protecting your money if the supplier fails. Other services such
              as hotels or flights on their own may not be protected and you should ask us what protection is available.
              The Barrhead Travel ABTA number is 13759.
              <br />
              We also offer ABTA's scheme for the resolution of disputes which is approved by the Chartered Trading
              Standards Institute. If we can't resolve your complaint, go to{' '}
              <Link href="https://www.abta.com/" target="_blank" rel="noreferrer">
                www.abta.com
              </Link>{' '}
              to use ABTA's simple procedure and to find further information on the code and ABTA's assistance in
              resolving disputes.
            </Box>
            <Box marginTop={3}>
              <b>ADR - ODR:</b>
              <br />
              If you have a complaint you have access to Alternative Dispute Resolution(ADR) and Online Dispute
              Resolution (ODR) for online sales.
              <br />
              We make every effort to ensure that your holiday arrangements run smoothly but if you do have a problem
              during your holiday, please inform the cruise line immediately who will endeavour to rectify the
              situation. If the problem cannot be resolved and you wish to complain further, you must send a formal
              written notice of your complaint to{' '}
              <Link href="mailto:ahoy@clickncruise.co.uk">ahoy@clickncruise.co.uk</Link>.
              <br />
              You can also access the European Commission online dispute resolution (ODR) platform at{' '}
              <Link href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">
                ec.europa.eu/consumers/odr/
              </Link>
              . This ODR platform is a means of registering your complaint; it will not determine how your complaint
              should be resolved.
            </Box>
            <Box marginTop={3}>
              <b>Passports and Visas:</b>
              <br />
              Make sure you check passport requirements (such as validity) and visa requirements on the Government's
              passport advice{' '}
              <Link href="https://www.gov.uk/browse/abroad/passports" target="_blank" rel="noreferrer">
                www.gov.uk/browse/abroad/passports
              </Link>
            </Box>
            <Box marginTop={3}>
              <b>Health:</b>
              <br />
              Make sure you check recommended practice with your GP, practice nurse or travel health clinic. Please read
              carefully the health information for destinations at{' '}
              <Link href="https://www.gov.uk/foreign-travel-advice" target="_blank" rel="noreferrer">
                www.gov.uk/foreign-travel-advice
              </Link>{' '}
              and{' '}
              <Link href="https://travelhealthpro.org.uk" target="_blank" rel="noreferrer">
                travelhealthpro.org.uk
              </Link>
            </Box>
            <Box marginTop={3}>
              <b>Foreign, Commonwealth & Development Office (FCDO) Travel Advice:</b>
              <br />
              <Link href="http://www.gov.uk/foreign-travel-advice" target="_blank" rel="noreferrer">
                www.gov.uk/foreign-travel-advice
              </Link>
            </Box>
          </SectionBox>
        </Stack>
      </Box>
    </>
  );
};
CompanyDetailsPageUK.displayName = 'CompanyDetailsPageUK';