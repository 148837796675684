import { useNavigate } from "react-router";
import { ReactComponent as LogoIcon } from "./images/logo-icon.svg";
import { ReactComponent as LogoIconDark } from "./images/logo-icon-dark.svg";
import { ReactComponent as LogoIconInverse } from "./images/logo-icon-inverse.svg";
import { ReactComponent as LogoIconOnlyImage } from "./images/logo-icon-only.svg";
import { SvgIcon } from "@mui/material";

export type LogoColor = "light" | "dark" | "inverse";

export const Logo: React.FC<{ color?: LogoColor, width?: number }> = (props) => {
  let navigation = useNavigate();
  const goHome = () => {
    navigation('/');
  }

  const colorMapping = {
    dark: LogoIconDark,
    light: LogoIcon,
    inverse: LogoIconInverse
  }

  return <>
    <SvgIcon component={colorMapping[props.color || 'light']}
             onClick={goHome}
             style={{ cursor: 'pointer', width: `${props.width}px`, height: (props.width ? props.width / 6 : 40) + 'px' }}
             viewBox="0 0 230 27"
              />
  </>
}

Logo.defaultProps = {
  color: 'light',
  width: 230
}

export const LogoIconOnly: React.FC<{ width?: number }> = (props) => {
  let navigation = useNavigate();
  const goHome = () => {
    navigation('/');
  }

  return <>
    <SvgIcon component={LogoIconOnlyImage}
             onClick={goHome}
             style={{ cursor: 'pointer', width: `${props.width}px`, height: (props.width ? props.width * 0.9 : 108) + 'px' }}
             viewBox="0 0 121 108"
              />
  </>
}
