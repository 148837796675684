import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './labels.scss';

export const PromoLabel: React.FC<{
  promo: string;
}> = (props) => {
  const { t } = useTranslation('common');

  const style =
    props.promo === 'Black Friday' ? { background: 'linear-gradient(163deg,#8f0071 0%,#8f0071 30%,#000 100%)' } : {};

  return (
    <Box className="label-box" style={style}>
      {t(`search.filters.promo.options.${props.promo}`, props.promo)}
    </Box>
  );
};
