import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../hooks/useTracking';
import { BookingState } from '../../models/BookingState';
import { ColorButton } from '../color-button/color-button';
import { ContactForm } from '../contact-form/contact-form';
import './booking-departure-callback-dialog.scss';

export const BookingDepartureCallbackDialog: React.FC<{
  data: BookingState;
  cabinId?: string;
  handleChange: () => void;
  color?: string;
}> = (props) => {
  const { trackEvent } = useTracking();

  // Translation
  const { t } = useTranslation('common');

  // Theme
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const trackBookingEvent = () => {
    trackEvent('callback_requested', {
      conversion: { revenue: props.data.calculateTotalPrice(true), currency: __CONFIG__.currency.default },
      cruise_group_id: props.data.bookingDetails?.cruiseGroupId,
      price: props.data.calculateTotalPrice(true),
      currency: __CONFIG__.currency.default
    });
  };

  const handleContactAfterSubmit = (booking: BookingState) => {
    if (booking.guestsState.guestList.length > 0) {
      props.handleChange();
      trackBookingEvent();
      handleDialogClose();
    }
  };

  return (
    <Box>
      <ColorButton
        label={t('booking.cabintype.choose-button-label-callback')}
        data-cruise-code={props.data.cabinType?.id}
        onClick={(e) => {
          trackEvent('callback_dialog_opened');
          handleDialogOpen();
        }}
        style={{ backgroundColor: props.color }}
        fullWidth
      />
      <Dialog
        className="booking-departure-callback-dialog"
        open={isDialogOpened}
        onClose={handleDialogClose}
        fullScreen={isLayoutMedium}
      >
        <DialogTitle>
          {t('booking.departure.callback-dialog.title')}
          <IconButton
            aria-label="Bezar"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ minWidth: isLayoutMedium ? '' : '480px' }}>
          <Typography variant="body2" textAlign="center" marginTop="8px">
            {t('booking.departure.callback-dialog.sub-title')}
          </Typography>

          <ContactForm
            bookingState={props.data}
            cabinId={props.cabinId}
            afterSubmit={handleContactAfterSubmit}
            source="callback"
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
