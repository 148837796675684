/* tslint:disable */
/* eslint-disable */
/**
 * CLICKnCRUISE Checkout API
 * CLICKnCRUISE API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyDiscountRequest } from '../models';
// @ts-ignore
import { CreateBookingRequest } from '../models';
// @ts-ignore
import { CreateBookingResponse } from '../models';
// @ts-ignore
import { CreateCheckoutResponse } from '../models';
// @ts-ignore
import { CreateFavoriteRequest } from '../models';
// @ts-ignore
import { CreateFavoriteResponse } from '../models';
// @ts-ignore
import { CreateOfferRequest } from '../models';
// @ts-ignore
import { CreateOptionRequest } from '../models';
// @ts-ignore
import { CreateOptionResponse } from '../models';
// @ts-ignore
import { CreateTransactionRequest } from '../models';
// @ts-ignore
import { CreateTransactionResponse } from '../models';
// @ts-ignore
import { GetFavoriteResponse } from '../models';
// @ts-ignore
import { GetOffersResponse } from '../models';
// @ts-ignore
import { TransactionStatus200Response } from '../models';
// @ts-ignore
import { UpdateBookingRequest } from '../models';
// @ts-ignore
import { UpdateBookingResponse } from '../models';
/**
 * CheckoutApi - axios parameter creator
 * @export
 */
export const CheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplyDiscountRequest} applyDiscountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyDiscounts: async (applyDiscountRequest: ApplyDiscountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyDiscountRequest' is not null or undefined
            assertParamExists('applyDiscounts', 'applyDiscountRequest', applyDiscountRequest)
            const localVarPath = `/discount/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyDiscountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBookingRequest} createBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking: async (createBookingRequest: CreateBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBookingRequest' is not null or undefined
            assertParamExists('createBooking', 'createBookingRequest', createBookingRequest)
            const localVarPath = `/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckout: async (transactionId: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('createCheckout', 'transactionId', transactionId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createCheckout', 'body', body)
            const localVarPath = `/transaction/{transactionId}/checkout`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFavoriteRequest} createFavoriteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFavorite: async (createFavoriteRequest: CreateFavoriteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFavoriteRequest' is not null or undefined
            assertParamExists('createFavorite', 'createFavoriteRequest', createFavoriteRequest)
            const localVarPath = `/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFavoriteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOfferRequest} createOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer: async (createOfferRequest: CreateOfferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfferRequest' is not null or undefined
            assertParamExists('createOffer', 'createOfferRequest', createOfferRequest)
            const localVarPath = `/offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOptionRequest} createOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOptionFromBookingId: async (createOptionRequest: CreateOptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOptionRequest' is not null or undefined
            assertParamExists('createOptionFromBookingId', 'createOptionRequest', createOptionRequest)
            const localVarPath = `/msc/option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction: async (createTransactionRequest: CreateTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransactionRequest' is not null or undefined
            assertParamExists('createTransaction', 'createTransactionRequest', createTransactionRequest)
            const localVarPath = `/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavorite: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getFavorite', 'slug', slug)
            const localVarPath = `/favorite/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getOffers', 'slug', slug)
            const localVarPath = `/offer/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns HTTP 200 if the server considers itself ready to serve requests.
         * @summary Healthcheck endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {string} redirectStatus 
         * @param {string} [stripeCheckoutSessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectedTransaction: async (transactionId: string, redirectStatus: string, stripeCheckoutSessionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('redirectedTransaction', 'transactionId', transactionId)
            // verify required parameter 'redirectStatus' is not null or undefined
            assertParamExists('redirectedTransaction', 'redirectStatus', redirectStatus)
            const localVarPath = `/transaction/{transactionId}/callback/{redirectStatus}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)))
                .replace(`{${"redirectStatus"}}`, encodeURIComponent(String(redirectStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stripeCheckoutSessionId !== undefined) {
                localVarQueryParameter['stripe_checkout_session_id'] = stripeCheckoutSessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatus: async (transactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transactionStatus', 'transactionId', transactionId)
            const localVarPath = `/transaction/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBookingRequest} updateBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking: async (updateBookingRequest: UpdateBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBookingRequest' is not null or undefined
            assertParamExists('updateBooking', 'updateBookingRequest', updateBookingRequest)
            const localVarPath = `/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplyDiscountRequest} applyDiscountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyDiscounts(applyDiscountRequest: ApplyDiscountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyDiscounts(applyDiscountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBookingRequest} createBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBooking(createBookingRequest: CreateBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBooking(createBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCheckout(transactionId: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCheckoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCheckout(transactionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateFavoriteRequest} createFavoriteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFavorite(createFavoriteRequest: CreateFavoriteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFavoriteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFavorite(createFavoriteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOfferRequest} createOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffer(createOfferRequest: CreateOfferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffer(createOfferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOptionRequest} createOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOptionFromBookingId(createOptionRequest: CreateOptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOptionFromBookingId(createOptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransaction(createTransactionRequest: CreateTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransaction(createTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavorite(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFavoriteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavorite(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffers(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOffersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffers(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns HTTP 200 if the server considers itself ready to serve requests.
         * @summary Healthcheck endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {string} redirectStatus 
         * @param {string} [stripeCheckoutSessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectedTransaction(transactionId: string, redirectStatus: string, stripeCheckoutSessionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectedTransaction(transactionId, redirectStatus, stripeCheckoutSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionStatus(transactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionStatus(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBookingRequest} updateBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBooking(updateBookingRequest: UpdateBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBooking(updateBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplyDiscountRequest} applyDiscountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyDiscounts(applyDiscountRequest: ApplyDiscountRequest, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.applyDiscounts(applyDiscountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBookingRequest} createBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking(createBookingRequest: CreateBookingRequest, options?: any): AxiosPromise<CreateBookingResponse> {
            return localVarFp.createBooking(createBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckout(transactionId: string, body: object, options?: any): AxiosPromise<CreateCheckoutResponse> {
            return localVarFp.createCheckout(transactionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateFavoriteRequest} createFavoriteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFavorite(createFavoriteRequest: CreateFavoriteRequest, options?: any): AxiosPromise<CreateFavoriteResponse> {
            return localVarFp.createFavorite(createFavoriteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOfferRequest} createOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(createOfferRequest: CreateOfferRequest, options?: any): AxiosPromise<object> {
            return localVarFp.createOffer(createOfferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOptionRequest} createOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOptionFromBookingId(createOptionRequest: CreateOptionRequest, options?: any): AxiosPromise<CreateOptionResponse> {
            return localVarFp.createOptionFromBookingId(createOptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(createTransactionRequest: CreateTransactionRequest, options?: any): AxiosPromise<CreateTransactionResponse> {
            return localVarFp.createTransaction(createTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavorite(slug: string, options?: any): AxiosPromise<GetFavoriteResponse> {
            return localVarFp.getFavorite(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers(slug: string, options?: any): AxiosPromise<GetOffersResponse> {
            return localVarFp.getOffers(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns HTTP 200 if the server considers itself ready to serve requests.
         * @summary Healthcheck endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck(options?: any): AxiosPromise<string> {
            return localVarFp.healthcheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {string} redirectStatus 
         * @param {string} [stripeCheckoutSessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectedTransaction(transactionId: string, redirectStatus: string, stripeCheckoutSessionId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.redirectedTransaction(transactionId, redirectStatus, stripeCheckoutSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionStatus(transactionId: string, options?: any): AxiosPromise<TransactionStatus200Response> {
            return localVarFp.transactionStatus(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBookingRequest} updateBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking(updateBookingRequest: UpdateBookingRequest, options?: any): AxiosPromise<UpdateBookingResponse> {
            return localVarFp.updateBooking(updateBookingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI {
    /**
     * 
     * @param {ApplyDiscountRequest} applyDiscountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public applyDiscounts(applyDiscountRequest: ApplyDiscountRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).applyDiscounts(applyDiscountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBookingRequest} createBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public createBooking(createBookingRequest: CreateBookingRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).createBooking(createBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transactionId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public createCheckout(transactionId: string, body: object, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).createCheckout(transactionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateFavoriteRequest} createFavoriteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public createFavorite(createFavoriteRequest: CreateFavoriteRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).createFavorite(createFavoriteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOfferRequest} createOfferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public createOffer(createOfferRequest: CreateOfferRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).createOffer(createOfferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOptionRequest} createOptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public createOptionFromBookingId(createOptionRequest: CreateOptionRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).createOptionFromBookingId(createOptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTransactionRequest} createTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public createTransaction(createTransactionRequest: CreateTransactionRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).createTransaction(createTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public getFavorite(slug: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).getFavorite(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public getOffers(slug: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).getOffers(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns HTTP 200 if the server considers itself ready to serve requests.
     * @summary Healthcheck endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public healthcheck(options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).healthcheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transactionId 
     * @param {string} redirectStatus 
     * @param {string} [stripeCheckoutSessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public redirectedTransaction(transactionId: string, redirectStatus: string, stripeCheckoutSessionId?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).redirectedTransaction(transactionId, redirectStatus, stripeCheckoutSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public transactionStatus(transactionId: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).transactionStatus(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBookingRequest} updateBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public updateBooking(updateBookingRequest: UpdateBookingRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).updateBooking(updateBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
