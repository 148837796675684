import { Box, Grid, Stack } from '@mui/material';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';
import { SubscriptionBox } from '../components/subscription-box/subscription-box';

export const TermsAndConditionsPageRO: React.FC<{}> = (props) => {
  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={'Termeni și condiții ale contractului de călătorie la cerere'} />
            <SectionBox boxParams={{ style: { marginTop: '40px' } }}>
              <h3>Preambulum</h3>
              <p>
                Prezentele Condiții generale (denumite în continuare "CG") conțin următoarele dispoziții, CLICKnCRUISE
                Societate cu răspundere limitată (sediul social: 9141, Ikrény, Rét köz 2; număr de înregistrare a
                societății: 08-09-033950; număr de înregistrare fiscală: 27547111-1-08; Oficiul Maghiar de Licențe
                Comerciale număr de înregistrare: U-001970; număr de telefon: 06203705292; denumită în continuare
                CLICKnCRUISE), în calitate de ca agent de turism se aplică în cadrul activităților sale de agent de
                turism.
              </p>
              <p>
                Termenii și condițiile generale de utilizare sunt disponibile pe pagina de internet www.clickncruise.hu
                operată de agenția de turism (denumită în continuare: Website) și serviciile conexe furnizate pe site-ul
                internet de către CLICKnCRUISE în calitate de agenție de turism servicii disponibile prin intermediul
                site-ului web.
              </p>
              <p>
                Utilizarea acestui serviciu constituie acceptarea acestor CGC. Datele cu caracter personal ale
                călătorilor sunt reglementate de Politica de confidențialitate.
              </p>

              <h3>1. Intermediarul de turism</h3>
              <p>
                CLICKnCRUISE se angajează, contra cost, să furnizeze pasagerului un serviciu de călătorie organizat de
                un alt intermediar de călătorie servicii de călătorie în legătură cu o călătorie organizată pentru
                Pasager de către o altă agenție de călătorie. CLICKnCRUISE va avea toate drepturile licențe necesare
                pentru a-și desfășura activitățile.
              </p>

              <h3>2. Activități ale intermediarului de turism, reguli generale</h3>
              <p>
                2.1 213/1996. (XII. 23.) din Decretul guvernamental privind agențiile și intermediarii de turism,
                activitățile sale nu includ organizarea de călătorii prestează servicii de agenție de turism. Cu toate
                acestea, activitățile CLICKnCRUISE pot include prestarea de servicii disponibile pe site-ul web, în
                conformitate cu dispozițiile din altor servicii legate de medierea în materie de călătorii furnizate pe
                site-ul web.
              </p>
              <p>
                2.2 Acești Termeni și condiții nu fac parte din contractul de călătorie, ci doar dintre CLICKnCRUISE și
                utilizatorii Site-ului web, vizitatorii (orice persoană care vizitează Website-ul sau utilizează
                conținutul acestuia, sau orice parte a conținutului acestuia conținutul sau orice parte a acestuia, sau
                utilizează serviciile disponibile pe site-ul web, sau oricare dintre serviciile disponibile pe site-ul
                web, respectiv denumit în continuare în mod colectiv "client/utilizator").
              </p>
              <p>2.3. Limba contractului dintre CLICKnCRUISE și Pasager este limba maghiară.</p>
              <p>
                2.4. CLICKnCRUISE își desfășoară activitatea exclusiv online, prin intermediul rețelei publice de
                internet, și, în consecință, se supune normelor care reglementează serviciile societății informaționale
                se aplică.
              </p>
              <p>2.5. Conținutul site-ului web este public și accesibil oricui.</p>
              <p>
                2.6 de a face o rezervare sau o rezervare sau de a face o declarație de angajament, numai de către
                persoane cu vârsta de peste 18 ani și cu capacitate juridică deplină (fie în nume propriu, fie în numele
                unei societăți sau al oricărei alte persoane sau organizații) reprezentând orice altă persoană sau
                entitate. CLICKnCRUISE nu va fi răspunzătoare pentru astfel de daune sau orice alt dezavantaj, suferit
                de orice persoană ca urmare a oricărei declarații false făcute pe site-ul web cu privire la vârsta sau
                capacitatea pasagerului sau care induce în eroare CLICKnCRUISE în orice alt mod în această privință.
              </p>
              <p>
                2.7. În cazul în care există îndoieli cu privire la conținutul acestor date sau declarații, se va lua la
                cunoștință de către Sistemul CLICKnCRUISE se acceptă ca fiind corecte.
              </p>
              <p>
                2.8, CLICKnCRUISE consideră că pasagerul este reprezentantul legal al celorlalți pasageri. Pasagerul va
                fi singurul responsabil pentru orice daune sau orice alt dezavantaj/dezavantaj juridic cauzat de
                pasagerul nu a fost autorizat să reprezinte ceilalți pasageri, sau furnizează informații incorecte.
              </p>
              <p>
                2.9 CLICKnCRUISE nu verifică și nu verifică conținutul informațiilor furnizate de Utilizator. Doar
                Pasagerul este responsabil pentru utilizarea sau neutilizarea informațiilor furnizate de CLICKnCRUISE
                sau terțe părți, care rezultă din utilizarea site-ului web sau din utilizarea de către Pasagerul
                furnizează informații neadevărate sau face în alt mod declarații neadevărate.
              </p>
              <p>
                2.10 CLICKnCRUISE poate obține consimțământul Utilizatorului, în forma și conținutul prevăzute de
                legislația în vigoare, pentru utilizarea Intermediarului de turism pentru a-i comunica acestuia
                publicitate.
              </p>
              <p>
                2.11. Vizitatorul/Utilizatorul recunoaște că nu poate pretinde că informațiile de călătorie de pe
                site-ul web agenției de turism este obligat să îi furnizeze serviciile oferite pe site-ul web, mai ales
                dacă între timp operatorul de turism a retras agenția de turism nu își asumă nicio răspundere pentru
                orice erori sau omisiuni. site-ul web site-ul web creează drepturi și obligații între vizitator sau alte
                părți terțe și agenția de turism și obligații între agenția de turism și orice terță parte. Agenția de
                turism nu își asumă nicio răspundere sau responsabilitate în legătură cu obligațiile agenției de turism
                în legătură cu călătoriile mediate sunt guvernate exclusiv de contractele aferente (de exemplu,
                Contractul de călătorie).
              </p>

              <h3>3. Informații generale privind contractele de călătorie</h3>
              <p>
                3.1. Site-ul contractul de călătorie se încheie întotdeauna între turoperator și persoana care îl
                comandă, adică pasagerul agenția de turism nu este răspunzătoare pentru nicio încălcare a contractului
                de călătorie de către oricare dintre părțile contractante consecințele care decurg din executarea
                defectuoasă a contractului de călătorie de către oricare dintre părțile contractante.
              </p>
              <p>
                3.2 termenii și condițiile, alte documente care conțin norme contractuale și condițiile contractului de
                călătorie contractului (în special legea privind contractele de servicii de călătorie, în special legea
                privind contractele de călătorie) 472/2017 (XII. 28.) privind contractele de servicii de călătorie, în
                special contractele privind pachetele de servicii de călătorie și pachetele de servicii de călătorie Și
                Legea V din 2013 privind Codul civil.
              </p>
              <p>
                3.3 documentele furnizate de operatorii de turism le pun la dispoziția pasagerilor pe site-ul web, fără
                nicio modificare.
              </p>
              <p>
                În consecință, agenția de turism nu este răspunzătoare pentru erorile sau omisiunile din oricare dintre
                aceste documente conținutul oricăruia dintre aceste documente, în totalitate sau parțial, este fals sau
                încalcă orice lege conținutul. Călătorul recunoaște că, în cazul unor daune sau al oricărui alt
                prejudiciu care rezultă din acestea, acesta este răspunzător pentru consecințele acestora direct
                împotriva operatorului de turism.
              </p>
              <p>
                3.4. Călătorul recunoaște, de asemenea, că CLICKnCRUISE poate utiliza site-ul web pentru a furniza
                informații despre date, imagini, videoclipuri etc., care i-au fost furnizate de către operatorii de
                turism, în special în ceea ce privește date - și, în consecință, nu își asumă nicio răspundere pentru
                aceste date (inclusiv, fără a se limita la datele despre cabină) în special, dar fără a se limita la,
                prețul sau orice alte costuri ale călătoriei sau descrierea călătoriei nu este în totalitate sau parțial
                exactă.
              </p>
              <p>
                3.5 prețul sau alte costuri ale călătoriei sau chiar disponibilitatea călătoriei în cauză sunt oferite
                doar în scop informativ și pot face obiectul unor modificări în consecință. Datele finale vor fi
                furnizate de către operatorul de turism la momentul rezervării confirmării rezervării.
              </p>

              <h3>4. Procedura de rezervare</h3>
              <p>
                4.1 cunoașterea și acceptarea Regulamentului. Utilizarea serviciului constituie acceptarea acestor
                Termeni și condiții.
              </p>
              <p>
                Prin efectuarea unei rezervări pe site-ul web, fiecare Pasager declară că acceptă aceste Reguli, că este
                conștient de de procedura de rezervare. Termenii și condițiile generale ale operatorului de turism sunt
                la dispoziția Pasagerului confirmarea trimisă Călătorului, precum și la adresa www.clickncruise.hu
                făcând clic pe linkul de pe site-ul web.
              </p>
              <p>4.3. Completarea și transmiterea formularului de rezervare nu constituie un contract de călătorie. </p>

              <p>
                4.4. Contractul de călătorie este un contract între călător și operatorul de turism pentru rezervarea de
                către acesta confirmare trimisă de agenția de turism către operatorul de turism, în conformitate cu
                termenii și condițiile stabilite în scris în această confirmare CLICKnCRUISE
              </p>
              <p>
                <ul>
                  <li>
                    Pasagerul poate utiliza "Căutătorul de călătorii" disponibil pe site-ul web al agenției de turism
                    pentru a căuta oferte de călătorie (prin setarea diferitelor criterii de filtrare) și apoi
                    selectează o apoi să selecteze o ofertă și să aleagă între diferite date de plecare.
                  </li>
                  <li>
                    Următorul pas este selectarea "Tipului de cabină" (interior, fereastră, balcon, apartament, studio
                    solo) - nu răspundem de amplasarea cabinei la bord, în funcție de disponibilitate va fi alocată în
                    funcție de disponibilitate.
                  </li>
                  <li>
                    În continuare, introduceți "Detaliile pasagerului": nume, prenume, naționalitate, sex, data
                    nașterii, numărul de telefon, adresa de e-mail, care vor fi transmise companiei de croazieră în
                    momentul efectuării rezervării aceste informații vor fi transmise companiei de navigație pentru a
                    finaliza rezervarea și a încheia contractul de călătorie. Pentru participarea copiilor la călătorie
                    este posibil, caz în care sunt necesare adresa de e-mail și numărul de telefon al părintelui.
                  </li>
                  <li>Acestea sunt urmate de "Detalii de facturare": nume, prenume, țară, oraș, adresă, cod poștal.</li>
                  <li>
                    Aceasta este urmată de "acceptarea declarațiilor legale" (de exemplu, CLICKnCRUISE GTC, propriile
                    declarații ale companiei de transport maritim) T&amp;C ale companiei de feribot, Politica de
                    confidențialitate, care este obligatorie pentru a continua.
                  </li>
                  <li>
                    Ultimul pas este plata prin intermediul "integrării Stripe", unde pasagerul poate alege metoda de
                    plată (card de credit, Google Pay). Prețurile afișate sunt orientative și pot fi modificate fără
                    notificare prealabilă tarifele sunt preluate din MNB API, plata se face în euro de pe cardul de
                    credit al Pasagerului pe cardul de credit al Pasagerului la rata de vânzare a băncii Pasagerului. La
                    momentul rezervării, se va achita avansul sau, în funcție de data rezervării, suma totală a sumă va
                    fi dedusă. Vă rugăm să rețineți că, în timpul rezervării, "Rezumatul ofertei" programul de plată,
                    care poate varia de la un operator de turism la altul, și suma corespunzătoare este disponibilă în
                    cont la momentul rezervării. În cazul în care o sumă nu este inclusă în CLICKnCRUISE nu poate fi
                    retrasă până la data scadentă din cauza unei erori care nu se află sub controlul CLICKnCRUISE, o
                    singură CLICKnCRUISE va trimite o notificare prin e-mail pasagerului și, în cazul unei a doua
                    deduceri nereușite, rezervarea va fi anulată rezervarea va fi anulată cu consecințele legale
                    prevăzute în Termenii și Condițiile Generale ale operatorului de turism în cauză.
                  </li>
                  <li>Prin selectarea metodei de plată, Pasagerul și-a confirmat cererea de rezervare.</li>
                  <li>
                    Pasagerul va primi un răspuns automat prin e-mail la adresa de e-mail furnizată, confirmând că
                    cererea de rezervare a fost trimisă.
                  </li>
                  <li>
                    CLICKnCRUISE va începe apoi procesarea cererii Pasagerului (în timpul orelor de lucru): în timpul
                    acestui proces verifică capacitatea disponibilă, statutul rezervării, taxa de participare, alte
                    informații alte condiții.
                  </li>
                  <li>
                    Dacă, din orice motiv, cererea de rezervare nu poate fi satisfăcută (rezervare simultană
                    indisponibilitate de locuri din cauza unor rezervări simultane, eroare tehnică, modificare
                    semnificativă a prețului etc.), rezervarea CLICKnCRUISE va informa Pasagerul prin e-mail și va
                    trimite o ofertă alternativă, dacă este posibil
                  </li>
                  <li>
                    În cazul în care avansul (sau întreaga sumă în cazul plății integrale) este creditat la (prin
                    debitarea cardului furnizat în timpul procesului de rezervare), atunci (și numai în cazul în care
                    CLICKnCRUISE va finaliza cererea de rezervare cu agentul de turism relevant și va trimite chitanță
                    de plată în avans către pasager.
                  </li>
                  <li>
                    CLICKnCRUISE va trimite agentului de turism confirmare de la operatorul de turism și trimite prin
                    e-mail confirmarea rezervării deja acceptate electronic și și contractul de călătorie care trebuie
                    să fie tipărit și semnat de Pasager și returnat, iar Pasagerul va fi informat cu privire la data la
                    care orice restanțe de plată vor fi deduse din de pe cardul de credit furnizat.
                  </li>
                </ul>
              </p>

              <p>
                4.5 în cazul în care clientul dorește să își modifice rezervarea, acesta poate contacta agenția de
                turism prin e-mail, modificarea rezervării este posibilă în conformitate cu regulile operatorului de
                turism!
              </p>
              <p>4.6 la adresa de e-mail furnizată.</p>
              <p>
                4.7. În fereastra "Rezumatul ofertei", Pasagerul poate verifica detaliile călătoriei sale, taxa de
                participare detaliile și suma totală. În cazul în care întâmpină erori sau discrepanțe, poate contacta
                CLICKnCRUSE prin e-mail sau prin "chat".
              </p>
              <p>
                4.8 pasagerul este responsabil pentru acuratețea și corectitudinea datelor furnizate de acesta și pentru
                orice consecințe juridice care rezultă din datele incorecte, cum ar fi CLICKnCRUISE nu este
                răspunzătoare pentru niciun fel de daune, în special pentru costurile suplimentare suportate ca urmare a
                unei modificări.
              </p>
              <p>4.9 confirmare, sau confirmarea și plata, călătoria rezervată poate rămâne fără bilete CLICKnCRUISE</p>
              <p>
                4.10 operatorul de turism/CLICKnCRUISE face o nouă ofertă pasagerului, pe care acesta trebuie să o
                accepte separat și numai numai atunci se poate încheia un contract de călătorie în condițiile
                modificate.
              </p>
              <p>
                În cazul în care numărul de locuri se epuizează între confirmare și plată, CLICKnCRUISE va informa
                imediat pasagerul că locurile sunt ocupate, iar contractul cu plata va fi reziliat. A CLICKnCRUISE nu va
                fi răspunzătoare pentru daunele rezultate în urma acestei rezilieri. A CLICKnCRUISE va acorda asistență
                în cazul rezilierii contractului în perioada dintre confirmare și plată să găsească o altă modalitate
                sau să ramburseze suma plătită fără nicio taxă de gestiune. Mergeți la formularul de rezervare online
                este potrivit pentru calcularea efectivă a tuturor elementelor taxei de călătorie, dar pot exista
                diferențe între prețul/suma primit(ă) pe formularul de rezervare și prețul/suma afișat(ă) pe confirmare,
                ambele ambele sensuri. În acest caz, se va aplica întotdeauna suma indicată pe confirmare.
              </p>

              <h3>5. Tratarea reclamațiilor</h3>
              <p>
                5.1 condiții, adică în cazul oricărei reclamații, călătorul poate depune o reclamație direct la
                CLICKnCRUISE pentru asistență în ceea ce privește obstacolele și circumstanțele care trebuie depășite.
              </p>

              <h3>6. Drepturi de autor, drepturi conexe și alte drepturi în legătură cu acest site web</h3>
              <p>
                6.1. Pasagerul recunoaște că Website-ul este protejat de drepturile de autor ale agenției de turism.
                Întregul site web conținut este proprietatea agenției de turism sau a persoanei care acordă dreptul de a
                utiliza anumite elemente ale conținutului 2. Conținutul site-ului web este proprietatea intelectuală a
                agenției de turism sau a altor persoane juridice.
              </p>

              <h3>7. Dispoziții privind protecția datelor</h3>
              <p>
                Pentru o descriere a activităților de gestionare a datelor agenției de turism, vă rugăm să consultați
                "Politica de confidențialitate" a agenției de turism declarația de confidențialitate", disponibilă la
                adresa www.clickncruise.hu/adatvedelmi-nyilatkozat Prin utilizarea site-ului web, călătorul recunoaște
                că a înțeles declarația de confidențialitate, că acceptă termenii și condițiile prevăzute în aceasta și
                că este obligat să le respecte CLICKnCRUISE
              </p>

              <h3>8. Dispoziții finale</h3>
              <p>
                8.1 pasagerul (consumatorul) are dreptul de a se adresa Camerei de Comerț și Industrie de la locul său
                de reședință se poate adresa unui organism de conciliere organizat de Camera de Comerț și Industrie de
                la locul de reședință al agenției de voiaj cu CLICKnCRUISE. În cazul în care litigiul dintre părți este
                soluționat pe cale amiabilă în cazul în care litigiul nu poate fi soluționat pe cale amiabilă,
                consumatorii pot, în cazul unei încălcări a normelor de protecție a consumatorilor, să aibă dreptul la
                rambursarea sumei de de la 1 ianuarie 2017, plângerile pot fi depuse în primă instanță la birourile
                districtuale competente.
              </p>
              <p>8.2 unilateral, în orice moment și fără notificare prealabilă.</p>
              <p>
                8.3 sau invalidă, în totalitate sau parțial, ca urmare a unei modificări legislative sau ca urmare a
                unei dispozițiilor legale, celelalte părți ale acestor Termeni și condiții generale nu vor fi afectate.
              </p>
            </SectionBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
TermsAndConditionsPageRO.displayName = 'TermsAndConditionsPageRO';
