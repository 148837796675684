import { useGrowthBook } from '@growthbook/growthbook-react';
import { useJitsu } from '@jitsu/react';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';
import './tracking-with-consent.scss';

export const TrackingWithConsent: React.FC<{}> = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const { trackPageView } = useJitsu();
  const growthbook = useGrowthBook();
  const { getAnonymousId } = useTracking();

  useEffect(() => {
    if (growthbook) {
      const isBetaParam = searchParams.get('beta');
      if (isBetaParam === 'true' || isBetaParam === 'false') {
        localStorage.setItem('beta', isBetaParam);
      }

      growthbook.loadFeatures().then(() => {
        growthbook.setAttributes({
          user_anonymous_id: getAnonymousId(),
          market: __CONFIG__.market,
          beta: localStorage.getItem('beta') === 'true'
        });
      });
    }

    const listIdParam = searchParams.get('list_id');
    if (listIdParam === '' || listIdParam) {
      localStorage.setItem('list_id', listIdParam);
    }

    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    trackPageView();
  }, [initialized, location]);

  return <></>;
};
