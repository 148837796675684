import { Box, Grid, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { ColorButton } from '../../components/color-button/color-button';
import { CruiseSearchResultListItem } from '../../components/cruise-search-result-list-item/cruise-search-result-list-item';
import { SubscriptionBox } from '../../components/subscription-box/subscription-box';
import { CruiseDetails } from '../../services/search';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BookingGuestState, BookingState, CheckoutStatus, IBookingGuestState } from '../../models/BookingState';
import { useCheckoutApi } from '../../contexts/checkout-api.context';

import HeaderImage from './images/costa-aldea-header.webp';

export const CostaToscanaAldeaLanding: React.FC<{}> = (props) => {
  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const { checkoutApi } = useCheckoutApi();

  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);
  const [offerName, setOfferName] = useState<string>();
  const [offerEmail, setOfferEmail] = useState<string>();
  const [offerPhone, setOfferPhone] = useState<string>();
  const [offerMessage, setOfferMessage] = useState<string>('');

  const offers = [
    {
      title: 'Belső, ablak nélküli kabin €495/fő',
      text: '',
      image: 'costa-inside.webp'
    },
    {
      title: 'Ablakos kabin €565/fő',
      text: '',
      image: 'costa-oceanview.webp'
    },
    {
      title: 'Balkonos kabin €595/fő',
      text: 'Az árak 2 fő/kabin esetén érvényesek. Minden kabin foglalható franciaágyas és 2 db egyszemélyes ággyal is. Egyágyas felárról érdeklődj az ajánlatkérésnél.',
      image: 'costa-balcony.webp'
    },
    {
      title: 'Mit tartalmaz az ár?',
      text: 'Szállást a választott kabinkategóriában. Teljes ellátást a hajón: minden étkezést, a büfében kávét, teát, vizet és gyümölcsleveket automatából, valamint a kikötői illetéket, a kötelező borravalót. Edzőterem, jacuzzi- és medencehasználatot a hajó fedélzetén. Szórakoztató műsorok, bulik és színházi előadásokat a hajón.',
      text2: 'Az ár nem tartalmazza a repülőjegyet, a kikötőbe jutás költségét, alkoholos italokat és üdítőket, minibárt, fakultatív kirándulásokat.',
      image: 'costa02.webp'
    },
    {
      title: 'A Costa Toscana,',
      text: 'a Costa legújabb és legmodernebb hajója tágas szabadtéri részein élvezheted a napsütést, a jó időt és a tengert. Nemzetközi művészek előadásai, minden este szórakoztató műsorok, valamint medencék és csúszdák, korszerű spa és edzőterem vár a fedélzeten.',
      image: 'costa03.webp'
    }
  ];

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const handleGuestFieldChange = (event: any) => {
    const fieldId = event.target.id;
    const value = event.target.value;
    switch (fieldId) {
      case 'field-name':
        setOfferName(value);
        break;
      case 'field-email':
        setOfferEmail(value);
        break;
      case 'field-phone':
        setOfferPhone(value);
        break;
      case 'field-message':
        setOfferMessage(value);
        break;
    }
  };

  const handleSubmit = () => {
    const state = new BookingState(null);
    state.bookingId = 'f3d183ad-070d-47d9-8a01-5053b6b36ac0';

    const guest: IBookingGuestState = {
      firstName: offerName || '',
      email: offerEmail || '',
      phone: offerPhone || '',
      type: 'adult'
    };
    state.guestsState.guestList.push(new BookingGuestState(guest));
    state.guestsState.notes = `COSTA TOSCANA ALDEA - ${offerMessage}`;
    state.source = 'callback';
    
    checkoutApi.updateBooking(
      state.toUpdateBookingPayload(CheckoutStatus.GUESTS_PROVIDED)
    );

    handleDialogClose();
  };

  const OfferButton = () => {
    return (
      <>
        <Box textAlign="center" style={{ margin: '40px 0' }}>
          <ColorButton
            label="Ajánlatot kérek!"
            onClick={handleDialogOpen}
            style={{ width: '300px', textTransform: 'uppercase' }}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Box
        paddingLeft={!isLayoutMedium ? 10 : 0}
        paddingRight={!isLayoutMedium ? 10 : 0}
        marginTop={isLayoutMedium ? -1 : 0}
        style={{ borderTopRightRadius: '35px', borderTopLeftRadius: '35px', overflow: isLayoutMedium ? 'hidden' : '' }}
      >
        <Stack spacing={isLayoutMedium ? 4 : 8}>
          <Box>
            <img src={HeaderImage} alt="header" style={{ width: '100%', borderRadius: 20 }} />
          </Box>
          <Box style={{ margin: '0 16px' }}>
            <Typography variant="h2" style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 12 }}>
              Mindig is ki akartad próbálni a hajózást, de nem mertél nekivágni egyedül?
            </Typography>
            <Typography variant="h3" style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 20 }}>
              Szeretnél egy jót bulizni a barátokkal? Szeretnéd bebarangolni Róma, Marseille és Barcelona városait 4 nap alatt, miközben kényelmesen utazol?
            </Typography>
            <Typography
              variant="h3"
              style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 20, fontWeight: 'normal' }}
            >
              Dátum: 2024 Május 24 - 27 (Péntek - Hétfő)
              <br />
              Útvonal: Róma - Savona - Marseille - Barcelona
            </Typography>
            <Typography
              variant="h3"
              style={{ textAlign: isLayoutMedium ? 'left' : 'center', marginTop: 20, fontWeight: 'normal' }}
            >
              Lineup: Dominik Gehringer (CZ), Denes Toth (SK), EKG (SK), Tysker (SK), Mvtthew (SK)
            </Typography>
            <video width="100%" controls muted autoPlay loop style={{borderRadius: 20, marginTop: 40}}>
              <source src="https://clickncruise.hu/static/images/cruiselines/COSTA/aldea-sea-experience-30sec.mp4" type="video/mp4" />
            </video>
            <OfferButton />
          </Box>
          {offers.map((offer, index) => (
            <Grid
              key={index}
              container
              columnSpacing={isLayoutMedium ? 2 : 7}
              flexDirection={index % 2 !== 0 ? 'row-reverse' : 'row'}
              style={{ width: isLayoutMedium ? 'calc(100% - 16px)' : 'calc(100% - 56px)' }}
            >
              <Grid item xs={12} md={6}>
                <img src={`/landings/${offer.image}`} alt="offer" width={'100%'} style={{borderRadius: 20}} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack textAlign="left">
                  <Typography variant="h3" style={{ fontSize: 22, fontWeight: 600, marginTop: 2 }}>
                    {offer.title}
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: 16, fontWeight: 400, marginTop: 2 }}>
                    {offer.text}
                    {offer.text2 && <><br/><br/>{offer.text2}</>}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          ))}
          <Typography
            variant="h1"
            style={{ textAlign: 'center', fontSize: 36, fontWeight: 600, marginTop: isLayoutMedium ? '32px' : '64px' }}
          >
            Foglalás
          </Typography>
          <OfferButton />
          
          <Dialog open={isDialogOpened} onClose={handleDialogClose} fullScreen={isLayoutMedium}>
            <DialogTitle>
              Ajánlatot kérek
              <IconButton
                aria-label="Bezar"
                onClick={handleDialogClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Stack spacing={3} padding={{ xs: 1, md: 8 }}>
                  <TextField
                    id="field-name"
                    label={null}
                    variant="outlined"
                    placeholder="Név"
                    onChange={handleGuestFieldChange}
                  />
                  <TextField
                    id="field-email"
                    label={null}
                    variant="outlined"
                    placeholder="E-mail cím"
                    onChange={handleGuestFieldChange}
                  />
                  <TextField
                    id="field-phone"
                    label={null}
                    variant="outlined"
                    placeholder="Telefonszám"
                    onChange={handleGuestFieldChange}
                  />
                  <TextField
                    id="field-message"
                    label={null}
                    variant="outlined"
                    placeholder="Üzenet"
                    multiline
                    minRows={2}
                    maxRows={4}
                    onChange={handleGuestFieldChange}
                  />
                  <ColorButton label="Elküldöm!" onClick={handleSubmit} />
                </Stack>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Grid item>
            <SubscriptionBox />
          </Grid>
        </Stack>
      </Box>
    </>
  );
};
