import { useTheme } from '@mui/material/styles';
import { Grid, Box, Stack, useMediaQuery } from '@mui/material';
import { FilterPanel } from '../../components/filter-panel/filter-panel';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { useNavigate } from 'react-router-dom';

export type FilterPageProps = {
  searchFilters: CruiseSearchFilters,
  updateSearchFilters: (f: CruiseSearchFilters) => void,
  scrollToTop: () => void
}

export const FilterPage: React.FC<FilterPageProps> = (props) => {

  // Theme
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  // Navigation
  const navigate = useNavigate();

  // Methods
  const searchClicked = () => {
    props.scrollToTop();
    navigate({
      pathname: `/search/${props.searchFilters.toUrlSearchParams()}`,
    });
  }


  // Components

  const FilterGrid = () => {
    return (
      <Grid item xs={12} >
        <FilterPanel
          searchFilters={props.searchFilters}
          updateSearchFilters={props.updateSearchFilters}
          onSearch={searchClicked}
          searchOnSelect={!isLayoutSmall}
        />
      </Grid>
    );
  }

  return <>
    <Stack spacing={8}>
      <Box sx={{ flexGrow: 1, paddingTop: '20px' }} padding={{ xs: '0 20px', md: '0 100px' }}>
        <Grid container width="100%" justifyContent={{ xs: "center", md: 'flex-start' }} spacing={{ xs: 2, sm: 2, md: 6 }}>
          <FilterGrid />
        </Grid>
      </Box>
    </Stack>
  </>
}
