
import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';

export const CompanyDetailsPageRO: React.FC<{  }> = (props) => {

  const {t} = useTranslation('common');

  return <>
    <Box>
      <Stack spacing={8} >
        <SectionBox boxParams={{style: {padding: "0 100px"}}}>
          <SectionTitle title={t('company-details.title')} />
          <Box marginTop={3} marginBottom={3}>
            CLICKnCRUISE Kft.<br/>
            Sediu: 9141, Ikreny Ret koz 2. B.<br/>
            Email: ahoy@clickncruise.ro<br/>
            Numărul de înregistrare al companiei: 08-09-033950<br/>
            Număr de identificare fiscală: 27547111-1-08<br/>
            Număr de înregistrare: U-001970<br/>
            Detalii furnizor de găzduire: Amazon Web Services<br/>
          </Box>
        </SectionBox>
      </Stack>
    </Box>
  </>
}
CompanyDetailsPageRO.displayName = 'CompanyDetailsPageRO';