import Button, { ButtonProps } from '@mui/material/Button';

export interface ColorButtonProps extends ButtonProps {
  label?: string;
}

export const ColorButton: React.FC<ColorButtonProps> = (props) => {
  
  const ButtonElement = () => {
    if (props.label) {
      return (
        <Button {...props} sx={{...props.sx}} >
          {props.label}
        </Button>
      );
    }
    
    // If no label specified
    const p = {...props};
    delete p.startIcon;
    
    return (
      <Button {...p} >
        {props.startIcon}
      </Button>
    );
  }

  return <>
    <ButtonElement />
  </>
}

ColorButton.defaultProps = {
  color: 'primary',
  variant: 'contained'
}