import { Box } from '@mui/material';
import './labels.scss';

export const AdultsOnlyLabel: React.FC<{}> = (props) => {
  return (
    <Box className="label-box" style={{ background: 'linear-gradient(163deg,#c00 0%,#c00 60%,#3c1053 100%)' }}>
      Adults only
    </Box>
  );
};
