import './input-multiselect.scss';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { BrowserStorage } from '../../models/BrowserStorage';

export type SelectOption = {
  value: string,
  label?: string
}

export const MultiSelect: React.FC<{ id: string, label: string, placeHolder?: string, options: SelectOption[], onChanged: (n: string, v: SelectOption[]) => void, values: string[], translationKeyPrefix?: string }> = (props) => {
  const {t} = useTranslation('common');
  const translationKeyPrefix = props.translationKeyPrefix || `search.filters.${props.id}.options`;

  const openedId = BrowserStorage.getInstance().getItem('cnc-f-open');
  const [isOpen, setOpen] = useState<boolean>(openedId === props.id);
  const [selected, setSelected] = useState<SelectOption[]>(props.options.filter((e) => props.values.includes(e.value)));
  const [defaults] = useState<SelectOption[]>([...props.options].filter((e) => props.values.includes(e.value)));

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: SelectOption[]) : void => {
    setSelected(value);
    props.onChanged(props.id, value);
  };

  const onClose = (event: React.SyntheticEvent<Element, Event>) : void => {
    setOpen(false);
    BrowserStorage.getInstance().setItem('cnc-f-open', '');
  };

  const onOpen = (event: React.SyntheticEvent) => {
    setOpen(true);
    BrowserStorage.getInstance().setItem('cnc-f-open', props.id);
  };

  useEffect(() => {
    if (openedId === props.id) {
      document.getElementById(`filter-${props.id}`)?.focus();
    }
  });

  return <>
    <FormControl className='input-multiselect' sx={{ m: 1}}>
      <Typography variant="h6" style={{marginBottom: '4px'}}>{props.label}</Typography>
      <Autocomplete
        multiple
        disablePortal
        disableCloseOnSelect
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onBlur={onClose}
        id={`filter-${props.id}`}
        options={props.options}
        defaultValue={defaults}
        getOptionLabel={(option) => option.label ? option.label : t(`${translationKeyPrefix}.${option.value}`, option.value)}
        onChange={handleChange}
        renderInput={(params) => {
          return (
            <>
              <TextField
                {...params}
                variant="outlined"
                disabled={false}
                placeholder={selected.length === 0 && props.placeHolder ? t(props.placeHolder) : ''}
              />
            </>
          )
        }}
        renderOption={(optionProps, option, { selected }) => (
          <li {...optionProps}>
            <Grid container>
              <Grid item xs={11}>
                {option.label ? option.label : t(`${translationKeyPrefix}.${option.value}`, option.value)}
              </Grid>
              <Grid item xs={1}>
                {(selected ? <CheckIcon htmlColor='#576B75' /> : <></>)}
              </Grid>
            </Grid>
          </li>
        )}
      />
      
    </FormControl>
  </>
}
