import './input-datepicker.scss';
import { useState } from 'react';
import { useTheme, useMediaQuery, Typography, FormControl, TextField } from '@mui/material';

import 'react-dates/initialize';
import {DateRangePicker, FocusedInputShape } from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { DateRangeFilter } from '../../models/CruiseSearchFilters';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useTranslation } from 'react-i18next';
import huLocale from 'date-fns/locale/hu';
import enLocale from 'date-fns/locale/en-GB';
import { Controller, useFormContext } from 'react-hook-form';
import { ValidationRulesType } from '../input-text/input-text';

const localeMap: any = {
  en: enLocale,
  'en-uk': enLocale,
  hu: huLocale
}

const maskMap: any = {
  en: '__/__/____',
  'en-uk': '__/__/____',
  'en-gb': '__/__/____',
  hu: '____.__.__'
};

export const DatePickerInputRange: React.FC<{ id: string, label: string, onChanged: (f: Date|null, t: Date|null) => void, values: DateRangeFilter, showClearDates?: boolean, onClose?: () => void }> = (props) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const [startDate, setStartDate] = useState<moment.Moment | null>(props.values.from ? moment(new Date(props.values.from)) : null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(props.values.to ? moment(new Date(props.values.to)) : null);

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape|null>(null);
  
  const rangeChanged = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    setStartDate(startDate);
    setEndDate(endDate);

    props.onChanged(
      startDate ? startDate.toDate() : null,
      endDate ? endDate.toDate() : null
    );
  };

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  }

  return <>
    <FormControl className='input-datepicker' sx={{ m: 1}}>
      <Typography variant="h6" style={{marginBottom: '4px'}}>{props.label}</Typography>
      <DateRangePicker
        startDate={startDate}
        startDateId={`date-input-start-${props.id}`}
        endDate={endDate}
        endDateId={`date-input-end-${props.id}`}
        onDatesChange={({ startDate, endDate }) => rangeChanged(startDate, endDate)}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        numberOfMonths={isLayoutSmall ? 1 : 2}
        showClearDates={props.showClearDates}
        daySize={40}
        startDatePlaceholderText={t('search.filters.when.placeholderStartDate')}
        endDatePlaceholderText={t('search.filters.when.placeholderEndDate')}
        hideKeyboardShortcutsPanel={true}
        onClose={onClose}
        readOnly={isLayoutSmall}
        screenReaderInputMessage={t('search.filters.when.label')}
      />
    </FormControl>
  </>
}

DatePickerInputRange.defaultProps = {
  showClearDates: true
}

export const DatePickerInputSingle: React.FC<{ id: string, label: string, onChanged: (id: string, d: Date) => void, value?: Date, format: string, onValidate?: (r: boolean) => void, validationRules?: ValidationRulesType }> = (props) => {
  const {t, i18n} = useTranslation('common');

  // Form
  const { control, formState: { errors } } = useFormContext();

  const elementId = props.id;
  const [startDate, setStartDate] = useState<Date|null>(props.value ? props.value : null);
  const [isError, setError] = useState<boolean>(false);

  const dateChanged = (startDate: Date|null) => {
    if (startDate !== null) {
      setStartDate(startDate);

      props.onChanged(
        elementId,
        startDate
      );
    }
  }

  const handleError = (reason: any) => {
    let result = false;

    if (reason !== null && startDate !== null) {
      result = true;
    }
    setError(result);
    if (props.onValidate) {
      props.onValidate(result);
    }
  }

  const getErrors = () => {
    let e = '';
    
    if (isError) {
      e = t('form.invalid-date-text', {format: props.format});
    }
    if (errors && props.id && errors[props.id]) {
      e = errors[props.id].message;
    }
    
    return e;
  }

  return <>
    <FormControl className='input-datepicker' sx={{ m: 1}}>
      <Typography variant="h6" style={{marginBottom: '4px'}}>{props.label}</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language.toLowerCase()]}>
      <Controller
        name={props.id}
        control={control}
        defaultValue={startDate}
        rules={props.validationRules}
        render={({
          field: { onChange, value },
          fieldState: { error, invalid }
        }) => (
          <DatePicker
            openTo="year"
            disableFuture
            views={['year', 'month', 'day']}
            value={startDate}
            onError={handleError}
            mask={maskMap[i18n.language.toLowerCase()]}
            inputFormat={props.format}
            onChange={(sd: Date|null) => { onChange(sd); dateChanged(sd); }}
            renderInput={(params) =>
              <TextField
                {...params}
                id={props.id}
                variant="outlined"
                error={Boolean(getErrors())}
                helperText={getErrors()}
                autoComplete="bday"
              />}
          />
          
        )}
      />
      </LocalizationProvider>
    </FormControl>
  </>
}
