import { Box, useTheme } from '@mui/material';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './labels.scss';
import { useTracking } from '../../hooks/useTracking';

const AVAILABLE_COUNT_WARNING_THRESHOLD = 4;

export const AvailabilityLabel: React.FC<{
  availableCount: number;
  refreshTime: Date;
}> = (props) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const [isAvailabilityLabelOpen, setIsAvailabilityLabelOpen] = useState(false);
  const handleAvailabilityLabelClick = useCallback(
    () =>
      setIsAvailabilityLabelOpen((isOpen) => {
        trackEvent('availability_label_clicked');
        return !isOpen;
      }),
    []
  );

  if (props.availableCount > AVAILABLE_COUNT_WARNING_THRESHOLD) {
    return <></>;
  }

  return (
    <Box
      className="label-box"
      style={{
        background: theme.palette.warning.main,
        cursor: 'pointer',
        flexDirection: 'column',
        maxHeight: '40px',
        maxWidth: '180px'
      }}
      onClick={handleAvailabilityLabelClick}
    >
      <div
        style={{
          minHeight: '40px',
          display: 'flex',
          alignItems: 'center',
          transform: isAvailabilityLabelOpen ? 'translateY(-100%)' : 'translateY(0)',
          opacity: isAvailabilityLabelOpen ? 0 : 1,
          transition: 'transform 0.5s ease-out, opacity 0.5s ease-out'
        }}
      >
        {t('search.availability.short')}
      </div>
      <div
        style={{
          fontWeight: 300,
          fontSize: '12px',
          lineHeight: '14px',
          minHeight: '40px',
          display: 'flex',
          alignItems: 'center',
          transform: isAvailabilityLabelOpen ? 'translateY(-100%)' : 'translateY(0)',
          opacity: isAvailabilityLabelOpen ? 1 : 0,
          transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
          overflow: 'hidden'
        }}
      >
        {t('search.availability.long', {
          availableCount: props.availableCount,
          refreshTime: moment(props.refreshTime).fromNow()
        })}
      </div>
    </Box>
  );
};
