import { Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { PredefinedSearchButton } from '../predefined-search-button/predefined-search-button';
import { SectionBox } from '../section-box/section-box';
import { SectionTitle } from '../section-title/section-title';
import './home-page-quick-search-buttons.scss';
import aftImage from './images/aft.webp';
import beachImage from './images/beach.webp';
import croatiaImage from './images/croatia.webp';
import familyImage from './images/family.webp';
import lighthouseImage from './images/lighthouse.webp';
import santoriniImage from './images/santorini.webp';
import santorini2Image from './images/santorini2.webp';
import spaImage from './images/spa.webp';
import summerImage from './images/summer.webp';
import worldeuropaImage from './images/worldeuropa.webp';
import xmasImage from './images/xmas.webp';
import fantasiaShip from './images/ship-fantasia.webp';
import operaShip from './images/ship-opera.webp';
import worldeuropaShip from './images/ship-world-europa.webp';
import toscanaShip from './images/ship-toscana.webp';

import { useTracking } from '../../hooks/useTracking';

const TILE_IMAGES: Record<string, string> = {
  aftImage,
  santoriniImage,
  summerImage,
  santorini2Image,
  lighthouseImage,
  xmasImage,
  beachImage,
  spaImage,
  croatiaImage,
  worldeuropaImage,
  familyImage,
  fantasiaShip,
  operaShip,
  worldeuropaShip,
  toscanaShip
};

const TILES = __CONFIG__.quickSearch.home.map((tile) => ({
  filter: new CruiseSearchFilters(tile.key),
  image: TILE_IMAGES[`${tile.image}Image`],
  label: `home.quick-search-button.${tile.key}`,
  name: tile.key
}));

const SHIP_TILES = __CONFIG__.homePageHighlights.ships.map((tile) => ({
  filter: new CruiseSearchFilters(tile.key),
  image: TILE_IMAGES[`${tile.image}Ship`],
  label: `home.quick-search-button.${tile.key}`,
  name: tile.key,
  url: tile.url
}));

export const HomePageQuickSearchButtons: React.FC<{ updateSearchFilters: (f: CruiseSearchFilters) => void }> = (
  props
) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const { trackEvent } = useTracking();

  const navigateToHighlightedShipUrl = (label: string, url: string) => () => {
    trackEvent('homepage-ship-highlight', { label: label });
    window.open(url, '_blank');
  }

  return (
    <>
      <SectionBox>
        <Box className="home-page-quick-search-buttons">
          {SHIP_TILES && SHIP_TILES.length > 0 &&
            <Stack spacing={2} alignItems={`${isLayoutSmall ? 'flex-start' : 'center'}`} marginBottom={'2rem'}>
              <SectionTitle title={t('home.ship-highlight-title')} />
              <Grid
                container
                textAlign={`${isLayoutSmall ? 'left' : 'center'}`}
                columnSpacing={{ xs: 3, sm: 3, md: 6 }}
                rowSpacing={1}
                marginLeft={{ xs: '-24px !important', md: '-48px !important' }}
              >
                {SHIP_TILES.map((tile) => (
                  <Grid item xs={12} sm={6} md={4} key={tile.label}>
                    <PredefinedSearchButton
                      image={tile.image}
                      label={t(tile.label)}
                      name={tile.name}
                      searchFilters={tile.filter}
                      showLabel={false}
                      aspectRatio={'1'}
                      hoverAnimationEnabled={false}
                      updateSearchFilters={props.updateSearchFilters}
                      onButtonClick={navigateToHighlightedShipUrl(tile.name, tile.url)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          }
          <Stack spacing={2} alignItems={`${isLayoutSmall ? 'flex-start' : 'center'}`}>
            <SectionTitle title={t('home.predefined-search-title')} />
            <Grid
              container
              textAlign={`${isLayoutSmall ? 'left' : 'center'}`}
              columnSpacing={{ xs: 3, sm: 3, md: 6 }}
              rowSpacing={1}
              marginLeft={{ xs: '-24px !important', md: '-48px !important' }}
            >
              {TILES.map((tile) => (
                <Grid item xs={12} sm={6} md={4} key={tile.label}>
                  <PredefinedSearchButton
                    image={tile.image}
                    label={t(tile.label)}
                    name={tile.name}
                    searchFilters={tile.filter}
                    showLabel={true}
                    updateSearchFilters={props.updateSearchFilters}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Box>
      </SectionBox>
    </>
  );
};
