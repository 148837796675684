import moment from 'moment';
import { useCallback, useState } from 'react';
import { useCheckoutApi } from '../contexts/checkout-api.context';
import { BookingState } from '../models/BookingState';
import { useTracking } from './useTracking';

export const useDiscount = (bookingState: BookingState) => {
  const { checkoutApi } = useCheckoutApi();
  const { trackEvent } = useTracking();

  const [discountIsLoading, setDiscountIsLoading] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(Object.keys(bookingState.discounts).length > 0);

  const applyDiscounts = useCallback(
    (discounts: string[]) => {
      setDiscountIsLoading(true);

      if (discounts.length === 0) {
        bookingState.discounts = {};
        setHasDiscount(false);
        setDiscountIsLoading(false);
        return;
      }

      checkoutApi
        .applyDiscounts({
          amount: bookingState.calculateTotalPrice(false),
          departureDate: moment(bookingState.departureDate?.fromDate).format('YYYY-MM-DD'),
          cruiseLength: bookingState.bookingDetails?.cruiseLength || 0,
          cruiseline: bookingState.bookingDetails?.cruiseLine || '',
          passengerCount: bookingState.numberOfGuests.getAllCount(),
          discounts
        })
        .then((result) => {
          const successfulDiscounts = result.data as { [key: string]: number };
          bookingState.discounts = successfulDiscounts;
          setHasDiscount(Object.keys(successfulDiscounts).length > 0);
          trackEvent('discount_applied', { discounts: successfulDiscounts });
        })
        .finally(() => setDiscountIsLoading(false));
    },
    [bookingState]
  );

  return {
    applyDiscount: (discount: string) => applyDiscounts(discount ? [discount] : []),
    hasDiscount,
    discountIsLoading
  };
};
