import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { BookingForm } from './booking-form';
import { BookingState } from '../../models/BookingState';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';

export const BookingPage: React.FC<{bookingState: BookingState, cruiseSearchFilters: CruiseSearchFilters, scrollToTop: () => void, updateBooking: (b: BookingState) => void }> = (props: any) => {

  return <>
    <Stack spacing={8} marginBottom={10}>
      <Box sx={{ flexGrow: 1, paddingTop: '20px' }} padding={{xs: '0 20px', md: '0 100px'}}>
        <BookingForm
          bookingState={props.bookingState}
          scrollToTop={props.scrollToTop}
          cruiseSearchFilters={props.cruiseSearchFilters}
          updateBooking={props.updateBooking}
        />
      </Box>
    </Stack>
  </>
}