/* tslint:disable */
/* eslint-disable */
/**
 * ClickNCruise Search API
 * ClickNCruise API
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateTrackerRequest } from '../models';
// @ts-ignore
import { CruiseCabin } from '../models';
// @ts-ignore
import { CruiseDetails } from '../models';
// @ts-ignore
import { CruiseSearchRequest } from '../models';
// @ts-ignore
import { CruiseSearchResponse } from '../models';
// @ts-ignore
import { CruiseService } from '../models';
// @ts-ignore
import { CruiseServicesRequest } from '../models';
/**
 * CruiseSearchApi - axios parameter creator
 * @export
 */
export const CruiseSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTrackerRequest} createTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTracker: async (createTrackerRequest: CreateTrackerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTrackerRequest' is not null or undefined
            assertParamExists('createTracker', 'createTrackerRequest', createTrackerRequest)
            const localVarPath = `/tracker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTrackerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get available cabins with their prices for the given cruise
         * @summary cruiseDetails
         * @param {string} cruiseCode Cruise code
         * @param {string} shipCode Ship identifier
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseCabins: async (cruiseCode: string, shipCode: string, cruiseSearchRequest?: CruiseSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cruiseCode' is not null or undefined
            assertParamExists('getCruiseCabins', 'cruiseCode', cruiseCode)
            // verify required parameter 'shipCode' is not null or undefined
            assertParamExists('getCruiseCabins', 'shipCode', shipCode)
            const localVarPath = `/cruiseCabins/{cruiseCode}/{shipCode}`
                .replace(`{${"cruiseCode"}}`, encodeURIComponent(String(cruiseCode)))
                .replace(`{${"shipCode"}}`, encodeURIComponent(String(shipCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cruiseSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get default cruises
         * @summary getDefaultCruises
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseDefaultSearch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cruise/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single cruises details
         * @summary cruiseDetails
         * @param {string} cruiseGroupId Cruise group id
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseDetails: async (cruiseGroupId: string, cruiseSearchRequest?: CruiseSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cruiseGroupId' is not null or undefined
            assertParamExists('getCruiseDetails', 'cruiseGroupId', cruiseGroupId)
            const localVarPath = `/cruiseDetails/{cruiseGroupId}`
                .replace(`{${"cruiseGroupId"}}`, encodeURIComponent(String(cruiseGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cruiseSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get available services with their prices for the given cruise and suite
         * @param {CruiseServicesRequest} cruiseServicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseServices: async (cruiseServicesRequest: CruiseServicesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cruiseServicesRequest' is not null or undefined
            assertParamExists('getCruiseServices', 'cruiseServicesRequest', cruiseServicesRequest)
            const localVarPath = `/cruiseServices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cruiseServicesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns HTTP 200 if the server considers itself ready to serve requests.
         * @summary Healthcheck endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for cruises
         * @summary getCruises
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCruiseSearch: async (cruiseSearchRequest?: CruiseSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cruise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userTokenHeader required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cruiseSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CruiseSearchApi - functional programming interface
 * @export
 */
export const CruiseSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CruiseSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTrackerRequest} createTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTracker(createTrackerRequest: CreateTrackerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTracker(createTrackerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get available cabins with their prices for the given cruise
         * @summary cruiseDetails
         * @param {string} cruiseCode Cruise code
         * @param {string} shipCode Ship identifier
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCruiseCabins(cruiseCode: string, shipCode: string, cruiseSearchRequest?: CruiseSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CruiseCabin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCruiseCabins(cruiseCode, shipCode, cruiseSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get default cruises
         * @summary getDefaultCruises
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCruiseDefaultSearch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CruiseSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCruiseDefaultSearch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a single cruises details
         * @summary cruiseDetails
         * @param {string} cruiseGroupId Cruise group id
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCruiseDetails(cruiseGroupId: string, cruiseSearchRequest?: CruiseSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CruiseDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCruiseDetails(cruiseGroupId, cruiseSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get available services with their prices for the given cruise and suite
         * @param {CruiseServicesRequest} cruiseServicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCruiseServices(cruiseServicesRequest: CruiseServicesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CruiseService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCruiseServices(cruiseServicesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns HTTP 200 if the server considers itself ready to serve requests.
         * @summary Healthcheck endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search for cruises
         * @summary getCruises
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCruiseSearch(cruiseSearchRequest?: CruiseSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CruiseSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCruiseSearch(cruiseSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CruiseSearchApi - factory interface
 * @export
 */
export const CruiseSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CruiseSearchApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTrackerRequest} createTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTracker(createTrackerRequest: CreateTrackerRequest, options?: any): AxiosPromise<object> {
            return localVarFp.createTracker(createTrackerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get available cabins with their prices for the given cruise
         * @summary cruiseDetails
         * @param {string} cruiseCode Cruise code
         * @param {string} shipCode Ship identifier
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseCabins(cruiseCode: string, shipCode: string, cruiseSearchRequest?: CruiseSearchRequest, options?: any): AxiosPromise<Array<CruiseCabin>> {
            return localVarFp.getCruiseCabins(cruiseCode, shipCode, cruiseSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get default cruises
         * @summary getDefaultCruises
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseDefaultSearch(options?: any): AxiosPromise<CruiseSearchResponse> {
            return localVarFp.getCruiseDefaultSearch(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single cruises details
         * @summary cruiseDetails
         * @param {string} cruiseGroupId Cruise group id
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseDetails(cruiseGroupId: string, cruiseSearchRequest?: CruiseSearchRequest, options?: any): AxiosPromise<CruiseDetails> {
            return localVarFp.getCruiseDetails(cruiseGroupId, cruiseSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get available services with their prices for the given cruise and suite
         * @param {CruiseServicesRequest} cruiseServicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCruiseServices(cruiseServicesRequest: CruiseServicesRequest, options?: any): AxiosPromise<Array<CruiseService>> {
            return localVarFp.getCruiseServices(cruiseServicesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns HTTP 200 if the server considers itself ready to serve requests.
         * @summary Healthcheck endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck(options?: any): AxiosPromise<string> {
            return localVarFp.healthcheck(options).then((request) => request(axios, basePath));
        },
        /**
         * Search for cruises
         * @summary getCruises
         * @param {CruiseSearchRequest} [cruiseSearchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCruiseSearch(cruiseSearchRequest?: CruiseSearchRequest, options?: any): AxiosPromise<CruiseSearchResponse> {
            return localVarFp.postCruiseSearch(cruiseSearchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CruiseSearchApi - object-oriented interface
 * @export
 * @class CruiseSearchApi
 * @extends {BaseAPI}
 */
export class CruiseSearchApi extends BaseAPI {
    /**
     * 
     * @param {CreateTrackerRequest} createTrackerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CruiseSearchApi
     */
    public createTracker(createTrackerRequest: CreateTrackerRequest, options?: AxiosRequestConfig) {
        return CruiseSearchApiFp(this.configuration).createTracker(createTrackerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get available cabins with their prices for the given cruise
     * @summary cruiseDetails
     * @param {string} cruiseCode Cruise code
     * @param {string} shipCode Ship identifier
     * @param {CruiseSearchRequest} [cruiseSearchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CruiseSearchApi
     */
    public getCruiseCabins(cruiseCode: string, shipCode: string, cruiseSearchRequest?: CruiseSearchRequest, options?: AxiosRequestConfig) {
        return CruiseSearchApiFp(this.configuration).getCruiseCabins(cruiseCode, shipCode, cruiseSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get default cruises
     * @summary getDefaultCruises
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CruiseSearchApi
     */
    public getCruiseDefaultSearch(options?: AxiosRequestConfig) {
        return CruiseSearchApiFp(this.configuration).getCruiseDefaultSearch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single cruises details
     * @summary cruiseDetails
     * @param {string} cruiseGroupId Cruise group id
     * @param {CruiseSearchRequest} [cruiseSearchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CruiseSearchApi
     */
    public getCruiseDetails(cruiseGroupId: string, cruiseSearchRequest?: CruiseSearchRequest, options?: AxiosRequestConfig) {
        return CruiseSearchApiFp(this.configuration).getCruiseDetails(cruiseGroupId, cruiseSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get available services with their prices for the given cruise and suite
     * @param {CruiseServicesRequest} cruiseServicesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CruiseSearchApi
     */
    public getCruiseServices(cruiseServicesRequest: CruiseServicesRequest, options?: AxiosRequestConfig) {
        return CruiseSearchApiFp(this.configuration).getCruiseServices(cruiseServicesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns HTTP 200 if the server considers itself ready to serve requests.
     * @summary Healthcheck endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CruiseSearchApi
     */
    public healthcheck(options?: AxiosRequestConfig) {
        return CruiseSearchApiFp(this.configuration).healthcheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search for cruises
     * @summary getCruises
     * @param {CruiseSearchRequest} [cruiseSearchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CruiseSearchApi
     */
    public postCruiseSearch(cruiseSearchRequest?: CruiseSearchRequest, options?: AxiosRequestConfig) {
        return CruiseSearchApiFp(this.configuration).postCruiseSearch(cruiseSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
