import { Box, BoxProps } from '@mui/system';

export const SectionBox: React.FC<{ className?: string, boxParams?: BoxProps }> = (props) => {

  return <>
    <Box {...props.boxParams} className={`${props.className} section-box`} padding={'0 0 0 0px'}>
      {props.children}
    </Box>
  </>
}
