import ReactDOM from 'react-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { JitsuProvider, createClient } from '@jitsu/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';
import i18next from 'i18next';
import moment from 'moment';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import './index.scss';
import theme from './theme';
import common_en from './translations/en/common.json';
import hu_en from './translations/en/hu.json';
import ro_en from './translations/en/ro.json';
import common_hu from './translations/hu/common.json';
import common_ro from './translations/ro/common.json';

Sentry.init({
  dsn: __CONFIG__.logging.sentry.dsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: __CONFIG__.logging.sentry.environment,
  enabled: __CONFIG__.logging.sentry.environment !== 'development',
  ignoreErrors: ['domInteractive']
});

i18next.init({
  interpolation: { escapeValue: false },
  resources: {
    en: {
      common: common_en
    },
    hu: {
      common: common_hu
    },
    ro: {
      common: common_ro
    }
  },
  lng: __CONFIG__.i18n.defaultLanguage
});

if (__CONFIG__.market === 'hu') {
  i18next.addResourceBundle('en', 'common', hu_en, true, true);
}

if (__CONFIG__.market === 'ro') {
  i18next.addResourceBundle('en', 'common', ro_en, true, true);
}

// location based redirect
const redirect = (fromCountry: string, toUrl: string) => {
  axios('https://ipapi.co/json/')
    .then((response: any) => {
      if (response.data?.country_code === fromCountry) {
        window.location.href = toUrl;
      }
    })
    .catch((e) => {});
};

if (__CONFIG__.redirect.fromCountry) {
  redirect(__CONFIG__.redirect.fromCountry, __CONFIG__.redirect.toUrl);
}

export const jitsuClient = createClient({
  tracking_host: __CONFIG__.logging.jitsu.trackingHost,
  key: __CONFIG__.logging.jitsu.key,
  randomize_url: true
});

const growthbook = new GrowthBook({
  apiHost: __CONFIG__.featureFlags.growthBookApiHost,
  clientKey: __CONFIG__.featureFlags.growthBookClientKey,
  enableDevMode: __CONFIG__.featureFlags.growthBookEnableDevMode,
  trackingCallback: (experiment, result) => {
    jitsuClient.track('experiment_assigned', {
      experiment_id: experiment.key,
      variation_id: result.key
    });
  }
});

const authConfig = {
  domain: __CONFIG__.auth0?.domain,
  clientId: __CONFIG__.auth0?.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(__CONFIG__.auth0?.audience ? { audience: __CONFIG__.auth0?.audience } : null)
  }
};

const onRedirectCallback = (appState?: AppState) => {
  if (appState && appState.returnTo) {
    window.location.href = appState.returnTo;
  }
};

moment.locale(i18next.language);

ReactDOM.render(
  <GrowthBookProvider growthbook={growthbook}>
    <JitsuProvider client={jitsuClient}>
      <ThemeProvider theme={theme}>
        <div className="app-container">
          <div className="main-page">
            <CssBaseline />
            <I18nextProvider i18n={i18next} defaultNS="common">
              {authConfig.domain && authConfig.clientId ? (
                <Auth0Provider {...authConfig} cacheLocation="localstorage" onRedirectCallback={onRedirectCallback}>
                  <App />
                </Auth0Provider>
              ) : (
                <App />
              )}
            </I18nextProvider>
          </div>
        </div>
      </ThemeProvider>
    </JitsuProvider>
  </GrowthBookProvider>,
  document.getElementById('root')
);
