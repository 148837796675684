import './cruise-search-result-list-item.scss';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export const CruiseSearchResultListItemSkeleton: React.FC<{  }> = (props) => {
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  return <>
    <Box className="cruise-search-result-list-item-container" style={{border: "1px solid #eee"}}>
      <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} width="calc(100% + 2px)">
        <Box minWidth={isLayoutSmall ? '100%' : '45%'}>
          <Skeleton className="thumbnail-image" animation="wave" variant="rectangular" height='100%' width='100%' sx={{borderRadius: '20px'}} />
        </Box>
        <Box minWidth={isLayoutSmall ? '100%' : '55%'} padding="15px">
          <Stack className="company-info" direction="row" justifyContent="space-between" alignItems="center" >
            <Skeleton animation="wave" variant="rectangular" height='18px' width='60%' sx={{borderRadius: '4px'}} />
            <Box>
              <Skeleton animation="wave" variant="rectangular" height='20px' width='50px' sx={{borderRadius: '4px'}} />
            </Box>
          </Stack>

          <Stack direction="row" justifyContent="space-between" margin="15px 0" >
            <Stack direction="column" spacing={1}>
              <Skeleton animation="wave" variant="rectangular" height='18px' width='150px' sx={{borderRadius: '4px', marginBottom: '6px'}} />
              <Skeleton animation="wave" variant="rectangular" height='18px' width='150px' sx={{borderRadius: '4px', marginBottom: '6px'}} />
            </Stack>
            <Stack direction="column" justifyContent="space-around" textAlign="right" width='35%'>
              <Skeleton animation="wave" variant="rectangular" height='15px' width='110px' sx={{borderRadius: '4px', marginBottom: '6px', marginLeft: 'auto'}} />
              <Skeleton animation="wave" variant="rectangular" height='15px' width='70px' sx={{borderRadius: '4px', marginBottom: '6px', marginLeft: 'auto'}} />
            </Stack>
          </Stack>

          <Box className="ports">
            <Skeleton animation="wave" variant="rectangular" height='15px' width='100%' sx={{borderRadius: '4px', marginBottom: '6px'}} />
            <Skeleton animation="wave" variant="rectangular" height='15px' width='100%' sx={{borderRadius: '4px', marginBottom: '6px'}} />
            <Skeleton animation="wave" variant="rectangular" height='15px' width='100%' sx={{borderRadius: '4px', marginBottom: '6px'}} />
            <Skeleton animation="wave" variant="rectangular" height='15px' width='100%' sx={{borderRadius: '4px'}} />
          </Box>

          <Stack className="price" direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} marginTop='15px'>
            <Skeleton animation="wave" variant="rectangular" height='30px' width='35%' sx={{borderRadius: '4px', marginLeft: 'auto'}} />
            <Skeleton animation="wave" variant="rectangular" height='30px' width='22%' sx={{borderRadius: '4px', marginLeft: 'auto'}} />
          </Stack>

          <Box textAlign="right">
            <Skeleton animation="wave" variant="rectangular" height='43px' width='82px' sx={{borderRadius: '10px',  marginLeft: 'auto', marginTop: '15px'}} />
          </Box>

        </Box>
        
      </Stack>
    </Box>
  </>
}
