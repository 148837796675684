import { createContext, useState, useEffect, useContext } from "react";
import { CruiseSearchApi } from "../services/search";

import { Configuration } from '../services/search/configuration';
import { useAuth0 } from "@auth0/auth0-react";

const SearchApiContext = createContext<{searchApi: CruiseSearchApi}>({
  searchApi: {} as CruiseSearchApi
});

export const useSearchApi = () => useContext(SearchApiContext);

const DEFAULT_CONFIG = __CONFIG__.api.search;

export const SearchApiProvider = ({ children }: any) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [ searchApi, setSearchApi ] = useState<CruiseSearchApi>(new CruiseSearchApi(new Configuration(DEFAULT_CONFIG)));

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        if (token) {
          setSearchApi(new CruiseSearchApi(
            new Configuration({ ...DEFAULT_CONFIG, accessToken: token})
          ));
        }
      });
    }
  }, [isAuthenticated]);

  const value = { searchApi };

  return (
    <SearchApiContext.Provider value={value}>
      {children}
    </SearchApiContext.Provider>
  );
};