import { HomePageQuickSearchButtons } from '../../components/home-page-quick-search-buttons/home-page-quick-search-buttons';
import { SubscriptionBox } from '../../components/subscription-box/subscription-box';
import { Stack, Grid, useMediaQuery, useTheme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ColorButton } from '../../components/color-button/color-button';
import { useNavigate } from 'react-router-dom';
import { SectionBox } from '../../components/section-box/section-box';
import { useTranslation } from 'react-i18next';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { QuickSearchBox } from '../../components/quick-search-box/quick-search-box';


export const HomePage: React.FC<{
  searchFilters: CruiseSearchFilters,
  updateSearchFilters: (f: CruiseSearchFilters) => void,
  scrollToTop: () => void
}> = (props) => {

  // Translation
  const {t} = useTranslation('common');
  
  // Theme
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isLayoutLarge = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  let navigate = useNavigate();

  const searchButtonClick = () => {
    props.scrollToTop();
    navigate(isLayoutMedium ? "/filter" : "/search");
  }

  const SearchButton = () => {
    return (
      <>
        <Grid item>
          <SectionBox>
            <Stack spacing={2} alignItems={'center'}>
              <Box className='home-page-search-button' textAlign='center' width='100%'>
                <ColorButton label={t('home.ill-use-the-filters')} onClick={searchButtonClick} sx={{width: isLayoutLarge ? 'auto' : '70%'}} />
              </Box>
            </Stack>
          </SectionBox>
        </Grid>
      </>
    );

  }

  const HowItWorksItem = (args: {h: any, index: number}) => {
    return (
      <Stack alignItems={isLayoutLarge || isLayoutSmall ? "flex-start" : "center"} spacing={1}>
        <img src={args.h.img} alt={t(args.h.title)} style={{width: '100%'}} />
        <Typography variant="h3" textAlign={isLayoutLarge || isLayoutSmall ? "left" : "center"}>{t(args.h.title)}</Typography>
        <Typography variant="body1" textAlign={isLayoutLarge || isLayoutSmall ? "left" : "center"}>{t(args.h.details)}</Typography>
      </Stack>
    );
  }

  const getHomeScreen = () => {
    return (
      <Stack spacing={8}>
        <Stack spacing={{xs: 2, md: 8}} padding={{xs: '0 20px', md: '0 100px'}}>
          {!isLayoutLarge &&
            <Grid item>
              <QuickSearchBox
                searchFilters={props.searchFilters}
                updateSearchFilters={props.updateSearchFilters}
              />
            </Grid>
          }
          <Grid item>
            <HomePageQuickSearchButtons
              updateSearchFilters={props.updateSearchFilters}
            />
          </Grid>
        </Stack> 
        <Box>
          <SubscriptionBox />
        </Box>
      </Stack>
    );
  }

  return <>
    <Box>
      {getHomeScreen()}
    </Box>
  </>
}
